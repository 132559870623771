import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const fr = require('./i18n/fr.json');
const en = require('./i18n/en.json');
const de = require('./i18n/de.json');
const lu = require('./i18n/lu.json');
const nl = require('./i18n/nl.json');
const it = require('./i18n/it.json');
const es = require('./i18n/es.json');
const pt = require('./i18n/pt.json');

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      de: { translation: de },
      lu: { translation: lu },
      nl: { translation: nl },
      it: { translation: it },
      es: { translation: es },
      pt: { translation: pt }
    },
    fallbackLng: 'en',
    keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
