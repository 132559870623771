import React, {useState, useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
  FormControlLabel,
  Radio,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  Paper,
} from '@mui/material';
import './UserForm.scss';
import UserServices from "../../../services/users.services";
import CompanyServices from "../../../services/company.services";
import { PROFILES } from "components/shared/Constantes";
import firebase from '../../../config/firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
interface UserFormProps {
  open: boolean;
  toggle: () => void;
  user?: {
    userId?: string,
    email: string,
    name: string,
    role: string,
    companyId: string,
    companies: []
  };
  refresh: () => void;
  reset: () => void;
}

export const UserForm = (props: UserFormProps) => {  
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState(PROFILES.ADMIN.code);
  const [managedCompanies, setManagedCompanies] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setEmail(props.user ? props.user.email : '');
    setName(props.user ? props.user.name : '');
    setRole(props.user ? props.user.role : PROFILES.ADMIN.code);
    setManagedCompanies(props.user && props.user.companies ? props.user.companies.map((comp: any) => {return comp.id;}) : []);
    CompanyServices.getCompaniesSummary().then((res) => {
      setCompanies(res.data);
    });
  }, [props]);

  const handleToggle = (value: string) => () => {
    const currentIndex = managedCompanies.indexOf(value);
    const newChecked = [...managedCompanies];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setManagedCompanies(newChecked);
  };

  const insertOrUpdate = (event: any) => {
    
    if (managedCompanies.length === 0 && role !== PROFILES.ADMIN.code) {
      setError(t('users.choice.error'));
    } else {
      setIsSubmitting(true);
      setError(null);
      if (props.user === null) {
        UserServices.addUser({
          email: email,
          name: name,
          role: role,
          managedCompanies: managedCompanies
        }).then(()=> {
          setIsSubmitting(false);
          const auth = getAuth(firebase);
          sendPasswordResetEmail(auth, email);
          props.refresh();
          props.reset();
          props.toggle();
        }).catch((err) => {
          console.log(err);
          toast.error(t('users.emailAlreadyUsed'), { position: 'bottom-right', autoClose: false });
          setIsSubmitting(false);
        });
      } else {
        UserServices.updateUser({
          id: props.user.userId,
          name: name,
          role: role,
          managedCompanies: managedCompanies
        }).then(()=> {
          setIsSubmitting(false);
          props.refresh();
          props.reset();
          props.toggle();
        }).catch((err) => {
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
          setIsSubmitting(false);
        });
      }
    }   
    
    event.preventDefault();
  };


  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {props.user ? t('users.modify') : t('users.add')} 
      </DialogTitle>

      <form onSubmit={insertOrUpdate} >
        <DialogContent>
          <div >
              <FormControlLabel
                  control={<Radio 
                      checked={role === PROFILES.ADMIN.code} 
                      onChange={(e) => setRole(PROFILES.ADMIN.code)}
                      name="isAdmin" 
                      color="primary"/>}
                  label={t(PROFILES.ADMIN.label)}
              />
              <FormControlLabel
                  control={<Radio 
                      checked={role === PROFILES.COMPANY_ADMIN.code} 
                      onChange={(e) => setRole(PROFILES.COMPANY_ADMIN.code)} 
                      name="isCompanyAdmin" 
                      color="primary"/>}
                  label={t(PROFILES.COMPANY_ADMIN.label)}
              />
              <FormControlLabel
                  control={<Radio 
                      checked={role === PROFILES.SCHOOL_ADMIN.code} 
                      onChange={(e) => setRole(PROFILES.SCHOOL_ADMIN.code)} 
                      name="isSchoolAdmin" 
                      color="primary"/>}
                  label={t(PROFILES.SCHOOL_ADMIN.label)}
              />
          </div>
          <TextField
            value={email}
            required
            variant="outlined"
            disabled={props.user !== null}
            name="email"
            label={t('users.email')}
            type="email"
            onChange={(event) => setEmail(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />
          <TextField
            value={name}
            variant="outlined"
            name="name"
            label={t('users.name')}
            type="string"               
            onChange={(event) => setName(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />

          {(role === PROFILES.COMPANY_ADMIN.code || role === PROFILES.SCHOOL_ADMIN.code) && (
            <>
            <div style={{marginBottom: 10, fontSize: 'smaller'}}>{t('users.choice.org')}</div>
            <Paper style={{height: 230, overflow: 'auto', marginBottom: 20}} variant="outlined">
              <List dense component="div" role="list">
                {companies.map((comp: any) => 
                  <ListItem 
                    key={comp.id} 
                    role="listitem" button 
                    onClick={handleToggle(comp.id)}
                  >
                  <ListItemIcon>
                    <Checkbox
                      checked={managedCompanies.indexOf(comp.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{ 'aria-labelledby': comp.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={comp.id} primary={comp.name} />
                  </ListItem>
                )}
              </List>
            </Paper>
            </>
          )}
          
          {isSubmitting && <LinearProgress/>}
        </DialogContent>

        <DialogActions>
          {error && <span className="error">{error}</span>}
          <Button onClick={() => {
            props.toggle();
            props.reset();
          }}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>          
      </form>
    </Dialog>
  );
}
