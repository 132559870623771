import React, { useState, useMemo } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { numberWithSpaces } from '../../shared/tools/Toolbox';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import MultiIcon from '@mui/icons-material/Group';
import MonoIcon from '@mui/icons-material/Person';
import { useDialog } from "../../shared/tools/CustomHooks";
import { UpdateEventName } from "./UpdateEventName";
import dayjs from "dayjs";
import { FORMAT_DATE } from "components/shared/Constantes";

export const EventTable = (props: any) => {
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState('startDate');
  const [order, setOrder] = useState('desc');
  const [open, toggle] = useDialog(false);  

  const sortedEvent = useMemo(() => {
    return props.events.sort((a: any, b: any) => {
      if (orderBy === 'status') {
        const valueA = dayjs(a.startDate).isAfter(dayjs()) ? 2 : dayjs(a.endDate).isBefore(dayjs()) ? 0 : 1;
        const valueB = dayjs(b.startDate).isAfter(dayjs()) ? 2 : dayjs(b.endDate).isBefore(dayjs()) ? 0 : 1;
        if (order === 'asc') {
          return valueA > valueB ? 1 : -1
        } else {
          return valueA > valueB ? -1 : 1
        }
      }
      if (!a[orderBy]) {
        return (order === 'asc' ? -1 : 1);
      }
      if (!b[orderBy]) {
        return (order !== 'asc' ? -1 : 1);
      }
      if (orderBy === 'startDate' || orderBy === 'endDate') {
        return (order === 'asc') ? dayjs(a[orderBy]).isAfter(dayjs(b[orderBy])) ? 1 : -1
          : dayjs(a[orderBy]).isBefore(dayjs(b[orderBy])) ? 1 : -1
      }
      const labelFunction = (x: any) => x
      if (order === 'asc') {
        return labelFunction(a[orderBy]).localeCompare(labelFunction(b[orderBy]));
      } else {
        return labelFunction(b[orderBy]).localeCompare(labelFunction(a[orderBy]));
      }
    });
  }, [props.events, orderBy, order]);


  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const editEventName = (event: any) => {
    props.setEvent(event);
    toggle();
  };

  return (   
    
    <div className="section margin-top-20">
      <div className="section-info">
        {sortedEvent && t('events.events', { nb: sortedEvent.length })}
      </div>

      <TableContainer>
        <Table stickyHeader>

          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                  onClick={createSortHandler('name')}
                >
                  {t('event.name')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'startDate'}
                  direction={orderBy === 'startDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                  onClick={createSortHandler('startDate')}
                >
                  {t('events.startDate')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'endDate'}
                  direction={orderBy === 'endDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                  onClick={createSortHandler('endDate')}
                >
                  {t('events.endDate')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                  onClick={createSortHandler('status')}
                >
                  {t('events.status')}
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'nbSteps'}
                  direction={orderBy === 'nbSteps' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                  onClick={createSortHandler('nbSteps')}
                >
                  {t('events.totalStep')}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedEvent.map((event: any) => (
              <TableRow 
                hover
                key={event.id}
                onClick={() => { props.setEvent(event); }}
                selected={props.event !== null && props.event.id === event.id}
              >
                <TableCell align="left">
                  {event.isSingle && <MonoIcon fontSize="inherit" style={{color: 'grey'}}/>}
                  {!event.isSingle && <MultiIcon fontSize="inherit" style={{color: 'grey'}}/>}
                  &nbsp;
                  {event.name}
                  &nbsp;
                  {event.isNameEditable && (
                  <IconButton aria-label="edit" size="small" 
                    onClick={() => editEventName(event)}
                    title={t("events.update.name.title")}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>)}
                </TableCell>
                
                <TableCell align="left">{dayjs(event.startDate).format(FORMAT_DATE)}</TableCell>
                <TableCell align="left">{dayjs(event.endDate).format(FORMAT_DATE)}</TableCell>

                <TableCell align="left">
                  {dayjs(event.startDate).isAfter(dayjs())
                    ? <div style={{ color: 'red' }}>{t('events.toCome')}</div>
                    : (dayjs(event.endDate).isBefore(dayjs())
                      ? <div style={{ color: 'green' }}>{t('events.finished')}</div>
                      : <div style={{ color: 'orange' }}>{t('events.ongoing')}</div>)
                  }
                </TableCell>

                <TableCell align="right">{numberWithSpaces(event.nbSteps)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {open && 
        <UpdateEventName
          open={true}
          toggle={toggle}
          refresh={() => props.refresh()}
          event={props.event}
        />
      }
    </div>);
}
