import React, {useState} from "react";
import {Card} from '@mui/material';
import "./login.scss"
import {
  TextField, 
  LinearProgress,
  Button
} from '@mui/material';
import firebase from '../../../config/firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';
import logoImg from '../../../assets/img/logo.svg';

export const FortgotPassword = (props: any) => {

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const reinitPassword = (event: any) => {
    setIsLoading(true);
    const auth = getAuth(firebase);
    sendPasswordResetEmail(auth, email).then(() => {
        toast.success(t('reinit.succes', { position: 'bottom-right', email: email }));
        setIsLoading(false);
    }).catch((err) => {
        toast.error(t('reinit.error'), { position: 'bottom-right', autoClose: false });
        setIsLoading(false);
    });
    event.preventDefault();
};

  return (
        <div className="fixed-center">
          <Card className="login-card">
            <div className="panel-left">
              <img src={logoImg} alt=""/>
            </div>
            <div className="panel-right">
              <div className="title">
                <span className="color-primary">{t('reinit.title')}</span> <br/>
                <span className="text-small">{t('reinit.sub.title')}</span>
              </div>
              <form className="login" onSubmit={(e) => reinitPassword(e)}>
                <div className="inputs">
                  <TextField
                    id="login-email"
                    name="email"
                    label={t('reinit.email')}
                    variant="outlined"
                    className="margin-bottom-20"
                    autoComplete="off"
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  {<a href="/" className="text-small text-right color-primary">{t('reinit.login')}</a>}
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={isLoading}
                >
                  {t('reinit.button.reinit')}
                </Button>
                {isLoading && <LinearProgress/>}           
              </form>
            </div>
          </Card>
        </div>
    );
  };
