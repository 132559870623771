import React, { useContext } from 'react';
import {
  CardContent, IconButton
} from '@mui/material';
import Card from '@mui/material/Card';
import "./MyCompany.scss";
import { AuthUserContext } from 'AuthUserContext';
import { Company } from './Company';
import EditIcon from '@mui/icons-material/Edit';
import { useDialog } from "../../../shared/tools/CustomHooks";
import { UpdateCompanyName } from './UpdateCompanyName';
import { useTranslation } from 'react-i18next';

export const MyCompany = () => {

  const { companyName, user } = useContext(AuthUserContext);
  const [open, toggle] = useDialog(false);
  const { t } = useTranslation();
  
  return (
    <>
    {companyName && (
    <div className="content">      
     
        <Card className="company-panel">
          <div className="company-title">
            <div>
              {companyName}&nbsp;
              <IconButton aria-label="edit" size="small" 
                onClick={() => toggle()}
                title={user.isSchoolAdmin ? t("orga.update.name.school.title") : t("orga.update.name.title")}
                style={{color: 'white'}}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>

          {open && 
              <UpdateCompanyName
                  open={true}
                  toggle={toggle}
              />
          }
          
          <CardContent>        
            <Company />
          </CardContent>
        </Card>
      </div>
    )}
    </>
  )
};
