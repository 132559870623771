import axios from 'axios';
import config from '../config/config';

class UsersServices {

  getUsers = () => {
    return axios.get(config.backendURL + '/user-getUsers');
  };

  getUser = (userId: string) => {
    return axios.get(config.backendURL + '/user-getUser?userId='+userId);
  };

  deleteUser = (userId: string) => {
    return axios.delete(config.backendURL + '/user-deleteUser?userId='+userId);
  };

  addUser = (user: any) => {
    return axios.post(config.backendURL + '/user-addUser', user);
  };

  updateUser = (user: any) => {
    return axios.put(config.backendURL + '/user-updateUser?userId='+user.id, user);
  };
}

const usersServices = new UsersServices();
export default usersServices;


