import React, {useState, useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField, 
  LinearProgress,
  FormControlLabel,
  Radio
} from '@mui/material';
import "./Detail.scss"
import EventServices from '../../../services/event.services';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { DELIVERY, SCHEDULE, NOTIF_STATUS, getCountryTimezone, FORMAT_DATE } from '../../shared/Constantes';
import { DesktopDatePicker , DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

export const NotificationModal = (props: any) => {
  const { t } = useTranslation();
  const notification = { 
    id: '',
    title: '', 
    body: '', 
    status: '', 
    toSendDate: '', 
    when: ''
  };

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [typeOfDelivery, setTypeOfDelivery] = useState(DELIVERY.NOW);
  const [typeOfSchedule, setTypeOfSchedule] = useState(null);
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setNotification = () => {
    if (props.notif !== null) {
      notification.id = props.notif.id;
    } else {
      notification.id = null;
    }
    notification.title = title;
    notification.body = body;
    if (typeOfDelivery === DELIVERY.NOW) {
      notification.status = NOTIF_STATUS.SENT.code;
      notification.toSendDate = null;
      notification.when = null;
    } else {
      notification.status = NOTIF_STATUS.TO_SEND.code;
      if (typeOfSchedule === SCHEDULE.START) {
        notification.toSendDate = props.startDate;
        notification.when = SCHEDULE.START;
      } else if (typeOfSchedule === SCHEDULE.END) {
        notification.toSendDate = props.endDate;
        notification.when = SCHEDULE.END;
      } else {
        notification.toSendDate = dayjs.tz(dayjs(date).format("YYYY-MM-DD") + " " + dayjs(hour).format("HH:mm"), getCountryTimezone(props.country)).toISOString();
        notification.when = SCHEDULE.DATE;
      }
    }
  };

  const publish = (event: any) => {
    setIsSubmitting(true);
    setNotification();
    
    if (props.company !== undefined && props.company !== null) {
      EventServices.sendNotificationToCompany(props.event, props.company, notification)
      .then((result: any) => {
        toast.success(notification.status === NOTIF_STATUS.SENT.code ? t('detail.notif.send.success') : t('detail.notif.scheduled.success'), { position: 'bottom-right' });
        setIsSubmitting(false);
        props.resetNotif();
      })
      .catch(() => {
        toast.error(t('generic.error'), { position: 'bottom-right' });
        setIsSubmitting(false);
        props.resetNotif();
      });
    } else {
      EventServices.sendNotification(props.event, notification)
        .then((result: any) => {
          toast.success(notification.status === NOTIF_STATUS.SENT.code ? t('detail.notif.send.success') : t('detail.notif.scheduled.success'), { position: 'bottom-right' });
          setIsSubmitting(false);
          props.resetNotif();
        })
        .catch((error) => {
          toast.error(t('generic.error'), { position: 'bottom-right' });
          setIsSubmitting(false);
          props.resetNotif();
        });
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (props.notif !== null) {
      setTitle(props.notif.title);
      setBody(props.notif.body);
      if (props.notif.when !== undefined) {
        setTypeOfSchedule(props.notif.when);
        setTypeOfDelivery(DELIVERY.SCHEDULED);
        if (props.notif.when === SCHEDULE.DATE) {
          setDate(dayjs(props.notif.toSendDate).tz(getCountryTimezone(props.country)));
          setHour(dayjs(props.notif.toSendDate).tz(getCountryTimezone(props.country)));
        }        
      }
    } else {
      setTitle('');
      setBody('');
      setTypeOfSchedule(null);
      setTypeOfDelivery(DELIVERY.NOW);
      setDate(null);
      setHour(null);
    }
  }, [props]);

  return (
    <Dialog
      open={props.open}
      onClose={props.toggleNotif}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {t('detail.notif.title')}
      </DialogTitle>

          <form onSubmit={publish}>
            <DialogContent>
              
              <TextField
                value={title}
                error={title.length > 30}
                helperText={t('detail.notif.characters.30')}
                required
                variant="outlined"
                id="title"
                label={t('detail.notif.subject')}
                type="text"
                onChange={(event) => setTitle(event.target.value as string)}
                fullWidth                
                className="margin-bottom-10"
              />

              <TextField
                value={body}
                error={body.length > 150}
                helperText={t('detail.notif.characters.150')}
                required
                variant="outlined"
                id="body"
                label={t('detail.notif.body')}
                type="text"                
                onChange={(event) => setBody(event.target.value as string)}
                fullWidth
                multiline
                rows="2"
              />
            
              <div>
                <FormControlLabel
                  control={<Radio 
                  checked={typeOfDelivery === DELIVERY.NOW} 
                  onChange={(e) => setTypeOfDelivery(DELIVERY.NOW)}
                  name="isNow" 
                  color="primary"/>}
                  label={t('detail.notif.delivery.now')}
                />

                <FormControlLabel
                  control={<Radio 
                  checked={typeOfDelivery === DELIVERY.SCHEDULED} 
                  onChange={(e) => setTypeOfDelivery(DELIVERY.SCHEDULED)}
                  name="isScheduled" 
                  color="primary"/>}
                  label={t('detail.notif.delivery.scheduled')}
                />
              </div>
             
              <div className="notif">
                <FormControlLabel
                  control={<Radio 
                  checked={typeOfSchedule === SCHEDULE.START} 
                  onChange={(e) => setTypeOfSchedule(SCHEDULE.START)}
                  name="atStart" 
                  color="primary"/>}
                  label={t('detail.notif.schedule.start')}
                  disabled={typeOfDelivery === DELIVERY.NOW || dayjs().isAfter(dayjs(props.startDate))}
                />
                
                <FormControlLabel
                  control={<Radio 
                  checked={typeOfSchedule === SCHEDULE.END} 
                  onChange={(e) => setTypeOfSchedule(SCHEDULE.END)}
                  name="atEnd" 
                  color="primary"/>}
                  label={t('detail.notif.schedule.end')}
                  disabled={typeOfDelivery === DELIVERY.NOW || dayjs().isAfter(dayjs(props.endDate))}
                />
                <div style={{ display: 'flex',  flexDirection: 'row'}}>                  
                    <FormControlLabel
                      control={<Radio 
                      checked={typeOfSchedule === SCHEDULE.DATE}
                      onChange={(e) => setTypeOfSchedule(SCHEDULE.DATE)}
                      name="atDate" 
                      color="primary"/>}
                      label={t('detail.notif.schedule.date')}
                      disabled={typeOfDelivery === DELIVERY.NOW}
                    />
                    <DesktopDatePicker 
                      value={date}
                      slotProps={{ textField: { required: typeOfSchedule === SCHEDULE.DATE } }}
                      onChange={(event) => {
                        setDate(event as any);                  
                      }}
                      label={t('detail.notif.schedule.date.date')}
                      className="margin-right-20"
                      format={FORMAT_DATE}
                      disablePast
                      disabled={typeOfDelivery === DELIVERY.NOW}
                    />
                    <DesktopTimePicker
                      value={hour}
                      slotProps={{ textField: { required: typeOfSchedule === SCHEDULE.DATE } }}
                      onChange={(event) => {
                        setHour(event as any);
                      }}
                      label={t('detail.notif.schedule.date.time')}
                      className="margin-right-20"
                      ampm={false}
                      disabled={typeOfDelivery === DELIVERY.NOW}
                    />
                </div>
              </div>
              
              {isSubmitting && <LinearProgress/>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {props.toggle(); props.setSelectedNotif(null);}}>
                {t('button.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || title.length > 30 || body.length > 150}
                disableElevation>
                {t('button.send')}
              </Button>
            </DialogActions>
          </form>
      
    </Dialog>
  );
}
