import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  LinearProgress,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  TableHead
} from '@mui/material';
import "./MyCompany.scss"
import CompanyServices from "../../../../services/company.services";
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { AuthUserContext } from 'AuthUserContext';
import firebase from '../../../../config/firebase';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDialog } from '../../../shared/tools/CustomHooks';
import { UpdateCompanyInfos } from './UpdateCompanyInfos';
import { UpdateCompanyLogo } from './UpdateCompanyLogo';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import Validate from '@mui/icons-material/Check';
import Cancel from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { ConfirmDialog } from '../../../shared/ConfirmDialog/ConfirmDialog';
import { getCountryLabel, DIR_LOGO, PROFILES } from '../../../shared/Constantes';
import personImg from '../../../../assets/img/person.svg';
import countryImg from '../../../../assets/img/country.svg';
import emailImg from '../../../../assets/img/mail.svg';
import phoneImg from '../../../../assets/img/phone.svg';

export const Company = () => {
  const { t } = useTranslation();
  const { user, companyId } = useContext(AuthUserContext);
  const [url, setUrl] = useState(''); 

  const [company, setCompany] = useState(null);
  const [newDepName, setNewDepName] = useState(null);
  const [editDepName, setEditDepName] = useState(null);
  const [newServName, setNewServName] = useState(null);
  const [editServName, setEditServName] = useState(null);
  const [department, setDepartment] = useState(null);
  const [addDep, setAddDep] = useState(false);
  const [editDep, setEditDep] = useState(null);
  const [addServ, setAddServ] = useState(false);
  const [editServ, setEditServ] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [currentService, setCurrentService] = useState(null);

  const [open, toggle] = useDialog(false);
  const [openLogo, toggleLogo] = useDialog(false);
  const [openSupDep, toggleSupDep] = useDialog(false);
  const [openSupServ, toggleSupServ] = useDialog(false);

  const loadCompany = useCallback(() => {
    CompanyServices.getCompany(companyId).then((result) => {
      setCompany(result.data);
      if (result.data.logo !== undefined && result.data.logo !== null) {
        getUrl(result.data.logo);
      } else {
        getUrl('ELA.png');
      }
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }, [companyId]);

  const loadDepartment = useCallback(() => {
    setIsLoading(true);
    CompanyServices.getDepartment(companyId).then((result: any) => {
      setDepartment(result.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }, [companyId]);

  const getUrl = (logo: string) => {
    const storage = getStorage(firebase);
    const storageRef = ref(storage, DIR_LOGO.company + logo);
    getDownloadURL(storageRef).then((url) => {
      setUrl(url);
    });
  };

  const objectSort = (objectToOrder: any) => {
    return Object.entries(objectToOrder).sort((a: any, b: any) => a[1].name.localeCompare(b[1].name)).map(a => a[0]);
  };

  const handleAddDep = () => {
    setIsSubmitting(true);
    CompanyServices.addDepartment(companyId, newDepName)
      .then(() => {
        setNewDepName(null);
        setAddDep(false);
        loadDepartment();
        setIsSubmitting(false);
        toast.success(t('orgas.successAdd'), { position: 'bottom-right' });
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
      });
  };

  const handleEditDep = (event: any) => {
    setIsSubmitting(true);
    CompanyServices.updateDepartment(companyId, editDep, editDepName)
      .then(() => {
        setNewDepName(null);
        setEditDep(null);
        loadDepartment();
        setIsSubmitting(false);
        toast.success(t('orgas.successUpdate'), { position: 'bottom-right' });
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
      });
      event.preventDefault(); 
  };

  const handleSupDep = (departmentId: string) => {
    setIsSubmitting(true);
    toggleSupDep();
    CompanyServices.delDepartment(companyId, departmentId)
      .then(() => {
        setNewDepName(null);
        setEditDep(null);
        setEditServ(null);
        setNewServName(null);
        setCurrentDepartment(null);
        loadDepartment();
        setIsSubmitting(false);
        toast.success(t('orgas.successDelete'));
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        if (err.response.status === 403) {
          toast.error(t('orgas.runnersInDep'), { autoClose: false });
        } else {
          toast.error(t('generic.error'), { autoClose: false });
        }
      });
  };

  const handleAddServ = () => {
    setIsSubmitting(true);
    CompanyServices.addService(companyId, currentDepartment, newServName)
      .then(() => {
        setNewServName(null);
        setAddServ(false);
        loadDepartment();
        setIsSubmitting(false);
        toast.success(t('orgas.successAdd'));
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error(t('generic.error'), { autoClose: false });
      });
  };

  const handleEditServ = () => {
    setIsSubmitting(true);
    CompanyServices.updateService(companyId, currentDepartment, editServ, editServName)
      .then(() => {
        setNewServName(null);
        setEditServ(null);
        loadDepartment();
        setIsSubmitting(false);
        toast.success(t('orgas.successUpdate'));
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error(t('generic.error'), { autoClose: false });
      });
  };

  const handleSupServ = (serviceId: string) => {
    setIsSubmitting(true);
    toggleSupServ();
    CompanyServices.delService(companyId, currentDepartment, serviceId)
      .then(() => {
        loadDepartment();
        setNewServName(null);
        setEditServ(null);
        setIsSubmitting(false);
        toast.success(t('orgas.successDelete'));
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        if (err.response.status === 403) {
          toast.error(t('orgas.runnersInServ'), { autoClose: false });
        } else {
          toast.error(t('generic.error'), { autoClose: false });
        }
      });
  };

  useEffect(() => {
    loadCompany();
    loadDepartment()
  }, [loadCompany, loadDepartment]);

  return (
  <> 

    {company && (
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      
      <div className="margin-bottom-10">{user.isSchoolAdmin ? t('orgas.groups.info') : t('orgas.departments.info')}</div>
      
      {company.hasOngoingEvent && 
        <div style={{display: 'flex', flexDirection:'row', marginBottom: 5}}>
          <div><WarningIcon style={{ color: 'red'}} fontSize="inherit" className="margin-right-10"/></div>                                        
          <div style={{fontStyle: 'italic', fontSize: 'smaller', color:'red'}}>{t('orgas.group.alert2')}</div>
        </div>
      }
      <div style={{display: 'flex', flexDirection:'row'}}>
          <div><WarningIcon style={{ color: 'red'}} fontSize="inherit" className="margin-right-10"/></div>                                        
          <div style={{fontStyle: 'italic', fontSize: 'smaller'}}>{t('orgas.group.alert')}</div>
      </div>
      
    <div className="MuiCardContent-root">

      <div className="infos-panel left">
        
        <div className="section-content">
          <TableContainer>
            <Table stickyHeader>
              {((company) || (department && Object.entries(department).length > 0)) &&
                <TableHead>
                  <TableRow>
                    <TableCell>{user.isCompanyAdmin?t('orgas.departments'):t('orgas.groups')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              }
              <TableBody>
                {department && objectSort(department).map((departmentId: string) => (
                  (((!editDep) || (editDep !== departmentId)) && (department[departmentId].name !== company.name) &&
                    <TableRow
                      key={departmentId}
                      onClick={() => { setCurrentDepartment(departmentId); }}
                      selected={department[currentDepartment] !== null && currentDepartment === departmentId}
                    >
                      <TableCell component="th" scope="row" >
                        {department[departmentId].name}
                      </TableCell>
                      <TableCell align="right">
                        
                          <span>
                            <IconButton aria-label="edit" size="small"
                              onClick={() => {
                                setEditDep(departmentId);
                                setEditDepName(department[departmentId].name);
                              }}>
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton aria-label="delete" size="small"
                              onClick={() => {
                                setCurrentDepartment(departmentId);
                                toggleSupDep();
                              }}>
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </span>
                        
                      </TableCell>
                    </TableRow>) ||
                  ((editDep) && (editDep === departmentId) &&

                    <TableRow
                      key={departmentId}
                      onClick={() => { setCurrentDepartment(departmentId); }}
                      selected={currentDepartment !== null && currentDepartment === departmentId}
                    >
                      <TableCell component="th" scope="row">
                        <TextField
                          value={editDepName}
                          name="pseudo"
                          type="text"
                          placeholder={user.isCompanyAdmin?t('orgas.departmentName'):t('orgas.groupName')}
                          onChange={(event) => setEditDepName(event.target.value)}
                        />
                      </TableCell>
                      <TableCell >
                        {company && 
                          <span>
                            <IconButton aria-label="edit" size="small"
                              onClick={(event) => handleEditDep(event)}>
                              <Validate style={{ color: 'green', fontSize: '18px' }} />
                            </IconButton>
                            <IconButton aria-label="cancel" size="small"
                              onClick={() => {
                                setEditDep(null);
                                setEditDepName(null);
                              }}>
                              <Cancel style={{ color: 'red', fontSize: '18px' }} />
                            </IconButton>
                          </span>
                        }
                      </TableCell>
                    </TableRow>)

                ))}
                {company && !addDep && 
                  <TableRow
                    key={'addDepButton'}
                  ><TableCell component="th" scope="row">
                      <IconButton aria-label="delete" size="small"
                        onClick={() => { setAddDep(true); }}>
                        {user.isCompanyAdmin?t('orgas.departmentAdd'):t('orgas.groupAdd')}
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                {company && addDep && 
                  <TableRow
                    key={'addDepButton'}
                  ><TableCell component="th" scope="row">
                      <TextField
                        name="depName"
                        type="text"
                        placeholder={user.isCompanyAdmin?t('orgas.departmentName'):t('orgas.groupName')}
                        onChange={(event) => setNewDepName(event.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" size="small"
                        onClick={() => {
                          handleAddDep();
                        }}>
                        <Validate style={{ color: 'green', fontSize: '18px' }} />
                      </IconButton>
                      <IconButton aria-label="delete" size="small"
                        onClick={() => {
                          setAddDep(false);
                          setNewDepName(null);
                        }}>
                        <Cancel style={{ color: 'red', fontSize: '18px' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        
        <div style={{ marginBottom: '10px', display: 'block' }}>
          {(isLoading || isSubmitting) && <LinearProgress />}
        </div>
      </div>

      <div className="infos-panel middle">

      
        <div className="section-content">
          
          {department && Object.entries(department).length > 0 && 
                 (department[currentDepartment] && department[currentDepartment].services && Object.keys(department[currentDepartment].services).length > 0) &&
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{user.isCompanyAdmin ? t('orgas.services') : t('orgas.subgroups')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading && !currentDepartment &&
                    <TableRow>
                      <TableCell>{user.isCompanyAdmin ? t('orgas.noServices') : t('orgas.noSubGroups')}
                      </TableCell>
                    </TableRow>
                  }
                  {!isLoading && currentDepartment && objectSort((department[currentDepartment].services)).map((serviceId: string) => (
                    (((!editServ) || (editServ !== serviceId)) && (department[currentDepartment].name !== department[currentDepartment].services[serviceId].name) &&

                      <TableRow
                        key={serviceId}
                        onClick={() => setCurrentService(serviceId)}
                        selected={currentService !== null && currentService === serviceId}
                      >

                        <TableCell component="th" scope="row">
                          {department[currentDepartment].services[serviceId].name}
                        </TableCell>
                        <TableCell align="right">
                          
                            <span>
                              <IconButton aria-label="delete" size="small"
                                onClick={() => {
                                  setEditServ(serviceId);
                                  setEditServName(department[currentDepartment].services[serviceId].name);
                                }}>
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                              <IconButton aria-label="delete" size="small"
                                onClick={() => {
                                  setCurrentService(serviceId);
                                  toggleSupServ();
                                }}>
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </span>
                          
                        </TableCell>
                      </TableRow>) 
                      
                      || ((editServ) && (editServ === serviceId) &&

                      <TableRow
                        key={serviceId}
                      >
                        <TableCell component="th" scope="row">
                          <TextField
                            value={editServName}
                            name="servName"
                            type="text"
                            placeholder={user.isCompanyAdmin?t('orgas.serviceName'):t('orgas.subgroupName')}
                            onChange={(event) => setEditServName(event.target.value)}
                          />
                        </TableCell>
                        <TableCell>
                          
                            <span>
                              <IconButton aria-label="edit" size="small"
                                onClick={() => handleEditServ()}>
                                <Validate style={{ color: 'green', fontSize: '18px' }} />
                              </IconButton>
                              <IconButton aria-label="cancel" size="small"
                                onClick={() => {
                                  setEditServ(null);
                                  setEditServName(null);
                                }}>
                                <Cancel style={{ color: 'red', fontSize: '18px' }} />
                              </IconButton>
                            </span>
                          
                        </TableCell>
                      </TableRow>)
                  ))}

                  {!addServ && currentDepartment && 
                    <TableRow
                      key={'addServButton'}
                    ><TableCell component="th" scope="row">
                        <IconButton aria-label="delete" size="small"
                          onClick={() => setAddServ(true)}>
                          {user.isCompanyAdmin?t('orgas.serviceAdd'):t('orgas.subgroupAdd')}
                          <AddIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  }

                  {addServ && currentDepartment && 
                    <TableRow
                      key={'addServButton'}
                    >
                      <TableCell component="th" scope="row">
                        <TextField
                          name="servName"
                          type="text"
                          placeholder={user.isCompanyAdmin?t('orgas.serviceName'):t('orgas.subgroupName')}
                          onChange={(event) => setNewServName(event.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="delete" size="small"
                          onClick={() => {
                            handleAddServ();
                          }}>
                          <Validate style={{ color: 'green', fontSize: '18px' }} />
                        </IconButton>
                        <IconButton aria-label="delete" size="small"
                          onClick={() => {
                            setAddServ(false);
                            setNewServName(null);
                          }}>
                          <Cancel style={{ color: 'red', fontSize: '18px' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  }

                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>

      <div className="infos-panel right">
        <div className="edit">
          <Button
            className="action-link"
            color="primary"
            size="small"
            onClick={toggleLogo}
            endIcon={<EditOutlinedIcon />}
          >
            {t('button.update')}
          </Button>
          <UpdateCompanyLogo
            open={openLogo}
            toggle={toggleLogo}
            refresh={() => loadCompany()}
          />
        </div>

        <div className="logo">
          {url && <img src={url} alt="" />}
        </div>

        {company && !company.logo &&
          <div className="margin-20">
            <span>{t('orga.no.logo')}</span>
            <span>{t('orga.logo.ela')}</span>
          </div>}
        <div className="edit mt-2">
          {
            user.role !== PROFILES.SCHOOL_ADMIN.code &&
          <Button
            className="action-link"
            color="primary"
            size="small"
            onClick={toggle}
            endIcon={<EditOutlinedIcon />}
          >
            {t('button.update')}
          </Button>
          }
          {open &&
            <UpdateCompanyInfos
              open={true}
              toggle={toggle}
              company={company}
              refresh={() => loadCompany()}
            />
          }
        </div>
        
        <div className="section">
          <div className="section-content">
            <div className="section-title">
              <img src={personImg} alt="Localisation" /> {t('orga.contact')} : {company.contact}
            </div>
            <div className="section-title">
              <img src={countryImg} alt="Localisation" /> {t('orga.country')} : {t(getCountryLabel(company.country))}
            </div>
            <div className="section-title" style={{ paddingLeft: '30px' }}>
              {t('orga.cp')} : {company.cp ? company.cp : '-'}
            </div>
            <div className="section-title" style={{ paddingLeft: '30px' }}>
              {t('orga.city')} : {company.city ? company.city : '-'}
            </div>
            <div className="section-title padding-top-10" style={{borderTop: '1px', borderTopStyle: 'solid', borderTopColor: '#D5D5D5'}}>
              {t('orga.infos')}
            </div>
            <div className="section-title">
              <img src={emailImg} alt="Localisation" /> {t('orga.email')} : {company.email}
            </div>
            <div className="section-title">
              <img src={phoneImg} alt="Localisation" /> {t('orga.phone.number')} : {company.phone}
            </div>
          </div>
        </div>

      </div>
      

      {openSupDep && 
        <ConfirmDialog
          open={true}
          handleClose={() => {
            toggleSupDep();
          }}
          handleAction={() => handleSupDep(currentDepartment)}
          title={t('orgas.delete.title')}
          message={currentDepartment ? t('orgas.delete.department', { department: department[currentDepartment].name }) : ''}
          confirmLabel={t('button.validate')}
        />
      }

      {openSupServ && 
        <ConfirmDialog
          open={true}
          handleClose={() => {
            toggleSupServ();
          }}
          handleAction={() => handleSupServ(currentService)}
          title={t('orgas.delete.title')}
          message={currentService && currentDepartment && department && department[currentDepartment].services[currentService] ? t('orgas.delete.service', { service: department[currentDepartment].services[currentService].name }) : ''}
          confirmLabel={t('button.validate')}
        />
      }  
      
    </div>
    </div>
    )}
    </>
  );
};
