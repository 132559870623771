import axios from 'axios';
import config from '../config/config';

class EventServices {

  getAllEvents = (type?:string) => {
    if (!type) {
      return axios.get(config.backendURL + '/event-getEventsSummary');
    } else {
      return axios.get(config.backendURL + `/event-getEventsSummary?type=${type}`);
    }
  };

  getEvent = (eventId: string) => {
    return axios.get(config.backendURL + `/event-getEvent?eventId=${eventId}`);
  };

  createEvent = (event: any) => {
    return axios.post(
      config.backendURL + '/event-createEvent',
      event );
  };

  updateEvent = (event: any) => {
    return axios.put(
      config.backendURL + `/event-updateEvent?eventId=${event.id}`,
      event
    );
  };

  updateEventDates = (event: any) => {
    return axios.put(
      config.backendURL + `/event-updateEventDates?eventId=${event.id}`,
      event
    );
  };

  updateEventLogo = (eventId: any, logo: any) => {
    return axios.put(
      config.backendURL + `/event-updateEventLogo?eventId=${eventId}`,
      logo
    );
  };

  deleteEvent = (eventId: string) => {
    return axios.delete(config.backendURL + '/event-deleteEvent?eventId=' + eventId);
  };

  importEvents = (file: any) => {
    return axios.post(
      config.backendURL + '/import-importEvents',
      file );
  };

  importEventsMultiCompanies = (file: any) => {
    return axios.post(
      config.backendURL + '/import-importEventsMultiCompanies',
      file );
  };

  sendMailToCoord = (emails: []) => {
    return axios.post(
      config.backendURL + '/import-sendMailToCoord',
      {emails} );
  };

  inviteCompany = (eventId: string, companyId: string) => {
    return axios.post(config.backendURL + '/event-inviteCompany?eventId=' + eventId + '&companyId=' + companyId);
  };

  inviteCompanyManualCode = (eventId: string, companyId: string, code: string) => {
    return axios.post(config.backendURL + '/event-inviteCompany?eventId=' + eventId + '&companyId=' + companyId+ '&code=' + code);
  };

  unregisterCompany = (eventId: string, companyId: string) => {
    return axios.post(config.backendURL + '/event-unregisterCompany?eventId=' + eventId + '&companyId=' + companyId);
  };

  sendNotification = (eventId: string, notification: any) => {
    return axios.post(
      config.backendURL + '/notification-sendNotificationToEvent?eventId=' + eventId,
      notification
    );
  };

  sendNotificationToCompany = (eventId: string, companyId: string, notification: any) => {
    return axios.post(
      config.backendURL + '/notification-sendNotificationToCompany?eventId=' + eventId + '&companyId=' + companyId,
      notification
    );
  };

  getNotifications = (eventId: string) => {
    return axios.get(config.backendURL + `/event-getNotifications?eventId=${eventId}`);
  };

  deleteNotification = (eventId: string, messageId: string) => {
    return axios.delete(config.backendURL + `/event-deleteNotification?eventId=${eventId}&messageId=${messageId}`);
  };

  publishMessage = (eventId: string, companyId: string, notification: any) => {
    return axios.post(
      config.backendURL + '/notification-publishMessage?eventId=' + eventId + '&companyId=' + companyId,
      notification
    );
  };

  downloadUserGuide = (eventId: string, companyId: string, tz: string) => {
    return axios.get(
      config.backendURL + '/export-downloadUserGuide?eventId=' + eventId + '&companyId=' + companyId + '&tz='+tz,
      {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );
  };

  downloadGuideByType = (type: string) => {
    return axios.get(
      config.backendURL + '/export-downloadGuideByType?type=' + type,
      {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );
  };

  downloadStatistics = (type: string, startDate: string, endDate: string, country: string) => {
    
    return axios.get(
      config.backendURL + '/export-downloadStatistics?type='+type+'&country='+country+'&startDate='+startDate+'&endDate='+endDate,
      {
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      }
    );
  };

  computeRanking = (eventId: string) => {
    return axios.get(config.backendURL + `/leaderboard-createLeaderboard?eventId=${eventId}`);
  };

  downloadEvents = () => {
    return axios.get(config.backendURL + '/export-downloadEvents',
    {
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  };

}

const eventServices = new EventServices();
export default eventServices;

