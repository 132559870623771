import axios from 'axios';
import config from '../config/config';

class RunnerServices {

  getRunners = (eventId: string, companyId: string) => {
    return axios.get(config.backendURL + '/runner-getRunners?eventId='+eventId+'&companyId='+companyId);
  };

  downloadRunners = (eventId: string, companyId: string) => {
    return axios.get(config.backendURL + '/export-downloadRunners?eventId='+eventId+'&companyId='+companyId,
    {
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  };

  getLevels = (eventId: string, companyId: string) => {
    return axios.get(config.backendURL + '/company-getLevels?eventId='+eventId+'&companyId='+companyId);
  };

  addRunner = (eventId: string, companyId: string, subsidiaryId: string, serviceId: string, runner: any) => {
    return axios.post(
      config.backendURL + '/runner-addRunner?eventId='+eventId+'&companyId='+companyId+'&subsidiaryId='+subsidiaryId+'&serviceId='+serviceId,
      runner
    );
  };

  updateRunner = (eventId: string, companyId: string, subsidiaryId: string, serviceId: string, runnerId: string, runner: any) => {
    return axios.put(
      config.backendURL + '/runner-updateRunner?eventId='+eventId+'&companyId='+companyId+'&subsidiaryId='+subsidiaryId+'&serviceId='+serviceId+'&runnerId='+runnerId,
      runner
    );
  };

  deleteRunner = (eventId: string, companyId: string, subsidiaryId: string, serviceId: string, runnerId: string) => {
    return axios.delete(
      config.backendURL + '/runner-deleteRunner?eventId='+eventId+'&companyId='+companyId+'&subsidiaryId='+subsidiaryId+'&serviceId='+serviceId+'&runnerId='+runnerId);
  };

}

const runnerServices = new RunnerServices();
export default runnerServices;


