import axios from 'axios';
import config from '../config/config';

class CompanyServices {

  getCompanies = () => {
    return axios.get(config.backendURL + '/company-getCompanies');
  };

  getCompaniesSummary = () => {
    return axios.get(config.backendURL + '/company-getCompaniesSummary');
  };

  createCompany = (company: any) => {
    return axios.post(
      config.backendURL + '/company-createCompany',
      company
    );
  };

  updateCompany = (company: any) => {
    return axios.put(
      config.backendURL + '/company-updateCompany?companyId=' + company.id,
      company
    );
  };

  updateCompanyName = (company: any) => {
    return axios.put(
      config.backendURL + '/company-updateCompanyName?companyId=' + company.id,
      {name : company.name}
    );
  };

  updateEventName = (companyId: string, event: any) => {
    return axios.put(
      config.backendURL + '/company-updateEventName?companyId=' + companyId + '&eventId=' + event.id,
      {name : event.name}
    );
  };

  updateEventLogo = (companyId: string, eventId: string, eventLogo: any) => {
    return axios.put(
      `${config.backendURL}/company-updateEventLogo?companyId=${companyId}&eventId=${eventId}`,
      { logo: eventLogo }
    );
  };

  getCompany = (companyId: string) => {
    return axios.get(config.backendURL + '/company-getCompany?companyId=' + companyId);
  };

  deleteCompany = (companyId: any) => {
    return axios.delete(
      config.backendURL + '/company-deleteCompany?companyId=' + companyId
    );
  };

  getEvents = (companyId: any) => {
    return axios.get(config.backendURL + '/event-getEventsByCompany?companyId=' + companyId);
  };

  getDepartment = (companyId: string) => {
    return axios.get(config.backendURL + '/company-getDepartment?companyId=' + companyId);
  };

  updateParametersForEvent = (eventId: string, companyId: string, params: any) => {
    return axios.put(config.backendURL + '/company-updateCompanyParameters?eventId=' + eventId + '&companyId=' + companyId,
    params);
  };

  updateCompanyInfos = (company: any) => {
    return axios.put(config.backendURL + '/company-updateCompanyInfos?companyId=' + company.id,
    company);
  };

  updateCompanyLogo = (companyId: any, logo: any) => {
    return axios.put(config.backendURL + '/company-updateCompanyLogo?companyId=' + companyId,
    logo);
  };

  addDepartment = (companyId: string, departmentName: string) => {
    return axios.post(config.backendURL + '/company-addDepartment?companyId=' + companyId + '&departmentName=' + departmentName);
  };

  addService = (companyId : string, departmentId: string, serviceName: string) => {
    return axios.post(config.backendURL + '/company-addService?companyId=' + companyId + '&departmentId=' + departmentId + '&serviceName=' + serviceName);
  };

  updateDepartment = (companyId: string, departmentId: string, departmentName: string) => {
    return axios.put(config.backendURL + '/company-updateDepartment?companyId=' + companyId + '&departmentId=' + departmentId + '&departmentName=' + departmentName);
    };

  updateService  = (companyId : string, departmentId: string, serviceId: string, serviceName: string) => {
    return axios.put(config.backendURL + '/company-updateService?companyId=' + companyId + '&departmentId=' + departmentId + '&serviceId=' + serviceId+ '&serviceName=' + serviceName);
  };

  delDepartment = (companyId : string, departmentId: string) => {
    return axios.delete(config.backendURL + '/company-deleteDepartment?companyId=' + companyId + '&departmentId=' + departmentId);
  };

  delService = (companyId : string, departmentId: string, serviceId: string) => {
    return axios.delete(config.backendURL + '/company-deleteService?companyId=' + companyId + '&departmentId=' + departmentId + '&serviceId=' + serviceId);
  };

  getMyCompaniesSummary = (companies: []) => {
    return axios.post(config.backendURL + '/company-getMyCompaniesSummary',
    companies);
  };

  downloadCompanies = () => {
    return axios.get(config.backendURL + '/export-downloadCompanies',
    {
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });
  };

  getNotifications = (eventId: string, companyId: string) => {
    return axios.get(config.backendURL + `/company-getNotifications?eventId=${eventId}&companyId=${companyId}`);
  };

  deleteNotification = (eventId: string, companyId: string, messageId: string) => {
    return axios.delete(config.backendURL + `/company-deleteNotification?eventId=${eventId}&companyId=${companyId}&messageId=${messageId}`);
  };

}

const companyServices = new CompanyServices();
export default companyServices;


