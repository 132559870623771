import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
    Button,
    IconButton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    InputAdornment,
    LinearProgress,
    Checkbox,
    FormControlLabel,
    TableSortLabel
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useDialog } from "../../shared/tools/CustomHooks";
import ChangePwdIcon from '@mui/icons-material/Update';
import SearchIcon from '@mui/icons-material/Search';
import UsersServices from '../../../services/users.services';
import { FORMAT_DATE, PROFILES, getProfileLabel } from '../../shared/Constantes';
import "./users.scss";
import { ConfirmDialog } from "../../shared/ConfirmDialog/ConfirmDialog";
import { UserForm } from "./UserForm";
import firebase from '../../../config/firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Users = (props: any) => {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isEla, setIsEla] = useState(true);
    const [isCompany, setIsCompany] = useState(true);
    const [isSchool, setIsSchool] = useState(true);
    const [users, setUsers] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [openSup, toggleSup] = useDialog(false);
    const [openInit, toggleInit] = useDialog(false);
    const [open, toggle] = useDialog(false);
    const [orderBy, setOrderBy] = useState('role');
    const [order, setOrder] = useState('asc');

    const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(event.target.value as string);
    };

    const reset = () => {
        setSelectedUser(null);
    };

    const filterUserCompanies = useCallback((list: any) => {
        let found = false;
        list.forEach((comp: any) => {
            if (comp.name.toLowerCase().includes(search.toLowerCase())) {
                found = true;
            }
        });
        return found;
    }, [search]);

    const filteredUsers = useMemo(() => {
        return users.filter((item: any) => {
            return (
                ((item.email && (item.email as string).toLowerCase().includes(search.toLowerCase()))
                    || (item.companies && filterUserCompanies(item.companies))
                )
                && ((isCompany && item.role === PROFILES.COMPANY_ADMIN.code)
                    || (isEla && item.role === PROFILES.ADMIN.code)
                    || (isSchool && item.role === PROFILES.SCHOOL_ADMIN.code))
            );
        }).sort((a: any, b: any) => {
            if (!a[orderBy]) {
                return (order === 'asc' ? -1 : 1);
            }
            if (!b[orderBy]) {
                return (order !== 'asc' ? -1 : 1);
            }
            const labelFunction = (x: any) => x
            if (orderBy === 'creationDate' || orderBy === 'lastLoginDate') {
                return (order === 'asc') ?
                dayjs(a[orderBy]).isAfter(dayjs(b[orderBy])) ? 1 : -1
                : dayjs(a[orderBy]).isBefore(dayjs(b[orderBy])) ? 1 : -1
            }
            if (order === 'asc') {
                return labelFunction(a[orderBy]).toString().localeCompare(labelFunction(b[orderBy]).toString());
            } else {
                return labelFunction(b[orderBy]).toString().localeCompare(labelFunction(a[orderBy]).toString());
            }
        })
    }, [users, search, isEla, isCompany, isSchool, filterUserCompanies, orderBy, order]);

    const loadUsers = useCallback(() => {
        setIsLoading(true);
        UsersServices.getUsers().then((res: any) => {
            setUsers(res.data);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            setUsers([]);
            toast.error(t('generic.error'), { position: 'bottom-right' });
        });
    }, [t]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const deleteUser = () => {        
        UsersServices.deleteUser(selectedUser.userId).then(() => {
            toggleSup();
            setSelectedUser(null);
            loadUsers();
        }).catch(() => {
            toast.error(t('users.delete.error', { user: selectedUser.name }), { position: 'bottom-right', autoClose: false });
        });
    };

    const reinitPassword = () => {
        const auth = getAuth(firebase);
        sendPasswordResetEmail(auth, selectedUser.email).then(() => {
            toast.success(t('users.reset.success', { email: selectedUser.email }), { position: 'bottom-right' });
            toggleInit();
        }).catch((err) => {
            toast.error(t('users.reset.error', { email: selectedUser.email }), { position: 'bottom-right', autoClose: false });
        });
    };

    const nameOfCompanies = (values: any) => values.reduce((acc: string, value: any, index: number, array: any) => {
        if (index === array.length - 1) {
            return acc + value.name;
        } else {
            return acc + value.name + ', ';
        }
    }, '');

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const refresh = () => {
        setIsLoading(true);
        loadUsers();
    };

    return (         
        <>
            <div className="margin-bottom-25 filter-menu">
                <div className="filters">
                    <TextField
                        id="standard-basic"
                        label={t('users.search')}
                        type="search"
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        style={{ width: '400px' }}
                        onChange={handleSearchChange}
                    />
                    <IconButton 
                        title={t('participant.refresh')}
                        size="small"
                        onClick={() => refresh()}
                    >
                    <RefreshIcon fontSize="inherit" />
                    </IconButton>
                </div>

                {open && 
                    <UserForm
                        open={true}
                        toggle={toggle}
                        user={selectedUser}
                        refresh={() => loadUsers()}
                        reset={() => reset()}
                    />
                }
                    
                {openSup && 
                    <ConfirmDialog
                        open={true}
                        handleClose={() => {
                            toggleSup();
                            setSelectedUser(null);
                        }}
                        handleAction={() => deleteUser()}
                        title={t('users.delete.title')}
                        message={selectedUser ? t('users.delete.description', { user: selectedUser.name }) : ''}
                        confirmLabel={t('button.validate')}
                    />
                }
                    
                {openInit &&                            
                    <ConfirmDialog
                        open={true}
                        handleClose={() => {
                            toggleInit();
                            setSelectedUser(null);
                        }}
                        handleAction={() => reinitPassword()}
                        title={t('users.password.title')}
                        message={selectedUser ? t('users.password.descritpion', { user: selectedUser.name }) : ''}
                        confirmLabel={t('button.validate')}
                    />
                }

                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    endIcon={<AddIcon />}
                    className="add-button"
                    onClick={toggle}
                    size="small"
                >
                    {t('users.add')}
                </Button>
                    
            </div>

            {isLoading && <LinearProgress />}

            <div className="margin-20">
                {t('users.role')}&nbsp;
                <FormControlLabel
                    control={<Checkbox
                        checked={isEla}
                        onChange={(e) => setIsEla(e.target.checked)}
                        name="isEla"
                        color="primary" />}
                    label={t(PROFILES.ADMIN.label)}
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={isCompany}
                        onChange={(e) => setIsCompany(e.target.checked)}
                        name="isCompany"
                        color="primary" />}
                    label={t(PROFILES.COMPANY_ADMIN.label)}
                />

                <FormControlLabel
                    control={<Checkbox
                        checked={isSchool}
                        onChange={(e) => setIsSchool(e.target.checked)}
                        name="isSchool"
                        color="primary" />}
                    label={t(PROFILES.SCHOOL_ADMIN.label)}
                />
            </div>

            <div className="section margin-20">
                <div className="section-info">
                    {filteredUsers && t('users.users', { nb: filteredUsers.length })}
                </div>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                    active={orderBy === 'email'}
                                    direction={orderBy === 'email' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('email')}
                                >
                                    {t('users.table.email')}
                                </TableSortLabel></TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('name')}
                                >
                                    {t('users.table.name')}
                                </TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                    active={orderBy === 'role'}
                                    direction={orderBy === 'role' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('role')}
                                >
                                    {t('users.table.role')}
                                </TableSortLabel></TableCell>
                                <TableCell style={{ width: '20%' }}>

                                    {t('users.table.company')}
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                    active={orderBy === 'creationDate'}
                                    direction={orderBy === 'creationDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('creationDate')}
                                >
                                    {t('users.table.creationDate')}
                                </TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <TableSortLabel
                                    active={orderBy === 'lastLoginDate'}
                                    direction={orderBy === 'lastLoginDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('lastLoginDate')}
                                >
                                    {t('users.table.lastConnexion')}
                                </TableSortLabel></TableCell>
                                <TableCell align="right" style={{ width: '10%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers && filteredUsers.map((user: any) => (
                                <TableRow key={user.email} hover>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.name ? user.name : '-'}</TableCell>
                                    <TableCell>{t(getProfileLabel(user.role))}</TableCell>
                                    <TableCell
                                        title={user.companies && nameOfCompanies(user.companies).length > 50 ? nameOfCompanies(user.companies) : ''}
                                    >
                                        {user.companies && (
                                            nameOfCompanies(user.companies).length > 50
                                                ? nameOfCompanies(user.companies).substring(0, 50) + '...'
                                                : nameOfCompanies(user.companies)
                                        )}
                                    </TableCell>
                                    <TableCell>{dayjs(user.creationDate).format(FORMAT_DATE)}</TableCell>
                                    <TableCell>{user.lastLoginDate ? dayjs(user.lastLoginDate).format(FORMAT_DATE) : '-'}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            title={t('users.passwordReinit')}
                                            onClick={() => {
                                                setSelectedUser(user);
                                                toggleInit();
                                            }}
                                        >
                                            <ChangePwdIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={() => {
                                                setSelectedUser(user);
                                                toggle();
                                            }}
                                        >
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={() => {
                                                setSelectedUser(user);
                                                toggleSup();
                                            }}
                                        >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}