import React, { useContext } from "react";
import { Button } from '@mui/material';
import { NavLink, Link } from 'react-router-dom';
import { AuthUserContext } from 'AuthUserContext';
import Auth from 'services/auth.services';
import { useTranslation } from 'react-i18next';
import logoELA from '../../assets/img/ela-logo.svg';
import myAccountImg from '../../assets/img/my-account.svg';
import challengesImg from '../../assets/img/challenges.svg';
import orgaImg from '../../assets/img/organisations.svg';
import runnersImg from '../../assets/img/runners.svg';
import settingsImg from '../../assets/img/settings.svg';
import logoutImg from '../../assets/img/logout.svg';
interface MenuProps {  
  onClick: (item: any) => void;
}

export const Menu = (props: MenuProps) => {

  const { t } = useTranslation();
  const {user, companyName} = useContext(AuthUserContext);

  return (
    <div className="menu">

      <div className="logo">
        <Link to="/" onClick={() => props.onClick(false)}>
          <img src={logoELA} alt="Logo ELA"/>
        </Link>
      </div>

      <div className="nav">
        <NavLink
          to="/my-account"
          className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
          onClick={() => props.onClick(false)}
        >
          <Button>
            <img src={myAccountImg} alt="Challenges button" className="test"/>
            <div className="label">{t('menu.account')}</div>
          </Button>
        </NavLink>

        {user && user.isAdmin &&
          <>
            <NavLink
              to="/events"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
              onClick={() => props.onClick(true)}
            >
              <Button>
                <img src={challengesImg} alt="Challenges button" className="test"/>
                <div className="label">{t('menu.challenges')}</div>
              </Button>
            </NavLink>
            <NavLink
              to="/companies"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
              onClick={() => props.onClick(true)}
            >
              <Button>
                <img src={orgaImg} alt="Companies button"/>
                <div className="label">{t('menu.organisations')}</div>
              </Button>
            </NavLink>

            <NavLink
              to="/runners"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
              onClick={() => props.onClick(true)}
            >
              <Button>
                <img src={runnersImg} alt="Log out button"/>
                <div className="label">{t('menu.runners')}</div>
              </Button>
            </NavLink>
            
            <NavLink
              to="/params"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
              onClick={() => props.onClick(false)}
            >
              <Button>
                <img src={settingsImg} alt="Users button"/>
                <div className="label">{t('menu.parameters')}</div>
              </Button>
            </NavLink>
          </>
        }
        
        {user && (user.isCompanyAdmin || user.isSchoolAdmin) &&
          <>
            <NavLink
              to="/my-company"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
            >
              <Button>
                <img src={orgaImg} alt="Challenges button" className="test"/>
                <div className="label">{t('menu.organisation')}</div>
              </Button>
            </NavLink>

            <NavLink
              to="/dashboard-company"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
            >
              <Button>
                <img src={challengesImg} alt="Challenges button" className="test"/>
                <div className="label">{t('menu.challenges')}</div>
              </Button>
            </NavLink>

            <NavLink
              to="/runners"
              className={({ isActive }) => `menu-item ${isActive ? 'active-item' : ''}`}
            >
              <Button>
                <img src={runnersImg} alt="Log out button"/>
                <div className="label">{t('menu.runners')}</div>
              </Button>
            </NavLink>
          </>     
        }

        <div style={{
          fontSize: 'smaller',
          marginTop: 50,
          textAlign: 'center',
          color: '#C5326E'
        }}>
          {user.name}<br/>
          {companyName}
        </div>
        <Button className="menu-item" onClick={() => Auth.logout()}>
          <img src={logoutImg} alt="Log out button"/>
          <div className="label">{t('menu.logout')}</div>
        </Button>
       </div>

    </div>
  );
}
