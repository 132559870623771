import { getAuth, signOut } from 'firebase/auth';
import firebase from './../config/firebase';

class Auth {

  logout = () => {
    const auth = getAuth(firebase);
    signOut(auth).then(() => {
      console.log("Logout successful");
      window.location.assign('/');
    }).catch((error) => {
      console.log("Error during logout");
      window.location.assign('/');
    });
  };
}

const authService = new Auth();

export default authService;


