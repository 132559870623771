import React, {useState, useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress
} from '@mui/material';
import CompanyServices from '../../../services/company.services';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

interface UpdateEventNameProps {
  open: boolean;
  event: any;
  toggle: () => void;
  refresh: () => void;
}

export const UpdateEventName = (props: UpdateEventNameProps) => {  
  const { t } = useTranslation();
  const {companyId } = useContext(AuthUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState(props.event.name);
  
  const update = (event: any) => {
    setIsSubmitting(true);
    CompanyServices.updateEventName(companyId, {id: props.event.id, name: name}).then(() => {
          setIsSubmitting(false);
          props.toggle();
          props.refresh();
        }).catch((error) => {
          if (error.response.status === 403) {
            toast.error(t('events.update.name.error'), { position: 'bottom-right', autoClose: false });            
          } else {
            toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
          }
          setIsSubmitting(false);
    });
    event.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {t("events.update.name.title")}
      </DialogTitle>

      <form onSubmit={update} >        
        <DialogContent>
          <TextField
            value={name}
            inputProps={{maxLength: 50}}
            helperText={t('events.name.helper')}
            required
            variant="outlined"
            name="contact"
            label={t('event.name')}
            type="text"
            onChange={(event) => setName(event.target.value as string)}
            fullWidth
            className="margin-bottom-20"
          />

          {isSubmitting && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggle}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>          
      </form>
    </Dialog>
  );
}
