import React, { useEffect, useState, useMemo } from "react";
import {
  LinearProgress, TextField,
  InputAdornment, Button, Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
  IconButton,
  FormControl
} from '@mui/material';
import "./dashboard.scss";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
// @ts-ignore
import { AddEventModal } from '../../shared/add-event/add-event';
import EventServices from '../../../services/event.services';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from '../../shared/tools/CustomHooks';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { eventType, countries, getTypeLabel, getCountryLabel, getCountryTimezone, FORMAT_DATE_TIME } from "components/shared/Constantes";
import { ConfirmDialog } from "../../shared/ConfirmDialog/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/Visibility";
import MultiIcon from '@mui/icons-material/Group';
import MonoIcon from '@mui/icons-material/Person';
import { download } from '../../shared/tools/Utils';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';

dayjs.extend(utc)
dayjs.extend(timezone)

export const Dashboard = (props: any) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [events, setEvents] = useState<any>([]);

  const [search, setSearch] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [country, setCountry] = useState("");
  const [orderBy, setOrderBy] = useState('startDate');
  const [order, setOrder] = useState('asc');

  const [isMTBE, setIsMTBE] = useState(false);
  const [isMTB, setIsMTB] = useState(false);
  const [isGP, setIsGP] = useState(false);
  const [isTPE, setIsTPE] = useState(false);

  const navigate = useNavigate();
  const [open, toggle] = useDialog(false);
  const [openSup, toggleSup] = useDialog(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isEnabled, setIsEnabled] = useState(true);
  
  const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchEvent(event.target.value as string);
  };

  const loadEvents = (type: string) => {
    setIsLoading(true);
    EventServices.getAllEvents(type)
      .then((result: any) => {
        setEvents(result.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    if (selectedEvent !== undefined && selectedEvent !== null) {
      EventServices.deleteEvent(selectedEvent.id)
      .then(() => {
        toggleSup();
        loadEvents(search);
      })
      .catch((error) => toast.error(error.message, { position: 'bottom-right', autoClose: false }));
    }    
  };

  useEffect(() => {
    setSearch('progress');
    setIsMTBE(true);
    setIsMTB(true);
    setIsGP(true);
    setIsTPE(true);
    loadEvents('progress');
  }, []);

  const filteredEvent = useMemo(() => {
    return events.filter((item: any) => {
      return (
        (
          (item.name && (item.name as string).toLowerCase().includes(searchEvent.toLowerCase()))
          && (
            ((isMTBE && item.type === eventType.MTBE.code) || (isMTB && item.type === eventType.MTB.code) ||
              (isGP && item.type === eventType.GP.code) || (isTPE && item.type === eventType.TPE.code) || (!item.type)
              || (item.type !== eventType.MTBE.code && item.type !== eventType.MTB.code && item.type !== eventType.GP.code && item.type !== eventType.TPE.code))
          )
          && (!country || (country && item.location === country))
        )
      );
    }).sort((a:any,b:any) => {
      if (!a[orderBy]) {
        return (order === 'asc'?-1:1);
      }
      if (!b[orderBy]) {
        return (order !== 'asc'?-1:1);
      }
      if (orderBy === 'startDate' || orderBy === 'endDate' || orderBy === 'creationDate') {
        return (order === 'asc') ?
        dayjs(a[orderBy]).isAfter(dayjs(b[orderBy])) ? 1 : -1
        :dayjs(a[orderBy]).isBefore(dayjs(b[orderBy])) ? 1 : -1
    }
      const labelFunction = (orderBy === 'location')?getCountryLabel:(orderBy === 'type')?getTypeLabel:(x:any)=>x
      if (order === 'asc') {
        return labelFunction(a[orderBy]).localeCompare(labelFunction(b[orderBy]));
      } else {
        return labelFunction(b[orderBy]).localeCompare(labelFunction(a[orderBy]));
      }
    });
  }, [events, searchEvent, isMTBE, isMTB, isGP, isTPE, country, orderBy, order]);


  const resetSearch = (value: string) => {
    setSearch(value);
    loadEvents(value);
    setSearchEvent('');
    if (value === 'past') {
      setOrderBy('endDate');
      setOrder('desc');
    } else {setOrderBy('startDate');
    setOrder('asc');}
  };

  const goToDetail = (id: string) => {
    navigate(`/event/${id}`);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const downloadEvents = () => {
    setIsEnabled(false);
    setIsLoading(true);
    EventServices.downloadEvents()
    .then((response) => {
      download(
        response.data,
        `Challenges_${dayjs().toISOString()}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
      setIsEnabled(true);
      setIsLoading(false);
    }).catch(() => {
      setIsEnabled(true);
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
    });
  };

  const TableWithEvents = () => {
    return (
      <>
        <div className="section-info">
          {filteredEvent && t('events.events', { nb: filteredEvent.length })}
        </div>
        
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('name')}
                  >{t('event.name')}
                  </TableSortLabel></TableCell>
              <TableCell style={{ width: '10%' }}>
                <TableSortLabel
                    active={orderBy === 'startDate'}
                    direction={orderBy === 'startDate' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('startDate')}
                  >{t('events.startDate')}</TableSortLabel></TableCell>
              <TableCell style={{ width: '10%' }}>
                <TableSortLabel
                    active={orderBy === 'endDate'}
                    direction={orderBy === 'endDate' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('endDate')}
                  >{t('events.endDate')}</TableSortLabel></TableCell>
              <TableCell style={{ width: '15%' }}>
                <TableSortLabel
                    active={orderBy === 'type'}
                    direction={orderBy === 'type' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('type')}
                  >{t('event.type')}</TableSortLabel></TableCell>
              <TableCell style={{ width: '10%' }}>
                <TableSortLabel
                    active={orderBy === 'location'}
                    direction={orderBy === 'location' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('location')}
                  >{t('events.country.label')}</TableSortLabel></TableCell>
              <TableCell style={{ width: '10%' }}>
                <TableSortLabel
                    active={orderBy === 'creationDate'}
                    direction={orderBy === 'creationDate' ? order==='asc'?'asc':'desc' : 'asc'}
                    onClick={createSortHandler('creationDate')}
                  >{t('events.date.creation')}</TableSortLabel></TableCell>
                <TableCell style={{ width: '8%' }}></TableCell>
              </TableRow>
              
            </TableHead>
          <TableBody>
            {
              filteredEvent && filteredEvent.map((item: any) => (
                <TableRow key={item.id} hover>
                  <TableCell>
                    {item.isSingle && <MonoIcon fontSize="inherit" style={{color: 'grey'}}/>}
                    {!item.isSingle && <MultiIcon fontSize="inherit" style={{color: 'grey'}}/>}
                    &nbsp;
                    {item.name}
                  </TableCell>
                  <TableCell>{dayjs(item.startDate).tz(getCountryTimezone(item.location)).format(FORMAT_DATE_TIME)}</TableCell>
                  <TableCell>{dayjs(item.endDate).tz(getCountryTimezone(item.location)).format(FORMAT_DATE_TIME)}</TableCell>
                  <TableCell>{t(getTypeLabel(item.type))}</TableCell>
                  <TableCell>{t(getCountryLabel(item.location))}</TableCell>
                  <TableCell>{dayjs(item.creationDate).format(FORMAT_DATE_TIME)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => goToDetail(item.id)}
                      >
                          <ViewIcon fontSize="inherit" />
                      </IconButton>
                    {item.isEditable &&
                      <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => {
                            toggleSup();
                            setSelectedEvent(item);
                          }}
                      >
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    }
                </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          </Table>
      </TableContainer>
      </>
    );
  };

return (
  <>
          {openSup && 
            <ConfirmDialog
              open={true}
              handleClose={() => {
                toggleSup();
                setSelectedEvent(null);
              }}
              handleAction={() => handleDelete()}
              title={t('dialog.title.delete')}
              message={selectedEvent ? t('detail.message.delete', {event: selectedEvent.name}) : ''}
              confirmLabel={t('button.validate')}
            />
          }
            
          <div className="margin-bottom-15 filter-menu">
            <div className="filters">
              <div style={{ width: 800 }}>
                <TextField
                  id="standard-basic"
                  label={t('events.search')}
                  variant="standard"
                  type="search"
                  value={searchEvent}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '400px' }}
                  onChange={handleSearchChange}
                />
                <div style={{ display: "inline-block", marginLeft: 20 }}>
                  <FormControl>
                  <InputLabel  id="country-label">
                    {t('orga.country')}
                  </InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    variant="standard"
                    label={t('orga.country')}
                    value={country}
                    style={{ width: 300 }}
                    onChange={(event) => setCountry(event.target.value as string)}
                  >
                    <MenuItem value="">
                      <em>{t('events.country.none')} </em>
                    </MenuItem>
                    {countries.map(({ label, code }) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
                  </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className="add-button"
                  endIcon={<AddIcon />}
                  size="small"
                  onClick={toggle}
                >
                  {t('events.add')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  endIcon={<DownloadIcon />}
                  onClick={() => downloadEvents()}
                  disabled={!isEnabled}
                  size="small"
                  style={{marginLeft:20}}
                >
                    {t('button.export')}
                </Button>
              
          </div>

          <div style={{height:'20px'}}>
            {isLoading && <LinearProgress />}
          </div>

          <div>
            <FormControlLabel
              control={<Checkbox
                checked={isMTBE}
                onChange={(e) => setIsMTBE(e.target.checked)}
                name="isMTBE"
                color="primary" />}
              label={t(eventType.MTBE.label)}
            />
            <FormControlLabel
              control={<Checkbox
                checked={isMTB}
                onChange={(e) => setIsMTB(e.target.checked)}
                name="isMTB"
                color="primary" />}
              label={t(eventType.MTB.label)}
            />
            <FormControlLabel
              control={<Checkbox
                checked={isGP}
                onChange={(e) => setIsGP(e.target.checked)}
                name="isGP"
                color="primary" />}
              label={t(eventType.GP.label)}
            />
            <FormControlLabel
              control={<Checkbox
                checked={isTPE}
                onChange={(e) => setIsTPE(e.target.checked)}
                name="isTPE"
                color="primary" />}
              label={t(eventType.TPE.label)}
            />
          </div>

          <div className="section margin-top-20">

            <>
              <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                <Tab
                  label={t('events.in.progress')}
                  {...a11yProps(0)}
                  onClick={(e) => resetSearch('progress')} />
                <Tab
                  label={t('events.next')}
                  {...a11yProps(1)}
                  onClick={(e) => resetSearch('next')} />
                <Tab
                  label={t('events.past')}
                  {...a11yProps(2)}
                  onClick={(e) => resetSearch('past')} />
              </Tabs>
              
              <TabPanel value={value} index={0}>
                <TableWithEvents />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <TableWithEvents />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <TableWithEvents />
              </TabPanel>
              
            </>

          </div>

      {open && (
        <AddEventModal
          open={true}
          toggle={toggle}
          refresh={() => {
            loadEvents(search)
          }}
        />
      )}
      </>
)
}
