import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  TextField,
  LinearProgress,
  CardActions,
  Switch,
  FormControlLabel
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ParamsServices from "../../../services/params.services";
import { toast } from 'react-toastify';

export const Labels = (props: any) => {

  const { t } = useTranslation();

  const [labels, setLabels] = useState(null);
  
  const [enabled, setEnabled] = useState(false);
  const [fr, setFr] = useState('');
  const [en, setEn] = useState('');
  const [de, setDe] = useState('');
  const [lu, setLu] = useState('');
  const [es, setEs] = useState('');
  const [it, setIt] = useState('');
  const [nl, setNl] = useState('');
  const [pt, setPt] = useState('');
  const [da, setDa] = useState('');
  const [fi, setFi] = useState('');
  const [no, setNo] = useState('');
  const [se, setSe] = useState('');
  const [cz, setCz] = useState('');
  const [hu, setHu] = useState('');
  const [pl, setPl] = useState('');
  const [ro, setRo] = useState('');
  const [sk, setSk] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  

  const loadLabels = useCallback(() => {
    setIsLoading(true);
    ParamsServices.getLabels()
    .then((result: any) => {
      const results = result.data;
      setLabels(results);
      setEnabled(results.enabled);
      if (results.fr) {
        setFr(results.fr);
      }
      if (results.en) {
        setEn(results.en);
      }
      if (results.de) {
        setDe(results.de);
      }
      if (results.lu) {
        setLu(results.lu);
      }
      if (results.it) {
        setIt(results.it);
      }
      if (results.es) {
        setEs(results.es);
      }
      if (results.nl) {
        setNl(results.nl);
      }
      if (results.pt) {
        setPt(results.pt);
      }
      if (results.da) {
        setDa(results.da);
      }
      if (results.fi) {
        setFi(results.fi);
      }
      if (results.no) {
        setNo(results.no);
      }
      if (results.se) {
        setSe(results.se);
      }
      if (results.cz) {
        setCz(results.cz);
      }
      if (results.hu) {
        setHu(results.hu);
      }
      if (results.pl) {
        setPl(results.pl);
      }
      if (results.ro) {
        setRo(results.ro);
      }
      if (results.sk) {
        setSk(results.sk);
      }
      setIsLoading(false);
    }).catch((err: any) => {
        console.log(err);
        setIsLoading(true);
    });
},[]);

  useEffect(() => {
      loadLabels();
  }, [loadLabels]);

  const submit = (e: any) => {
    setIsSubmitting(true);
    const labelsUpdated = { enabled, fr, en, de, it, es, lu, pt, nl, da, fi, no, se, cz, hu, pl, ro, sk };
    ParamsServices.updateLabels(labelsUpdated).then(() => {
      setIsSubmitting(false);
      if (enabled) {
        toast.success(t('params.label.update.success') + t('params.label.update.enabled'), { position: 'bottom-right' });
      } else {
        toast.success(t('params.label.update.success') + t('params.label.update.disabled'), { position: 'bottom-right' });
      }
      setLabels(labelsUpdated);      
    }).catch((err: any) => {
      setIsSubmitting(false);
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
    });
    e.preventDefault();
  };

  const cancel = (e: any) => {    
    setFr(labels.fr);
    setEn(labels.en);
    setDe(labels.de);
    setLu(labels.lu);
    setIt(labels.it);
    setEs(labels.es);
    setPt(labels.pt);
    setNl(labels.nl);
    setDa(labels.da);
    setFi(labels.fi);
    setNo(labels.no);
    setSe(labels.se);
    setCz(labels.cz);
    setHu(labels.hu);
    setPl(labels.pl);
    setRo(labels.ro);
    setSk(labels.sk);
    setEnabled(labels.enabled);
    e.preventDefault();
  };

  

  return (
    <form onSubmit={submit}>      

      <div style={{height:10}}>
        {isLoading && <LinearProgress/>}
      </div>

      <FormControlLabel
        className="margin-bottom-10"
        control={
          <Switch
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
            name="enabled"
            color="primary"
          />
        }
        label={t('params.label.enabled')}
      />

      <div style={{display: 'flex' , flexDirection: 'row', flex: '1 1'}}>
        <div style={{display: 'flex' , flexDirection: 'column', flex: '0.5 1', padding: 10}}>
          <TextField
            value={fr}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="fr"
            label={t('params.label.fr')}
            type="text"
            onChange={(event) => setFr(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
            
          />

          <TextField
            value={en}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="en"
            label={t('params.label.en')}
            type="text"
            onChange={(event) => setEn(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
          />

          <TextField
            value={de}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="de"
            label={t('params.label.de')}
            type="text"
            onChange={(event) => setDe(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
          />

          <TextField
            value={nl}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="nl"
            label={t('params.label.nl')}
            type="text"
            onChange={(event) => setNl(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={da}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="da"
            label={t('params.label.da')}
            type="text"
            onChange={(event) => setDa(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={no}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="no"
            label={t('params.label.no')}
            type="text"
            onChange={(event) => setNo(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={cz}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="cz"
            label={t('params.label.cz')}
            type="text"
            onChange={(event) => setCz(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={pl}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="pl"
            label={t('params.label.pl')}
            type="text"
            onChange={(event) => setPl(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={sk}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="sk"
            label={t('params.label.sk')}
            type="text"
            onChange={(event) => setSk(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />
        </div>
        
        <div style={{display: 'flex' , flexDirection: 'column', flex: '0.5 1', padding: 10}}>
          <TextField
            value={lu}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="lu"
            label={t('params.label.lu')}
            type="text"
            onChange={(event) => setLu(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
          />

          <TextField
            value={es}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="es"
            label={t('params.label.es')}
            type="text"
            onChange={(event) => setEs(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
          />

          <TextField
            value={it}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="it"
            label={t('params.label.it')}
            type="text"
            onChange={(event) => setIt(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
            className="margin-bottom-10"
          />

          <TextField
            value={pt}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="pt"
            label={t('params.label.pt')}
            type="text"
            onChange={(event) => setPt(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={fi}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="fi"
            label={t('params.label.fi')}
            type="text"
            onChange={(event) => setFi(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={se}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="se"
            label={t('params.label.se')}
            type="text"
            onChange={(event) => setSe(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={hu}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="hu"
            label={t('params.label.hu')}
            type="text"
            onChange={(event) => setHu(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />

          <TextField
            value={ro}
            helperText={t('params.label.characters.300')}
            inputProps={{maxLength: 300}}
            required
            variant="outlined"
            id="ro"
            label={t('params.label.ro')}
            type="text"
            onChange={(event) => setRo(event.target.value as string)}
            fullWidth
            multiline
            rows="3"
          />
        </div>
      </div>

      <div style={{height:10}}>
        {isSubmitting && <LinearProgress/>}
      </div>
      
      <CardActions style={{textAlign:'right'}}>
        
        <Button
            onClick={(e) => cancel(e)}
        >
            {t('button.cancel')}
        </Button>
        
        <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
        >
            {t('button.validate')}
        </Button>

      </CardActions>
    </form>
  )
};
