import React, {useEffect, useState, useContext, useCallback} from "react";
import {Button, LinearProgress} from '@mui/material';
import SendOutlined from '@mui/icons-material/SendOutlined';
import Notifications from '@mui/icons-material/NotificationsOutlined';
import InsertCommentOutlined from '@mui/icons-material/InsertCommentOutlined';
import "./DashboardCompany.scss"
import {EventTable} from './EventTable';
import CompanyServices from '../../../services/company.services';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import config from '../../../config/config';
import QRCodeCanvas from 'qrcode.react';
import firebase from '../../../config/firebase';
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import { NotificationModal } from '../detail/NotificationDialog';
import { NotificationsListModal } from '../detail/NotificationsListDialog';
import { MessageModal } from './MessageDialog';
import {useDialog} from '../../shared/tools/CustomHooks';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UpdateParametersModal } from "./UpdateParameters";
import {formatterCurrency, formatNameOfDocument} from '../../shared/tools/Toolbox';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import { DIR_LOGO, FORMAT_DATE, getCountryTimezone } from '../../shared/Constantes';
import EventServices from '../../../services/event.services';
import {toast} from "react-toastify";
import i18next from 'i18next';
import {download} from '../../shared/tools/Utils';
import {ConfirmDialog} from '../../shared/ConfirmDialog/ConfirmDialog';
import listImg from '../../../assets/img/list.svg';
import calendarImg from '../../../assets/img/calendar_2.svg';
import clockImg from '../../../assets/img/clock.svg';
import footImg from '../../../assets/img/foot.svg';
import shareImg from '../../../assets/img/share.svg';
import { UpdateEventLogo } from "./UpdateEventLogo";

dayjs.extend(utc)
dayjs.extend(timezone)

export const EventsCompany = (props: any) => {
  const { t } = useTranslation();
  
  const [events, setEvents] = useState<any>([]);
  const [event, setEvent] = useState(null);
  const [openNotif, toggleNotif] = useDialog(false);
  const [openNotifList, toggleNotifList] = useDialog(false);
  const [openMessage, toggleMessage] = useDialog(false);
  const [openParams, toggleParams] = useDialog(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [language] = useState(i18next.language);
  const [selectedNotif, setSelectedNotif] = useState(null);
  const [openSupNotif, toggleSupNotif] = useDialog(false);
  const [openLogo, toggleLogo] = useDialog(false);

  const {companyId} = useContext(AuthUserContext);

  const updateNotif = (item: any) => {
    setSelectedNotif(item);
    toggleNotifList();
    toggleNotif();
  };

  const resetNotif = () => {
    setSelectedNotif(null);
    toggleNotifList();
    toggleNotif();
  };

  const newNotif = () => {
    setSelectedNotif(null);
    toggleNotifList();
    toggleNotif();
  };

  const deleteNotif = (item: any) => {
    setSelectedNotif(item);
    toggleNotifList();
    toggleSupNotif();
  };

  const handleCloseSupNotif = () => {
    toggleSupNotif();
    setSelectedNotif(null);
    toggleNotifList();
  };

  const handleDeleteNotif = () => {
    if (selectedNotif !== null) {
      CompanyServices.deleteNotification(event.id, companyId, selectedNotif.id).then(() => {
        toggleSupNotif();
        setSelectedNotif(null);      
        toggleNotifList();
      })
      .catch((error) => toast.error(error.message, { position: 'bottom-right', autoClose: false }));      
    }
  };

  const downloadQR = () => {
    const canvas: any = document.querySelector('.qrcode > canvas');
    const pngUrl = canvas.toDataURL();
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadUserGuide = () => {
    setIsLoading(true);
    EventServices.downloadUserGuide(event.id, companyId, getCountryTimezone(event.location)).then((response) => {
      download(
        response.data,
        formatNameOfDocument(event.qrcode +'_'+ event.name, '.pdf'),
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      setIsLoading(false);
    }).catch(() => {
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
    });
  };

  const refresh = () => {
    loadEvents();
  };

  const loadEvents = useCallback(() => {
    setIsLoading(true);    
    CompanyServices.getEvents(companyId).then((result: any) => {
      setEvents(result.data);
      if (result.data.length > 0) {        
        setEvent(result.data[0]);
      }
      setIsLoading(false);
    }).catch(err => console.error(t('generic.error'), err.message));
  },[companyId, t]);

  const getUrl = (logo: string) => {
    const storage = getStorage(firebase);
    const storageRef = ref(storage, DIR_LOGO.event + logo);
    getDownloadURL(storageRef).then((url) => {
      setUrl(url);
    });
  };

  useEffect(() => {
    if (event !== null && event.logo !== undefined && event.logo !== null) {
      getUrl(event.logo);
    } else {
      getUrl('ELA.png');
    }
  }, [event]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return (
    <>
          <div className="infos-panel left">
            <div className="section margin-bottom-30">
              <div className="section-title margin-bottom-20">
                <img src={listImg} alt="List"/>{t('events.list')}
              </div>
              <div className="section-content">
                {isLoading && <LinearProgress/>}
                <EventTable 
                  events={events} 
                  setEvent={setEvent} 
                  event={event} 
                  refresh={()=> loadEvents()}
                />
              </div>
            </div>
          </div>

          {event &&
          <div className="infos-panel right">
            {event.isNameEditable && // name is editable if the event has only one company so we can also edit logo
              <div className="edit">
                <Button
                  className="action-link"
                  color="primary"
                  size="small"
                  onClick={toggleLogo}
                  endIcon={<EditOutlinedIcon />}
                >
                  {t('button.update')}
                </Button>
                {openLogo &&
                  <UpdateEventLogo
                    eventId={event.id}
                    open={true}
                    toggle={toggleLogo}
                    refresh={() => refresh()}
                    isCompany
                    companyId={companyId}
                  />
                }
              </div>
            }

            <div className="logo">
              {url && <img src={url} alt="event logo" />}
            </div>

            {event.isParamsEditable && 
              <div className="edit">
            
                <Button
                  className="action-link"
                  color="primary"
                  size="small"
                  onClick={toggleParams}
                  endIcon={<EditOutlinedIcon />}
                >
                  {t('button.update').toUpperCase()}
                </Button>
  
                {openParams && 
                  <UpdateParametersModal
                    open={true}
                    toggle={toggleParams}
                    displayGaugeCompanies={event.displayGaugeCompanies}
                    index={event.index}
                    limitAmount={event.limitAmount}
                    link={event.link}
                    hashtag1={event.hashtag1}
                    hashtag2={event.hashtag2}
                    hashtag3={event.hashtag3}
                    event={event.id}
                    company={companyId}
                    refresh={() => refresh()}
                  />
                }                
              </div>
            }

            <div className="section-title" style={{color: "#C5326E"}}>
              {t('events.event')}&nbsp;<b>{event.name}</b>
            </div>

            <div className="section">
              <div className="section-content">
                <div className="section-title">
                  <img src={calendarImg} alt="In progress"/>
                  {t('events.startDate')}&nbsp;:&nbsp;<strong>{dayjs(event.startDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE)}
                  </strong>
                </div>
             
                <div className="section-title">
                  <img src={calendarImg} alt="In progress"/>
                  {t('events.endDate')}&nbsp;:&nbsp;<strong>{dayjs(event.endDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE)}</strong>
                </div>
              
                <div className="section-title">
                  <img src={clockImg} alt="In progress"/>
                  {t('events.schedule')}&nbsp;<strong>{dayjs(event.startDate).tz(getCountryTimezone(event.location)).format("HH:mm")} - {dayjs(event.endDate).tz(getCountryTimezone(event.location)).format("HH:mm")}</strong>
                </div>

                <div className="section-title">
                  <img src={footImg} alt="In progress"/>{t('events.settings')}
                </div>

                <div className="section-title margin-left-25">
                  {t('events.promise.enabled')}&nbsp;:&nbsp;<strong>
                    {event.isPromiseEnabled ? t('events.promise.enabled.yes') : t('events.promise.enabled.no')}</strong>
                </div>

                <div className="section-title margin-left-25">
                  {t('events.step')}&nbsp;<strong>
                    {event.index !== undefined ? formatterCurrency("EUR").format(event.index) : formatterCurrency("EUR").format(0.01)}</strong>
                </div>

                <div className="section-title margin-left-25">
                  {t('events.amountLimit')}&nbsp;<strong>
                    {event.limitAmount !== undefined && event.limitAmount !== null && event.limitAmount !== 0 ? formatterCurrency("EUR").format(event.limitAmount) : 'Aucun'}
                    </strong>
                </div>
                <div className="section-title margin-left-25">
                  {t('events.update.displayGauge')}&nbsp;:&nbsp;<strong>
                    {event.displayGaugeCompanies !== undefined && !event.displayGaugeCompanies ? t('events.displayGauge.yes') : t('events.displayGauge.no')}
                    </strong>
                </div>

                <div className="section-title">
                  <img src={shareImg} alt="Share"/>{t('events.share')}
                </div>

                <div className="section-title margin-left-25">
                  {t('events.link')}&nbsp;:&nbsp;<strong>
                    {event.link !== undefined && event.link !== null 
                    ? <a href={event.link} target="_blank" rel="noopener noreferrer">{event.link}</a> 
                    : '-'}
                    </strong>
                </div>
                <div className="section-title margin-left-25">
                  {t('events.hashtags')}&nbsp;:&nbsp;<strong>
                    {(event.hashtag1 === undefined || event.hashtag1 === null) 
                      && (event.hashtag2 === undefined || event.hashtag2 === null) 
                      && (event.hashtag3 === undefined || event.hashtag3 === null) 
                    ? '-' : ''}
                    </strong>
                </div>
                {event.hashtag1 !== undefined && event.hashtag1 !== null &&
                  <div className="section-title margin-left-45">
                    <strong>{event.hashtag1}</strong>
                  </div>
                }
                {event.hashtag2 !== undefined && event.hashtag2 !== null &&
                  <div className="section-title margin-left-45">
                    <strong>{event.hashtag2}</strong>
                  </div>
                }
                {event.hashtag3 !== undefined && event.hashtag3 !== null &&
                  <div className="section-title margin-left-45">
                    <strong>{event.hashtag3}</strong>
                  </div>
                }
              </div>
            </div>
            
            <div className="buttons-section">
              <div className="section">
                <div className="section-title">
                  <img src={shareImg} alt="In progress"/>
                  <div>
                    <a href={`${config.dashboardUrl}/#eventId=${event.id}&companyId=${companyId}&lang=${language}`} target="_blank" rel="noopener noreferrer">
                      {t('events.results')}
                    </a>
                  </div>
                </div>
              </div>            

              <div className="section">
                <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    endIcon={<InsertCommentOutlined />}
                    onClick={toggleMessage}
                  >
                    {t('events.postMessage')}
                </Button>
                <MessageModal
                    open={openMessage}
                    toggle={toggleMessage}
                    event={event.id}
                    company={companyId}
                  />
              </div>
            </div>

            <div className="buttons-section">

                <div className="section">
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    endIcon={<SendOutlined />}
                    onClick={toggleNotif}
                  >
                    {t('events.sendNotification')}
                  </Button>
                  
                  <NotificationModal
                      open={openNotif}
                      toggle={toggleNotif}
                      event={event.id}
                      company={companyId}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      country={event.location}
                      notif={selectedNotif}
                      resetNotif={resetNotif}
                      setSelectedNotif={setSelectedNotif}
                    />
                </div>

                <div className="section">
                  
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    endIcon={<Notifications/>}
                    onClick={toggleNotifList}
                  >
                    {t('events.notifications')}
                  </Button>
                  
                  <NotificationsListModal
                      open={openNotifList}
                      toggle={toggleNotifList}
                      event={event.id}
                      company={companyId}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      country={event.location}
                      updateNotif={updateNotif}
                      newNotif={newNotif}
                      deleteNotif={deleteNotif}
                    />
                </div>

                {openSupNotif &&
                    <ConfirmDialog
                        open={true}
                        handleClose={handleCloseSupNotif}
                        handleAction={handleDeleteNotif}
                        title={t('detail.notif.deletion.title')}
                        message={selectedNotif ? t('detail.notif.deletion.content', { notif: selectedNotif.title }) : ''}
                        confirmLabel={t('button.validate')}
                    />
                  }
            </div>

            <div className="qr-code-section">
              <div className="button-container">
                <div className="qr-code margin-bottom-10">
                  <div className="margin-bottom-10">QR Code</div>
                    <div className="qrcode">
                      <QRCodeCanvas value={event.qrcode} />
                    </div>
                  <div className="margin-top-10">{event.qrcode}</div>
                </div>

                <Button
                  className="action-link"
                  color="primary"
                  size="small"
                  onClick={() => downloadQR()}
                  endIcon={<DownloadIcon />}
                >
                  {t('events.downloadQR')}
                </Button>
              </div>
            </div>

            {event.isGuideEnabled && 
              <div className="buttons-section">
                <div className="section margin-bottom-20">
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    onClick={() => downloadUserGuide()}
                    endIcon={<DownloadIcon />}
                  >
                    {t('events.download.guide')}
                  </Button>
                </div>
              </div>
              }
          </div>
          }
        </>
  )
};
