import React, { useContext } from "react";
import {
  Tabs,
  Tab,
  Box,
  AppBar,
  Paper,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { Dashboard } from "./dashboard";
import { EventsCompany } from "../dashboard-company/EventsCompany";
import {AuthUserContext} from 'AuthUserContext';

export const Events = (props: any) => {

  const { t } = useTranslation();
  
  const {companyName} = useContext(AuthUserContext);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            <span >{children}</span>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="content">
      <Paper>
        <AppBar position="static" color="transparent" variant="outlined">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            
            <Tab style={{textTransform: 'none'}}
              label={t('events.menu1')}
              {...a11yProps(1)}
            />
            <Tab style={{textTransform: 'none'}}
              label={t('events.menu2', {companyName: companyName ? companyName : '-' })}
              {...a11yProps(0)}
              disabled={companyName === null}
            />
            
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Dashboard />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="event">
            <EventsCompany />
          </div>
        </TabPanel>

      </Paper>          
    </div>    
  )
};
