import React , {useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import '../css/default.scss'

import {createTheme, ThemeProvider} from '@mui/material/styles';
import firebase from '../config/firebase';
import { getAuth } from 'firebase/auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'react-toastify/dist/ReactToastify.css';
import '../css/toast.scss';
import axios from 'axios';
import i18next from 'i18next';
import {AuthUserContext} from 'AuthUserContext';

import { Login } from './pages/login/login';
import { InitAccount } from './pages/login/init';
import { HomePage } from './pages/home/HomePage';
import { Users } from './pages/users/users';
import { Detail } from './pages/detail/Detail';
import { DashboardCompany } from './pages/dashboard-company/DashboardCompany';
import { Events } from './pages/dashboard/events';
import { Participant } from './pages/participant/participant';
import { MyCompany } from './pages/company/MyCompany/MyCompany';
import { MyAccount } from './pages/account/MyAccount';
import { Params } from './pages/params/parameters';

import { Error } from './pages/error/error';
import { Menu } from './menu/menu';
import { FortgotPassword } from './pages/login/forgot-pwd';
import { SelectCompany } from './shared/selectCompany';
import { Organisations } from './pages/company/organisations';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const supportedLanguages = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt'];

i18next.on('languageChanged', (language) => {
  if (supportedLanguages.includes(language)) {
    dayjs.locale(language);
  } else {
    dayjs.locale('fr');
  }
});

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#DA408A',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0076C6',
      main: '#0076C6',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

axios.interceptors.request.use(
  async config => {
    const auth = getAuth(firebase);
    if(auth.currentUser) {
      config.headers.set('Authorization', `Bearer ${await auth.currentUser.getIdToken(true)}`);
      config.headers.set('Content-Type', `application/json`);
    }
    return config;
  }, (error) => {
    return Promise.reject(error)
  }
);

const App = () => {
  const [enabledSelect, setEnabledSelect] = useState(false);

  const onClickMenu = (item: any) => {
    setEnabledSelect(item);
  }

  const unauthenticatedRouter = () => (
      <HashRouter>
        <Routes>
          <Route path="/myAccount" element={<InitAccount />} />
          <Route path="/resetPassword" element={<FortgotPassword />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </HashRouter>
  );

  const authenticateRouter = () => (
    

    <div className="app-container">
      <Router>
        <Menu onClick={onClickMenu}/>
        <div style={{flexDirection: 'column', width: '100%'}}>
          
          {/* select a company to manage for admin*/}
          <SelectCompany enabled={enabledSelect}/>

          <Routes>

            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/error" element={<Error />} />        
            <Route path="/" element={<HomePage onClick={onClickMenu}/>} />

            {/* routes for admin*/}

            <Route path="/events" element={<Events />} />
            <Route path="/companies" element={<Organisations />} />
            <Route path="/event/:eventId" element={<Detail/>} />
            <Route path="/users" element={<Users/>} />
            <Route path="/params" element={<Params/>} />

            {/* routes for company*/}

            <Route path="/dashboard-company" element={<DashboardCompany />} />
            <Route path="/my-company" element={<MyCompany />} />
            <Route path="/runners" element={<Participant />} />

          </Routes>
        </div>
      </Router>
    </div>
  );

  return (
    <AuthUserContext.Consumer>
    {
      ({isAuthenticated}) => (
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
            <div className="App">
              <main>            
                {!isAuthenticated && unauthenticatedRouter()}
                {isAuthenticated && authenticateRouter()}
              </main>
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      )
    }
    </AuthUserContext.Consumer>
  );
}

export default App;
