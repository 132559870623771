const PROFILES = {
  ADMIN: {code: 'ADMIN', label: 'profile.ela'},
  COMPANY_ADMIN: {code: 'COMPANY_ADMIN', label: 'profile.company'},
  SCHOOL_ADMIN: {code: 'SCHOOL_ADMIN', label: 'profile.school'}
};

const countries = [
  {label: 'country.de', code: 'DE', tz: 'Europe/Berlin'},
  {label: 'country.be', code: 'BE', tz: 'Europe/Brussels'},
  {label: 'country.es', code: 'ES', tz: 'Europe/Madrid' },
  {label: 'country.it', code: 'IT', tz: 'Europe/Rome' },
  {label: 'country.fr', code: 'FR', tz: 'Europe/Paris'},
  {label: 'country.gp', code: 'GP', tz: 'America/Guadeloupe'},
  {label: 'country.gf', code: 'GF', tz: 'America/Cayenne'},
  {label: 'country.mq', code: 'MQ', tz: 'America/Martinique'},
  {label: 'country.yt', code: 'YT', tz: 'Indian/Mayotte'},
  {label: 'country.nc', code: 'NC', tz: 'Pacific/Noumea'},
  {label: 'country.re', code: 'RE', tz: 'Indian/Reunion'},
  {label: 'country.bl', code: 'BL', tz: 'America/St_Barthelemy'},
  {label: 'country.mf', code: 'MF', tz: 'America/Marigot'},
  {label: 'country.pm', code: 'PM', tz: 'America/Miquelon'},  
  {label: 'country.pf', code: 'PF', tz: 'Pacific/Tahiti'},
  {label: 'country.wf', code: 'WF', tz: 'Pacific/Wallis'},  
  {label: 'country.lu', code: 'LU', tz: 'Europe/Luxembourg'},
  {label: 'country.ch', code: 'CH', tz: 'Europe/Zurich'}
];

const eventType = {
  MTBE : {label: 'event.type.MTBE', code: 'MTBE'},
  MTB: {label: 'event.type.MTB', code: 'MTB'},
  GP: {label: 'event.type.GP', code: 'GP'},
  TPE: {label: 'event.type.TPE', code: 'TPE'}
};

const lang = {
  FR: {code: 'fr', label: 'lang.fr'},
  EN: {code: 'en', label: 'lang.en'},
  DE: {code: 'de', label: 'lang.de'},
  NL: {code: 'nl', label: 'lang.nl'},
  //LU: {code: 'lu', label: 'lang.lu')},
  IT: {code: 'it', label: 'lang.it'},
  ES: {code: 'es', label: 'lang.es'}
};

const getTypeLabel = (type: string) => {
  switch (type) {
    case eventType.GP.code: return eventType.GP.label;
    case eventType.MTB.code: return eventType.MTB.label;
    case eventType.MTBE.code: return eventType.MTBE.label;
    case eventType.TPE.code: return eventType.TPE.label;
    default: return '-';
  };
};

const getProfileLabel = (profile: string) => {
  switch (profile) {
    case PROFILES.ADMIN.code: return PROFILES.ADMIN.label;
    case PROFILES.SCHOOL_ADMIN.code: return PROFILES.SCHOOL_ADMIN.label;
    case PROFILES.COMPANY_ADMIN.code: return PROFILES.COMPANY_ADMIN.label;
    default: return '-';
  };
};

const getCountryLabel = (country: string) => {
  const found = countries.find((c)=> c.code === country);
  if (found) {
    return found.label;
  } else {
    return '-';
  }  
};

const MANUAL_QRCODE_MIN = 72000;
const MANUAL_QRCODE_MIN_LEN = 4;

const getCountryTimezone = (country: string) => {
  const found = countries.find((c)=> c.code === country);
  if (found) {
    return found.tz;
  } else {
    return 'Europe/Paris';
  }  
};

const DIR_LOGO = {
  company: "/company_logo/",
  event: "/event_logo/"
};

const DELIVERY = {
  NOW: "NOW",
  SCHEDULED: "SCHEDULED"
};

const SCHEDULE = {
  DATE: "DATE",
  START: "START",
  END: "END"
};

const NOTIF_STATUS = {
  SENT: {code: 'SENT', label: 'detail.notifs.status.sent'},
  TO_SEND: {code: 'TO_SEND', label: 'detail.notifs.status.tosend'}
};

const getNotifStatusLabel = (status: string) => {
  switch (status) {
    case NOTIF_STATUS.SENT.code: return NOTIF_STATUS.SENT.label;
    case NOTIF_STATUS.TO_SEND.code: return NOTIF_STATUS.TO_SEND.label;
    default: return '-';
  };
};

const FORMAT_DATE = "DD/MM/YYYY"
const FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm"

export { 
  PROFILES,
  countries,
  eventType,
  lang,
  getTypeLabel,
  getProfileLabel,
  getCountryLabel,
  getCountryTimezone,
  MANUAL_QRCODE_MIN,
  MANUAL_QRCODE_MIN_LEN,
  DIR_LOGO,
  DELIVERY,
  SCHEDULE,
  NOTIF_STATUS,
  getNotifStatusLabel,
  FORMAT_DATE,
  FORMAT_DATE_TIME,
};