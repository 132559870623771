import axios from 'axios';
import config from '../config/config';

class ParamsServices {
  
  getLabels = () => {
    return axios.get(config.backendURL + '/label-getLabels');
  };

  updateLabels = (labels: any) => {
    return axios.put(config.backendURL + '/label-updateHomePageLabels', labels);
  }
  
}

const paramsServices = new ParamsServices();
export default paramsServices;
