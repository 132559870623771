import React, {useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
  InputAdornment,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import './UpdateParameters.scss';
import CompanyServices from '../../../services/company.services';
import { useTranslation } from 'react-i18next';
import shareImg from '../../../assets/img/share.svg';

interface UpdateParametersModalProps {
  open: boolean;
  displayGaugeCompanies: boolean;
  toggle: () => void;
  index: number;
  limitAmount: number;
  link: string;
  hashtag1: string;
  hashtag2: string;
  hashtag3: string;
  event: any;
  company: any;
  refresh?: () => void;
}

export const UpdateParametersModal = (props: UpdateParametersModalProps) => {  
  const { t } = useTranslation();
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [index, setIndex] = useState(0.01);
  let [limitAmount, setLimitAmount] = useState(0);
  let [isLimited, setIsLimited] = useState<boolean>(false);
  let [displayGaugeCompanies, setDisplayGaugeCompanies] = useState<boolean>(true);
  let [link, setLink] = useState('');
  let [hashtag1, setHashtag1] = useState('');
  let [hashtag2, setHashtag2] = useState('');
  let [hashtag3, setHashtag3] = useState('');
  let params: any;

  const handleChange = (event: any) => {
    event.target.name === 'checkedGauge' ? setDisplayGaugeCompanies(!event.target.checked) : setIsLimited(event.target.checked)
  };

  const setParams = () => {    
    if (isLimited) {
      params = {
        index: index,
        limitAmount: limitAmount,
        displayGaugeCompanies: displayGaugeCompanies,
        link : link !== '' ? link : null,
        hashtag1 : hashtag1 !== '' ? hashtag1 : null,
        hashtag2 : hashtag2 !== '' ? hashtag2 : null,
        hashtag3 : hashtag3 !== '' ? hashtag3 : null
      };
    } else {
      params = {
        index: index,
        displayGaugeCompanies: displayGaugeCompanies,
        link : link !== '' ? link : null,
        hashtag1 : hashtag1 !== '' ? hashtag1 : null,
        hashtag2 : hashtag2 !== '' ? hashtag2 : null,
        hashtag3 : hashtag3 !== '' ? hashtag3 : null
      };
    }
  };

  useEffect(() => {
   setIndex(props.index ? props.index : 0.01);
   setLimitAmount(props.limitAmount ? props.limitAmount : 0);
   setIsLimited(props.limitAmount && props.limitAmount > 0 ? true : false);
   setDisplayGaugeCompanies(props.displayGaugeCompanies !== undefined ? props.displayGaugeCompanies : true);
   setLink(props.link !== undefined ? props.link : '');
   setHashtag1(props.hashtag1 !== undefined ? props.hashtag1 : '');
   setHashtag2(props.hashtag2 !== undefined ? props.hashtag2 : '');
   setHashtag3(props.hashtag3 !== undefined ? props.hashtag3 : '');
  }, [props]);

  const update = (event: any) => {
    setIsSubmitting(true);
    setParams();   
    CompanyServices.updateParametersForEvent(props.event, props.company, params)
        .then(() => {
          setIsSubmitting(false);
          props.toggle();
          props.refresh();
        })
        .catch((error) => {
          console.log(error);
    });
    event.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
      {t('events.update.title')}
      </DialogTitle>

      <form onSubmit={update} >        
        <DialogContent>
          <TextField
            value={index}
            inputProps={{min: 0, step: 0.001}}
            required
            variant="outlined"
            name="index"
            label={t('events.update.stepIndexLabel')}
            helperText={t('events.update.stepIndexExample')}
            type="number"
            onChange={(event) => setIndex(parseFloat(event.target.value as string))}
            fullWidth
            className="margin-bottom-20"
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={isLimited} onChange={handleChange} name="checkedA" />}
            label={t('events.update.checkLimit')}
          />
          <TextField
            value={limitAmount}
            inputProps={{min: 0, step: 100}}
            variant="outlined"
            name="limitAmount"
            label={t('events.update.limitLabel')}
            type="number"               
            onChange={(event) => setLimitAmount(parseFloat(event.target.value as string))}
            fullWidth
            disabled={!isLimited}
            className="margin-bottom-20"
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />

          <FormControlLabel
            control={<Checkbox checked={!displayGaugeCompanies} onChange={handleChange} name="checkedGauge" />}
            label={t('events.update.displayGauge')}
          />

          <div className="section-title margin-top-20 margin-bottom-20">
            <img src={shareImg} alt="Share"/>{t('events.update.share')}
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <TextField
              value={link}
              variant="outlined"
              name="link"
              label={t('events.update.link')}
              type="string"
              onChange={(event) => setLink(event.target.value as string)}
              fullWidth
              className="margin-bottom-20 margin-right-10"
            />
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <TextField
              value={hashtag1}              
              variant="outlined"
              name="hashtag1"
              label={t('events.update.hashtag1')}
              type="string"
              onChange={(event) => setHashtag1(event.target.value as string)}
              fullWidth
              className="margin-bottom-20 margin-right-10"
            />
            <TextField
              value={hashtag2}
              variant="outlined"
              name="hashtag2"
              label={t('events.update.hashtag2')}
              type="string"
              onChange={(event) => setHashtag2(event.target.value as string)}
              fullWidth
              className="margin-bottom-20 margin-right-10"
            />
            <TextField
              value={hashtag3}              
              variant="outlined"
              name="hashtag3"
              label={t('events.update.hashtag3')}
              type="string"
              onChange={(event) => setHashtag3(event.target.value as string)}
              fullWidth
              className="margin-bottom-20"
            />
          </div>          

          {isSubmitting && <LinearProgress/>}

        </DialogContent>

        <DialogActions>
          <Button onClick={props.toggle}>
          {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>       
      </form>
    </Dialog>
  );
}
