import React, {useState, useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress
} from '@mui/material';
import './UpdateCompanyInfos.scss';
import CompanyServices from '../../../../services/company.services';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';

interface UpdateCompanyNameProps {
  open: boolean;
  toggle: () => void;
}

export const UpdateCompanyName = (props: UpdateCompanyNameProps) => {  
  const { t } = useTranslation();
  const {companyId, companyName, managedCompanies, user, setCompanyName, setManagedCompanies} = useContext(AuthUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState(companyName);
  
  const update = (event: any) => {
    setIsSubmitting(true);
    CompanyServices.updateCompanyName({
      id: companyId,
      name: name
    }).then(() => {
          setIsSubmitting(false);
          props.toggle();
          setCompanyName(name);
          managedCompanies.map((item: any) => (
            item.id === companyId  ? item.name = name : item
          ));
          setManagedCompanies(managedCompanies);
        })
        .catch((error) => {
          console.log(error);
    });
    event.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {user.isSchoolAdmin ? t("orga.update.name.school.title") : t("orga.update.name.title")}
      </DialogTitle>

      <form onSubmit={update} >        
        <DialogContent>
          <TextField
            value={name}
            inputProps={{maxLength: 30}}
            helperText={t('orgas.form.characters.30')}
            required
            variant="outlined"
            name="contact"
            label={t('orgas.name')}
            type="text"
            onChange={(event) => setName(event.target.value as string)}
            fullWidth
            className="margin-bottom-20"
          />

          {isSubmitting && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggle}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>          
      </form>
    </Dialog>
  );
}
