import React, { useState } from 'react';

interface AuthenticatedUser {
  id: string;
  name: string;
  email: string;
  role: string;
  isAdmin: boolean;
  isCompanyAdmin: boolean;
  isSchoolAdmin: boolean
}

interface ContextProps {
  isAuthenticated: boolean;
  user: AuthenticatedUser;
  companyId: string;
  companyName: string;
  managedCompanies: ([]);
  setUser: (user: AuthenticatedUser, companyId: string, companyName: string, managedCompanies: []) => void;
  setCompanyId: (companyId: string) => void;
  setCompanyName: (companyName: string) => void;
  setManagedCompanies: (managedCompanies: []) => void;
};

interface InitialState {
  isAuthenticated: boolean;
  user: AuthenticatedUser;
  companyId: string;
  companyName: string;
  managedCompanies: ([]);
};

interface AuthUserContextProviderProps {
  children: React.ReactNode;
  initialState: InitialState;
};

const AuthUserContext = React.createContext<Partial<ContextProps>>({});

const AuthUserContextProvider = (props: AuthUserContextProviderProps) => {
  
  const [ state, setState ] = useState({
    isAuthenticated: props.initialState.isAuthenticated,
    user: props.initialState.user
  });

  const [companyId, setCompanyId] = useState(props.initialState.companyId);
  const [companyName, setCompanyName] = useState(props.initialState.companyName);
  const [managedCompanies, setManagedCompanies]= useState(props.initialState.managedCompanies);

  const defaultContext: ContextProps = {
    user: state.user,
    setUser: (user:AuthenticatedUser, companyId: string, companyName: string, managedCompanies: []) => {
      setState({
        ...state,
        user: user,
        isAuthenticated: true,
      });
      setCompanyId(companyId);
      setCompanyName(companyName);
      setManagedCompanies(managedCompanies);
    },
    setCompanyId,
    setCompanyName,
    setManagedCompanies,
    isAuthenticated: state.isAuthenticated,
    companyId: companyId,
    companyName: companyName,
    managedCompanies: managedCompanies,
  };

  return (
    <AuthUserContext.Provider value={defaultContext}>
      { props.children }
    </AuthUserContext.Provider>
  );
}
 
export {AuthUserContext, AuthUserContextProvider};
