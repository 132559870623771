import React, {useState, useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField, LinearProgress
} from '@mui/material';

import EventServices from '../../../services/event.services';
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';

export const MessageModal = (props: any) => {
  const { t } = useTranslation();
 
  const [notification, setNotification] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const publish = (event: any) => {
    setIsSubmitting(true);
    EventServices.publishMessage(props.event, props.company, {body: notification})
    .then((result: any) => {
      toast.success(t('events.pub.success'), { position: 'bottom-right' });
      props.toggle();
      setIsSubmitting(false);
    })
    .catch((error) => {
      toast.error(t('events.pub.error'), { position: 'bottom-right' });      
      setIsSubmitting(false);
      props.toggle();
    });    
    event.preventDefault();
  };

  useEffect(() => {
    setNotification('');
  }, [props]);

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {t('events.pub.title')}
      </DialogTitle>
        <form onSubmit={publish}>
          <DialogContent>
            <TextField
              value={notification}
              error={notification.length > 150}
              helperText={t('events.pub.characters.150')}
              required
              variant="outlined"
              id="body"
              label={t('events.pub.message')}
              type="text"
              multiline
              rows="2" 
              onChange={(event) => setNotification(event.target.value as string)}
              fullWidth
            />
            
            {isSubmitting && <LinearProgress/>}
          </DialogContent>
          
          <DialogActions>
            <Button onClick={props.toggle}>
              {t('button.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || notification.length > 150}
              disableElevation>
              {t('button.publish')}
            </Button>
          </DialogActions>
        </form>
    </Dialog>
  );
}
