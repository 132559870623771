import React, {useState, useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import './UpdateCompanyInfos.scss';
import CompanyServices from '../../../../services/company.services';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import { countries } from '../../../shared/Constantes';
import emailImg from '../../../../assets/img/mail.svg';
interface UpdateCompanyInfosProps {
  open: boolean;
  toggle: () => void;
  company: any;
  refresh?: () => void;
}

export const UpdateCompanyInfos = (props: UpdateCompanyInfosProps) => {  
  const { t } = useTranslation();
  const {companyId} = useContext(AuthUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState(props.company ? props.company.email : '');
  const [contact, setContact] = useState(props.company ? props.company.contact : '');
  const [country, setCountry] = useState(props.company ? props.company.country : '');
  const [phone, setPhone] = useState(props.company ? props.company.phone : '');
  const [cp, setCp] = useState(props.company ? props.company.cp : '');
  const [city, setCity] = useState(props.company ? props.company.city : '');
  
  const update = (event: any) => {
    setIsSubmitting(true);
    CompanyServices.updateCompanyInfos({
      id: companyId,
      mail: email,
      contact: contact,
      phone: phone,
      country: country,
      cp: cp,
      city: city
    }).then(() => {
          setIsSubmitting(false);
          props.toggle();
          props.refresh();
        })
        .catch((error) => {
          console.log(error);
    });
    event.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {t('orga.update.title')}
      </DialogTitle>

      <form onSubmit={update} >        
        <DialogContent>
          <TextField
            value={contact}
            inputProps={{maxLength: 50}}
            helperText={t('orgas.form.characters.50')}
            required
            variant="outlined"
            name="contact"
            label={t('orga.contact')}
            type="text"
            onChange={(event) => setContact(event.target.value as string)}
            fullWidth
            className="margin-bottom-20"
          />    

          <FormControl variant="outlined" fullWidth required className="margin-bottom-20">
            <InputLabel>
              {t('orga.country')}
            </InputLabel>
            <Select
              id="country"
              label={t('orga.country')}
              value={country}
              onChange={(event) => setCountry(event.target.value as string)}              
            >
              <MenuItem value="">
                <em>{t('events.country.none')} </em>
              </MenuItem>
              {countries.map(({ label, code }) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
            </Select>
          </FormControl>

          <TextField
            value={cp}
            inputProps={{maxLength: 20}}
            helperText={t('orgas.form.characters.20')}
            variant="outlined"
            name="cp"
            label={t('orga.cp')}
            type="text"
            onChange={(event) => setCp(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />

          <TextField
              value={city}
              inputProps={{maxLength: 32}}
              helperText={t('orgas.form.characters.32')}
              variant="outlined"
              name="city"
              label={t('orga.city')}
              type="text"
              onChange={(event) => setCity(event.target.value as string)}
              fullWidth
              className="margin-bottom-10"
            />

          <div className="section-title margin-bottom-10">
            <img src={emailImg} alt="Localisation" /> {t('orga.infos')}
          </div>

          <TextField
            value={email}
            inputProps={{maxLength: 50}}
            helperText={t('orgas.form.characters.50')}
            required
            variant="outlined"
            name="email"
            label={t('orga.email')}
            type="email"
            onChange={(event) => setEmail(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />

          <TextField
            value={phone}
            inputProps={{maxLength: 20}}
            helperText={t('orgas.form.characters.20')}
            required
            variant="outlined"
            name="phone"
            label={t('orga.phone.number')}
            type="text"
            onChange={(event) => setPhone(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />

          {isSubmitting && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggle}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>          
      </form>
    </Dialog>
  );
}
