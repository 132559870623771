import React, {useState, useEffect, useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import './UpdateCompanyInfos.scss';
import CompanyServices from '../../../../services/company.services';
import {AuthUserContext} from 'AuthUserContext';
import Upload from '@mui/icons-material/Publish';
import firebase from '../../../../config/firebase';
import { useTranslation } from 'react-i18next';
import { getStorage, ref, uploadBytes } from "firebase/storage";

interface UpdateCompanyLogoProps {
  open: boolean;
  toggle: () => void;
  refresh?: () => void;
}

export const UpdateCompanyLogo = (props: UpdateCompanyLogoProps) => {  
  const { t } = useTranslation();
  const {companyId} = useContext(AuthUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logo, setLogo] = useState(null);
  const [file, setFile] = useState<File>(null);
  const [importFileName, setImportFileName] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
   setLogo(null);
  }, [props]);

  const uploadDatas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogo(null);
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setImportFileName(file.name);
    reader.onload = (e) => {
      setLogo(e.target.result);
    };
  };

  const update = (event: any) => {
    setIsSubmitting(true);
    setError(null);
    const fileName = importFileName !== null ? importFileName.toLowerCase() : null;
    if (fileName !== null && 
        !fileName.endsWith('.png') && !fileName.endsWith('.jpg') && !fileName.endsWith('.jpeg') && !fileName.endsWith('.svg')) {
      setError(t('orga.update.logo.error'));
      setIsSubmitting(false);
    } else {
      const storage = getStorage(firebase);
      const storageRef = ref(storage, "/company_logo/"+importFileName);
      uploadBytes(storageRef, file).then(() => {
        CompanyServices.updateCompanyLogo(companyId, {logo: importFileName}).then(() =>{
          setIsSubmitting(false);
          props.toggle();
          props.refresh();
        }).catch(() => {
          setIsSubmitting(false);
        });        
      }).catch((err) => {
        console.log(err);
      })
    }    
    event.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {t('orga.update.logo.title')}
      </DialogTitle>

      <form onSubmit={update} >        
        <DialogContent>
          
          {logo && <img id="logoimg" alt="logo" src={logo} style={{ maxHeight: '50px' }} />}
          {error && (<div style={{color: '#f44336', textAlign: 'right'}}>{error}</div>)}         
          {isSubmitting && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
        
          <Button onClick={props.toggle}>
          {t('button.cancel')}
          </Button>
          <label htmlFor="data">
            <input
              accept=".png,.jpg,.jpeg,.svg"
              style={{ display: 'none' }}
              name="data"
              id="data"
              onChange={(event) => uploadDatas(event)}
              type="file"
            />
            <Button
              className="action-link"
              color="primary"
              component="span"
              variant="contained"
              endIcon={<Upload />}
            >
              {t('button.import')}
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>          
      </form>
    </Dialog>
  );
}
