import React, {useEffect, useState, useCallback} from 'react';
import {
  Button,
  CardContent,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import Card from '@mui/material/Card';
import "./Detail.scss"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import {DetailTable} from './DetailTable';
import {useNavigate, useParams} from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Notifications from '@mui/icons-material/NotificationsOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import ComputeIcon from '@mui/icons-material/Update';
import {useDialog} from '../../shared/tools/CustomHooks';
import {AddEventModal} from '../../shared/add-event/add-event';
import {ConfirmDialog} from '../../shared/ConfirmDialog/ConfirmDialog';
import EventServices from '../../../services/event.services';
import CompanyServices from "../../../services/company.services";
import {toast} from "react-toastify";
import { NotificationModal } from './NotificationDialog';
import { NotificationsListModal } from './NotificationsListDialog';
import { useTranslation } from 'react-i18next';
import { getTypeLabel,
  getCountryLabel,
  MANUAL_QRCODE_MIN_LEN,
  MANUAL_QRCODE_MIN,
  getCountryTimezone,
  DIR_LOGO,
  FORMAT_DATE
} from 'components/shared/Constantes';
import { UpdateEventLogo } from '../dashboard-company/UpdateEventLogo';
import firebase from '../../../config/firebase';
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { UpdateEventDatesModal } from 'components/shared/add-event/update-dates-event';
import listImg from '../../../assets/img/list.svg';
import inProgressImg from '../../../assets/img/inprogress-event.svg';

dayjs.extend(utc)
dayjs.extend(timezone)

export const Detail = (props: any) => {

  const { t } = useTranslation();
  const navigate = useNavigate ();
  const [open, toggle] = useDialog(false);
  const [openSup, toggleSup] = useDialog(false);
  const [openNotif, toggleNotif] = useDialog(false);
  const [openNotifList, toggleNotifList] = useDialog(false);
  const [openDates, toggleDates] = useDialog(false);
  const [event, setEvent] = useState(null);
  const { eventId } = useParams<any>();
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAutomaticCode, setIsAutomaticCode] = useState(true);
  const [manualCode, setManualCode] = useState('');
  const [errorManualCode, setErrorManualCode] = useState(null);
  const [openLogo, toggleLogo] = useDialog(false);
  const [url, setUrl] = useState('');
  const [selectedNotif, setSelectedNotif] = useState(null);
  const [openSupNotif, toggleSupNotif] = useDialog(false);

  const handleChange = (event: any) => {
    setIsAutomaticCode(event.target.checked);
    setManualCode('');
    setErrorManualCode(null);
  };

  const updateNotif = (item: any) => {
    setSelectedNotif(item);
    toggleNotifList();
    toggleNotif();
  };

  const resetNotif = () => {
    setSelectedNotif(null);
    toggleNotifList();
    toggleNotif();
  };

  const newNotif = () => {
    setSelectedNotif(null);
    toggleNotifList();
    toggleNotif();
  };

  const deleteNotif = (item: any) => {
    setSelectedNotif(item);
    toggleNotifList();
    toggleSupNotif();
  };

  const handleCloseSupNotif = () => {
    toggleSupNotif();
    setSelectedNotif(null);
    toggleNotifList();
  };

  const handleDeleteNotif = () => {
    if (selectedNotif !== null) {
      EventServices.deleteNotification(eventId, selectedNotif.id).then(() => {
        toggleSupNotif();
        setSelectedNotif(null);      
        toggleNotifList();
      })
      .catch((error) => toast.error(error.message, { position: 'bottom-right', autoClose: false }));      
    }
  }

  const getUrl = (logo: string) => {
    const storage = getStorage(firebase);
    const storageRef = ref(storage, DIR_LOGO.event + logo);
    getDownloadURL(storageRef).then((url) => {
      setUrl(url);
    });
  };
  
  const loadEvent = useCallback(() => {
    if (eventId) {
      setIsLoading(true);
      EventServices.getEvent(eventId).then((result: any) => {
        const challenge = result.data;
        setEvent(challenge);
        CompanyServices.getCompaniesSummary()
        .then((result) => {
          if (challenge !== undefined && challenge !== null) {
            const list = [{label: '', value: ''}];
            const all = result.data;
            const compEvent = challenge.companies.map((comp: any) => {return comp.id});
            all.forEach((comp: any) => {
              if (!compEvent.includes(comp.id)) {
                list.push({label: comp.name, value: comp.id});
              }
            });
            if (challenge.logo !== undefined && challenge.logo !== null) {
              getUrl(challenge.logo);
            } else {
              getUrl('ELA.png');
            }
            setCompanies(list);
            setIsLoading(false);
          }
        }).catch((err) => {
          console.log(err);
          setIsLoading(false);
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
        });        
      });
    }
  },[eventId, t]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  useEffect(() => {
    setDisabled(!(selectedCompany && selectedCompany !== '' && isAutomaticCode));
  }, [selectedCompany, isAutomaticCode]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = () => {
    toggleSup();
  };

  const inviteCompanies = () => {
    if(selectedCompany && (isAutomaticCode || manualCode.length > MANUAL_QRCODE_MIN_LEN)) {
      setDisabled(true);
      setIsLoading(true);
      if(isAutomaticCode) {
        EventServices.inviteCompany(eventId, selectedCompany)
        .then((result) => {
          setManualCode('');
          setSelectedCompany(null);
          loadEvent();
          toast.success(t('detail.register.success', { position: 'bottom-right', code: result.data.code }));
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.message, { position: 'bottom-right', autoClose: false });
        });
      } else {
        EventServices.inviteCompanyManualCode(eventId, selectedCompany, manualCode)
        .then((result) => {
          setManualCode('');
          setSelectedCompany(null);
          loadEvent();
          toast.success(t('detail.register.success', { position: 'bottom-right', code: manualCode }));
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 400) {
            setErrorManualCode(t('detail.code.error', {code: manualCode, min: MANUAL_QRCODE_MIN}));
            toast.error(t('detail.code.error.alreadyUsed', { position: 'bottom-right', code: manualCode }));
          } else {
            toast.error(error.message, { position: 'bottom-right', autoClose: false });
          }
        });
      }        
    } 
  };

  const handleDelete = () => {
    if (eventId !== undefined && eventId !== null) {
      EventServices.deleteEvent(eventId)
      .then(() => {
        toggleSup();
        navigate('/events');
      })
      .catch((error) => toast.error(error.message, { position: 'bottom-right', autoClose: false }));
    }    
  };

  const validateCodeChallenge = (qrcode: string) => {    
    if (qrcode.length > MANUAL_QRCODE_MIN_LEN && Number(qrcode) >= MANUAL_QRCODE_MIN) {
      setDisabled(false);
      setErrorManualCode('');
    } else {
      setErrorManualCode(t('detail.code.error', {code: qrcode, min: MANUAL_QRCODE_MIN}));
      setDisabled(true);
    }   
  };

  const computeRanking = () => {
    if (eventId !== undefined && eventId !== null) {
      setIsLoading(true);
      EventServices.computeRanking(eventId).then(() => {
        setIsLoading(false);
        toast.success(t('detail.compute.ranking.success'), { position: 'bottom-right' });
      }).catch((error) => toast.error(error.message, { position: 'bottom-right', autoClose: false }));
    }
  };

  return (
    <div className="content">
      <div className="action-row">
        <div className="link-button">
          <Button
            className="action-link"
            color="primary"
            size="small"
            startIcon={<ArrowBackIosIcon />}
            onClick={handleBack}
          >
            {t('detail.back')}
          </Button>
        </div>
        {event && event.isEditable && 
          <div>          
              <Button
                className="action-link"
                color="primary"
                size="small"
                endIcon={<DeleteOutlineOutlinedIcon />}
                onClick={handleClose}
              >
                {t('detail.delete')}
              </Button>
              <ConfirmDialog
                open={openSup}
                handleClose={handleClose}
                handleAction={handleDelete}
                title={t('dialog.title.delete')}
                message={event ? t('detail.message.delete', {event: event.name}) : ''}
                confirmLabel={t('button.validate')}
              />
          </div>
        }
      </div>

      <div style={{marginBottom: '10px', display: 'block'}}>
        {isLoading && <LinearProgress/>}
      </div>

      {event && (
      <Card className="event-panel">

        <div className="event-title">
          <div>
            {event.name}
          </div>
        </div>

        <CardContent>
          <div className="infos-panel left">
            <div className="section">
              <div className="section-title margin-bottom-20">
                <img src={listImg} alt="List"/>{t('detail.list.orgas')}
              </div>
            </div>
            
            <div className={event.isEditable ? 'border' : ''}>
              {event.isEditable && 
              <>
                <div style={{fontSize: 'smaller'}} className="pt-1">{t('detail.helper')}</div>
                <div style={{display: 'flex', flexDirection: 'row'}} className="margin-bottom-15 p-1">
                  <div style={{width: '85%'}}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>
                        {t('detail.placeholder')}
                      </InputLabel>
                      <Select
                          id="selectedCompany"
                          label={t('detail.placeholder')}
                          value={selectedCompany}
                          onChange={(event) => setSelectedCompany(event.target.value as string)}              
                      >
                          {companies.map((comp: any) => <MenuItem key={comp.value} value={comp.value}>{comp.label}</MenuItem>)}
                      </Select>
                    </FormControl>                    
                  </div>
                  <div style={{width: '15%', textAlign: 'right'}} className='center-vertical'>
                    <Button
                      variant="contained"
                      disabled={disabled}
                      color="primary"
                      size="small"
                      onClick={() => inviteCompanies()}
                    >
                      {t('detail.button.invite')}
                    </Button>
                  </div>              
                </div>

                <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row', marginBottom: 15, height: 50}} className="pr-l-1">                 

                    <FormControlLabel
                      control={<Checkbox checked={isAutomaticCode} onChange={handleChange} name="checkedCodeAuto" />}
                      label={t('detail.code.auto')}
                    />
                    {!isAutomaticCode && 
                    <TextField
                      name="challengeCode"                      
                      inputProps={{min: MANUAL_QRCODE_MIN}}
                      label={t('detail.qrcode')}
                      autoComplete="off"
                      disabled={isAutomaticCode}
                      type="number"
                      style={{width: "25%"}}
                      variant="outlined"
                      value={manualCode}
                      onChange={(event) => {
                        setManualCode(event.target.value);
                        validateCodeChallenge(event.target.value);
                      }}
                    />
                  }
                </div>
                {errorManualCode && <span className="error">{errorManualCode}</span>}
                
              </>
              }
            </div>

            {event && 
              <DetailTable
                companies={event.companies}
                event={{id: event.id, isEditable: event.isEditable, isGuideEnabled: event.isGuideEnabled, location: event.location}}
                load={() => loadEvent()} />}
          </div>

          <div className="infos-panel right">
            <div className="edit">
              <Button
                className="action-link"
                color="primary"
                size="small"
                onClick={toggleLogo}
                endIcon={<EditOutlinedIcon />}
              >
                {t('button.update')}
              </Button>
              {openLogo &&
                <UpdateEventLogo
                  eventId={event.id}
                  open={true}
                  toggle={toggleLogo}
                  refresh={() => loadEvent()}
                />
              }
              
            </div>

            <div className="logo">
              {url && <img src={url} alt="" />}
            </div>

            {event && !event.logo &&
              <div className="margin-20">
                <span>{t('orga.no.logo')}</span>
                <span>{t('orga.logo.ela')}</span>
              </div>
            }

            {event.isEditable && 
              <div className="edit">
                <Button
                  className="action-link"
                  color="primary"
                  size="small"
                  onClick={toggle}
                  endIcon={<EditOutlinedIcon />}
                >
                  {t('button.update')}
                </Button>
                { open && (
                <AddEventModal
                  open={true}
                  toggle={toggle}
                  event={event}
                  refresh={() => loadEvent()}
                />)}
              </div>
            }

            {!event.isEditable && 
              <div className="edit">
                <Button
                  className="action-link"
                  color="primary"
                  size="small"
                  onClick={toggleDates}
                  endIcon={<EditOutlinedIcon />}
                >
                  {t('button.update')}
                </Button>
                { openDates && (
                <UpdateEventDatesModal
                  open={true}
                  toggle={toggleDates}
                  event={event}
                  refresh={() => loadEvent()}
                />)}
              </div>
            }

            <div className="infos">

              <div className="section margin-bottom-30">

                <div className="section-title">
                  <img src={inProgressImg} alt="In progress"/>{t('detail.infos')}
                </div>

                <div className="section-content padding-left-35">
                  <div className="margin-bottom-10">
                    {t('detail.date')} : <strong>{dayjs(event.startDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE) === dayjs(event.endDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE) || event.endDate === undefined || event.endDate === null ? 
                    dayjs(event.startDate).format(FORMAT_DATE) : 
                    t('detail.from.to', {start: dayjs(event.startDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE), end: dayjs(event.endDate).tz(getCountryTimezone(event.location)).format(FORMAT_DATE)})
                    }</strong>
                  </div>
                  <div className="margin-bottom-10">
                    {t('detail.hours')} : <strong>{dayjs(event.startDate).tz(getCountryTimezone(event.location)).format("HH:mm")} - {dayjs(event.endDate).tz(getCountryTimezone(event.location)).format("HH:mm")}</strong>
                  </div>
                  <div className="margin-bottom-10">
                    {t('detail.country')} : <strong>{t(getCountryLabel(event.location))}</strong>
                  </div>
                  <div className="margin-bottom-10">
                    {t('detail.type')} : <strong>{t(getTypeLabel(event.type))}</strong>
                  </div>
                  <div className="margin-bottom-10">
                    {t('events.promise.enabled')} : <strong>{event.isPromiseEnabled ? t('events.promise.enabled.yes') : t('events.promise.enabled.no')}</strong>
                  </div>
                </div>

                {event.description && event.description !== null && 
                  <>
                    <div className="section-title">
                      <img src={inProgressImg} alt="In progress"/>{t('detail.description')}
                    </div>

                    <div className="section-content padding-left-35">
                      {event.description}
                    </div>
                  </>
                }
                
              </div>

              {event.companies.length > 0 && 
                <div className="send-invitation">
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    onClick={toggleNotif}
                    endIcon={<SendOutlined />}
                  >
                    {t('detail.send.notification')}
                  </Button>
                  
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    onClick={toggleNotifList}
                    endIcon={<Notifications />}
                  >
                    {t('detail.notifications')}
                  </Button>

                  {openNotif && 
                    <NotificationModal
                      open={true}                      
                      toggle={toggleNotif}
                      event={event.id}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      country={event.location}
                      notif={selectedNotif}
                      resetNotif={resetNotif}
                      setSelectedNotif={setSelectedNotif}
                    />
                  }

                  {openNotifList && 
                    <NotificationsListModal
                      open={true}
                      openNotif={openNotif}
                      toggle={toggleNotifList}
                      event={event.id}
                      startDate={event.startDate}
                      endDate={event.endDate}
                      country={event.location}
                      updateNotif={updateNotif}
                      newNotif={newNotif}
                      deleteNotif={deleteNotif}
                    />
                  }

                  {openSupNotif &&
                    <ConfirmDialog
                        open={true}
                        handleClose={handleCloseSupNotif}
                        handleAction={handleDeleteNotif}
                        title={t('detail.notif.deletion.title')}
                        message={selectedNotif ? t('detail.notif.deletion.content', { notif: selectedNotif.title }) : ''}
                        confirmLabel={t('button.validate')}
                    />
                  }

                  {event.isEditable && dayjs().isAfter(dayjs(event.endDate)) &&
                    <Button
                      className="action-link"
                      color="primary"
                      size="small"
                      onClick={() => computeRanking()}
                      endIcon={<ComputeIcon />}
                    >
                      {t('detail.compute.ranking')}
                    </Button>
                  }                  
                  
                </div>
              }

            </div>
          </div>

        </CardContent>
      </Card>
      )}
    </div>
  );
}
