import React, {useState, useEffect, useCallback} from "react";
import {Card, Typography} from '@mui/material';
import "./login.scss"
import {
  TextField, 
  LinearProgress,
  Button
} from '@mui/material';
import firebase from '../../../config/firebase';
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from "firebase/auth";
import { useNavigate  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import EventServices from '../../../services/event.services';
import {toast} from "react-toastify";
import { eventType } from "components/shared/Constantes";
import {download} from '../../shared/tools/Utils';
import logoImg from '../../../assets/img/logo.svg';

export const InitAccount = (props: any) => {

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate ();
  
  const params = window.location.hash.substring(1).split('&').map((v) => v.split('=')).reduce((acc: any, [paramName, paramValue]) => {
    acc[paramName] = paramValue;
    return acc;
  }, {});

  const initPassword = () => {
      const auth = getAuth(firebase);
      confirmPasswordReset(auth, params.oobCode, password).then((resp) => {
        navigate("/");
      }).catch((error) => {
       setError(error);
      });   
  };

  const verifyLink = useCallback(() => {
    const auth = getAuth(firebase);
    verifyPasswordResetCode(auth, params.oobCode)
    .then(() => {
      setIsDisabled(false);
    })
    .catch(() => {
      setIsDisabled(true);
    });
  },[params]);

  useEffect(() => {
    verifyLink();
  }, [verifyLink]);  

  const init = (event: any) => {
    if (password !== confirm) {
      setError(t('init.error.different'));
    } else {
      setIsLoading(true);
      setError('');
      initPassword();
    }
    event.preventDefault(); 
  };

  const downloadUserGuide = (type: any) => {
    setIsEnabled(false);
    EventServices.downloadGuideByType(type).then((response) => {
      download(
        response.data,
        response.headers["content-disposition"].split("filename=")[1],
        'application/pdf'
      );
      setIsEnabled(true);
    }).catch(() => {
      setIsEnabled(true);
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
    });
  };

  return (
    <div className="fixed-center">
      <Card className="login-card">
        <div className="panel-left">
          <img src={logoImg} alt=""/>
        </div>        
        <div className="panel-right">

          {isDisabled && (
          <>
          <div style={{marginBottom: 15}}>{t('init.error.link')}</div> 
          <div><a href="/#/resetPassword" className="text-small text-right color-primary">{t('init.reinit.password')}</a></div>
          </>)
          }

          {!isDisabled && (<>
            <div className="title">
              <span className="color-primary">{t('init.title')}</span> <br/>
              <span className="text-small">{t('init.sub.title')}</span>
            </div>
          
            <form className="login" onSubmit={(e) => init(e)}>
              <div className="inputs">
                
                <TextField
                  id="login-password"
                  name="password"
                  helperText={t('init.characters.8')}
                  inputProps={{minLength: 8}}
                  label={t('init.password')}
                  autoComplete="off"
                  variant="outlined"
                  type="password"
                  className="margin-bottom-20"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <TextField
                  id="login-email"
                  name="confirm"
                  helperText={t('init.characters.8')}
                  inputProps={{minLength: 8}}
                  label={t('init.confirmation')}
                  variant="outlined"
                  className="margin-bottom-20"
                  type="password"
                  autoComplete="off"
                  onChange={e => setConfirm(e.target.value)}
                  required
                />
              </div>

              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                <span className="text-small text-right">{t('init.help')}</span>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    endIcon={<DownloadIcon />}
                    onClick={() => downloadUserGuide(eventType.MTB.code)}
                    disabled={!isEnabled}
                  >
                    {t('init.help.mtb')}
                  </Button>
                  <Button
                    className="action-link"
                    color="primary"
                    size="small"
                    endIcon={<DownloadIcon />}
                    onClick={() => downloadUserGuide(eventType.MTBE.code)}
                    disabled={!isEnabled}
                  >
                    {t('init.help.mtbe')}
                  </Button>
                  
                </div>
              </div>

              <div style={{minHeight: 30}}>
                <Typography className="error">{error}</Typography>
              </div>
              
              <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={isLoading}
              >
                {t('init.button.init')}
              </Button>
              {isLoading && <LinearProgress/>}           
            </form>
          </>)}
        </div>
              
      </Card>
    </div>
  );
}
