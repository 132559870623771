import React, {useState, useContext} from 'react';
import {
  CardContent,
  LinearProgress,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  Card
} from '@mui/material';
import "./MyAccount.scss"
import {AuthUserContext} from 'AuthUserContext';
import { lang, getProfileLabel } from 'components/shared/Constantes';
import firebase from '../../../config/firebase';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import emailImg from '../../../assets/img/mail.svg';
import accountImg from '../../../assets/img/person.svg';
import companyImg from '../../../assets/img/company.svg';

export const MyAccount = () => {

  const { t } = useTranslation();

  const {user, companyId, companyName, setCompanyId, setCompanyName, managedCompanies} = useContext(AuthUserContext);
 
  const [isLoadingPwd, setIsLoadingPwd] = useState(false); 

  const [old, setOld] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState('');

  const [language, setLanguage] = useState(i18next.language);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
    setLanguage(lng);
  };

  const changePassword = (event: any) => {
    const auth = getAuth(firebase);
    const authUser = auth.currentUser;
    const credentials = EmailAuthProvider.credential(authUser.email, old);
    reauthenticateWithCredential(authUser, credentials).then(() => {
      updatePassword(authUser, password).then(() => {
        setPassword('');
        setConfirm('');
        setOld('');
        setIsLoadingPwd(false);
        toast.success(t('account.change.success'), { position: 'bottom-right' });
      }).catch(() => {
        setIsLoadingPwd(false);
        toast.error(t('account.change.error'), { position: 'bottom-right',autoClose: false });
      });
    }).catch(() => {
      setIsLoadingPwd(false);
      toast.error(t('account.change.bad.pwd'), { position: 'bottom-right', autoClose: false });
    });
    event.preventDefault(); 
  };

  const change = (event: any) => {
    if (password !== confirm) {
      setError(t('init.error.different'));
    } else {
      setIsLoadingPwd(true);
      setError('');
      changePassword(event);
    }
    event.preventDefault(); 
  };  

  const handleCompanyChange = (newValue: string) => {
    // set company in app context
    const id = newValue;
    setCompanyId(id);
    const comp = managedCompanies.filter((item: any) => item.id === id) as any;
    setCompanyName(comp[0].name);
  };

  return (
    <div className="content">
     
      {user && (
      <Card className="account-panel">
        <div className="account-title">
          <div>
            {user.name}
          </div>
        </div>
        <CardContent>
          <div className="infos-panel left">              
            <div className="section-content">             
              <div className="section-title">
                <img src={emailImg} alt="Localisation"/> {t('account.email')} : {user.email}
              </div>
              <div className="section-title">
                <img src={accountImg} alt="Localisation"/> {t('account.role')} : {t(getProfileLabel(user.role))}
              </div>
              {companyName && managedCompanies &&
                <>
                {(managedCompanies.length < 2) &&
                  <div className="section-title">
                    <img src={companyImg} alt="Company" style={{maxHeight: 17}}/> {t('account.organisation')} : {companyName}
                  </div>
                }
                {managedCompanies.length > 1 && (

                  <div >
                    <div className="section-title">
                    <img src={companyImg} alt="Company" style={{maxHeight: 17}}/> {t('account.organisations')} : &nbsp;
                      <Select
                        id="event"
                        value={companyId}
                        onChange={(e) => handleCompanyChange(e.target.value)}
                        variant="outlined"
                        style={{width: 300}}
                      >
                        {managedCompanies.map((item: any) => 
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )}
                      </Select>
                      </div>
                      <div style={{fontSize: 'smaller', marginTop: 30}}>{t('account.helper')}</div>
                  </div>
                )}
                </>
              }
            </div>
          </div>

          <div className="infos-panel right">              
            <div className="section-content">
              <div className="section-title">
                <span className="color-primary">{t('account.change.pwd.title')}</span>
              </div>

            <form className="login" onSubmit={(e) => change(e)}>
              <div className="inputs">                
                <TextField
                  name="old"
                  label={t('account.change.pwd.old')}
                  autoComplete="off"
                  variant="outlined"
                  type="password"
                  value={old}
                  className="margin-bottom-10"
                  onChange={e => setOld(e.target.value)}
                  required
                />
                <TextField
                  name="password"
                  helperText={t('init.characters.8')}
                  inputProps={{minLength: 8}}
                  value={password}
                  label={t('account.change.pwd.new')}
                  autoComplete="off"
                  variant="outlined"
                  type="password"
                  className="margin-bottom-10"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <TextField
                  name="confirm"
                  helperText={t('init.characters.8')}
                  inputProps={{minLength: 8}}
                  value={confirm}
                  label={t('account.change.pwd.confirmation')}
                  variant="outlined"
                  type="password"
                  autoComplete="off"
                  onChange={e => setConfirm(e.target.value)}
                  required
                />
              </div>

              <div style={{minHeight: 25}}>
                <Typography className="error">{error}</Typography>
              </div>
              
              <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={isLoadingPwd}
              >
                {t('button.validate')}
              </Button>
              {isLoadingPwd && <LinearProgress/>}           
            </form>
            </div>
            
            <div className="section-content" style={{ marginTop: 30 }}>
            <div className="section-title">
                <span className="color-primary">{t('account.change.language.title')}</span>
              </div>
              <div style={{display: 'flex', flexDirection:'column'}}>
              {Object.values(lang).map((lg) => 
                <FormControlLabel
                  control={<Radio 
                  checked={language === lg.code}                  
                  onChange={(e) => changeLanguage(lg.code)}
                  color="primary"/>}
                  label={t('lang.'+lg.code)}
                  key={lg.code}
                />
              )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      )}
    </div>
  );
};
