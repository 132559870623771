import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
  MenuItem,
  Select,
  InputLabel,
  Typography
} from '@mui/material';
import './RunnerForm.scss';
import RunnerServices from "../../../services/runners.services";
import { AuthUserContext } from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

interface RunnerFormProps {
  open: boolean;
  toggle: () => void;
  event: string;
  runner?: {
    id?: string,
    pseudo: string,
    nbSteps: number,
    subsidiary: any,
    service: any,
  };
  levels: any[];
  refresh: () => void;
  reset: () => void;
}

export const RunnerForm = (props: RunnerFormProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableDepartment, setAvailableDepartment] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [pseudo, setPseudo] = useState(props.runner ? props.runner.pseudo : '');
  const [department, setDepartment] = useState(props.runner ? props.runner.subsidiary.id : '');
  const [service, setService] = useState(props.runner ? props.runner.service.id : '');
  const [availableService, setAvailableService] = useState([]);
  const [nbSteps, setNbSteps] = useState(props.runner ? props.runner.nbSteps : 0);
  const { companyId, companyName } = useContext(AuthUserContext);

  useEffect(() => {
    setPseudo(props.runner ? props.runner.pseudo : '');
    setNbSteps(props.runner ? props.runner.nbSteps : 0);
    setService(props.runner ? props.runner.service.id : props.levels[0] ? props.levels[0].services[0].id : null);
    let defaultDep = props.runner ? props.runner.subsidiary.id : props.levels[0] ? props.levels[0].id : null;  
    const avDep = props.levels.filter((x: any) => x.name !== companyName);
    setAvailableDepartment(avDep)
    defaultDep = props.runner ? props.runner.subsidiary.id : avDep[0] ? avDep[0].id : props.levels[0]?props.levels[0].id:null;
    setDepartment(defaultDep);
    if (defaultDep) {
      const dep = props.levels.find((item) => item.id === defaultDep)
      const avServ = dep.services.filter((x: any) => x.name !== dep.name).sort((a: any, b: any) => a.name.localeCompare(b.name))
      setAvailableService(avServ);
      setService(props.runner ? props.runner.service.id : avServ && avServ.length !== 0 ? avServ[0].id : props.levels[0]  ?props.levels[0].services[0].id:null);
    }
  }, [props, companyName]);

  const insertOrUpdate = (event: any) => {
    setIsSubmitting(true);
    setErrorMessage('');
    if (props.runner === null) {
      RunnerServices.addRunner(
        props.event, companyId, department, service,
        { pseudo: pseudo, nbSteps: nbSteps }
      ).then(() => {
        setIsSubmitting(false);
        props.refresh();
        props.reset();
        props.toggle();
      }).catch((err) => {
        if (err.response.data.statusCode === 409) {
          setPseudo(err.response.data.detail.newPseudo);
          setErrorMessage(t('participant.pseudo.already.exists'));
        } else {
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
        }
        setIsSubmitting(false);
      });
    } else {
      RunnerServices.updateRunner(
        props.event, companyId, department, service, props.runner.id,
        { pseudo: pseudo, nbSteps: nbSteps }
      ).then(() => {
        setIsSubmitting(false);
        props.refresh();
        props.reset();
        props.toggle();
      }).catch((err) => {
        if (err.response.data.statusCode === 409) {
          setPseudo(err.response.data.detail.newPseudo);
          setErrorMessage(t('participant.pseudo.already.exists'));
        } else {
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
        }
        setIsSubmitting(false);
      });
    }
    event.preventDefault();
  };


  const handleDepartmentChange = (value: string) => {
    // set department in app context
    setDepartment(value);
    const dep = props.levels.find((item) => item.id === value)
    setAvailableService(dep.services.filter((x: any) => x.name !== dep.name).sort((a: any, b: any) => a.name.localeCompare(b.name)))
    setService(dep.services[0].id);
  };

  const handleServiceChange = (value: string) => {
    // set department in app context
    setService(value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {props.runner ? t('participant.update.modify') : t('participant.update.add')}
      </DialogTitle>

      <form onSubmit={insertOrUpdate} >
        <DialogContent>
          <TextField
            value={pseudo}
            required
            variant="outlined"
            name="pseudo"
            label={t('participant.update.pseudo')}
            type="text"
            onChange={(event) => setPseudo(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />
          <TextField
            value={nbSteps}
            inputProps={{min: 0, step: 1}}
            variant="outlined"
            name="nbSteps"
            label={t('participant.update.stepNumber')}
            type="number"
            onChange={(event) => setNbSteps(parseInt(event.target.value as string))}
            fullWidth
            className="margin-bottom-10"
          />
          <div style={{height: 75}}>
          {availableDepartment && availableDepartment.length > 0 &&
            <div style={{ display: "inline-block" }}>
              <InputLabel shrink id="departmentLabel">
                {t('participant.update.department')}
              </InputLabel>
              <Select
                id="department"
                value={department}
                required
                onChange={(e) => handleDepartmentChange(e.target.value)}
                variant="outlined"
                labelId='departmentLabel'
                style={{ width: 300 }}
              >
                {availableDepartment.map((item: any) =>
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                )}
              </Select>
            </div>
          }
          {availableDepartment && availableService && availableService.length > 0 &&
            <div style={{ display: "inline-block", marginLeft: "20px" }}>
              <InputLabel shrink id="departmentLabel">
                {t('participant.update.service')}
              </InputLabel>

              <Select
                id="service"
                value={service}
                required
                onChange={(e) => handleServiceChange(e.target.value)}
                variant="outlined"
                label='test'
                style={{ width: 300 }}
              >
                {availableService.map((item: any) =>
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                )}
              </Select>
            </div>
          }
          </div>
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <div style={{minHeight: 25}}>
            <Typography className="error">{errorMessage}</Typography>
          </div>
          <Button onClick={() => {
            props.toggle();
            props.reset();
          }}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            type="submit"
            disableElevation>
            {t('button.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
