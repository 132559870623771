import React from 'react';
import ReactDOM from 'react-dom';
import firebase from './config/firebase';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import './i18n'
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { AuthUserContextProvider } from 'AuthUserContext';
import { PROFILES } from 'components/shared/Constantes';
import { withTranslation } from 'react-i18next';
import usersServices from 'services/users.services';

const authentication: Promise<User> = new Promise((resolve) => {
  const auth = getAuth(firebase);
  onAuthStateChanged(auth, resolve);
});

const login = (user: User) => {
  if (user) {
    return usersServices.getUser(user.uid).then((res) => {
      return {
        id: user.uid,
        name: user.displayName,
        email: user.email,
        role: res.data.role,
        companyId: (res.data.role === PROFILES.COMPANY_ADMIN.code || res.data.role === PROFILES.SCHOOL_ADMIN.code) ? res.data.companies[0].id : '',
        companyName: (res.data.role === PROFILES.COMPANY_ADMIN.code || res.data.role === PROFILES.SCHOOL_ADMIN.code) ? res.data.companies[0].name : '',
        managedCompanies: res.data.companies,
        isSchoolAdmin: res.data.role === PROFILES.SCHOOL_ADMIN.code,
        isAdmin: res.data.role === PROFILES.ADMIN.code,
        isCompanyAdmin: res.data.role === PROFILES.COMPANY_ADMIN.code         
      };
    })
  }
  return Promise.resolve(null);
}

const Main = withTranslation()(() => (  
    <App /> 
));

authentication
  .then((authUser) => login(authUser))
  .then((user) => {
    const initialState: any = {
      user: user === null ? null :{
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        isSchoolAdmin: user.isSchoolAdmin,
        isAdmin: user.isAdmin,
        isCompanyAdmin: user.isCompanyAdmin
      },
      isAuthenticated: user !== null,
      companyId: user !== null ? user.companyId : '',
      companyName: user !== null ? user.companyName : '',
      managedCompanies: user !== null ? user.managedCompanies : null,
    };
    
    ReactDOM.render(
      <AuthUserContextProvider initialState={initialState}>
        <Main />
      </AuthUserContextProvider>
      , document.getElementById('root')
    );
  });

serviceWorker.unregister();
