import React, {useState, useContext} from "react";
import {Card, Typography} from '@mui/material';
import "./login.scss"
import {
  TextField, 
  LinearProgress,
  Button
} from '@mui/material';
import firebase from '../../../config/firebase';
import { browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import {PROFILES} from '../../shared/Constantes';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import usersServices from "services/users.services";
import logoImg from '../../../assets/img/logo.svg';

export const Login = (props: any) => {

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const {setUser} = useContext(AuthUserContext);

  const signIn = (event: any) => {
    setIsLoading(true);
    setError('');
    event.preventDefault();

    // When the user signs in with email and password.
    const auth = getAuth(firebase);
    setPersistence(auth, browserSessionPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password).then((uc) => {
        setIsLoading(false);
        const authUser = auth.currentUser;
        usersServices.getUser(authUser.uid).then((res) => {
          const user = {
            id: authUser.uid,
            name: authUser.displayName,
            email: authUser.email,
            role: res.data.role,
            isSchoolAdmin: res.data.role === PROFILES.SCHOOL_ADMIN.code,
            isAdmin: res.data.role === PROFILES.ADMIN.code,
            isCompanyAdmin: res.data.role === PROFILES.COMPANY_ADMIN.code         
          };
          // set user in app context
          setUser(
            user,
            (res.data.role === PROFILES.COMPANY_ADMIN.code || res.data.role === PROFILES.SCHOOL_ADMIN.code) ? res.data.companies[0].id : '',
            (res.data.role === PROFILES.COMPANY_ADMIN.code || res.data.role === PROFILES.SCHOOL_ADMIN.code) ? res.data.companies[0].name : '',
            res.data.companies
          );
        });
      }).catch ((err) => {
          console.log(err);
          setError(t('login.error'));
          setIsLoading(false);
      });
    });    
  };

  return (
        <div className="fixed-center">
          <Card className="login-card">
            <div className="panel-left">
              <img src={logoImg} alt=""/>
            </div>
            <div className="panel-right">
              <div className="title">
                <span className="color-primary">{t('login.title')}</span> <br/>
                <span className="text-small">{t('login.sub.title')}</span>
              </div>
              <form className="login" onSubmit={(e) => signIn(e)}>
                <div className="inputs">
                  <TextField
                    id="login-email"
                    name="email"
                    label={t('login.email')}
                    variant="outlined"
                    className="margin-bottom-20"
                    autoComplete="off"
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    required
                  />
                  <TextField
                    id="login-password"
                    name="password"
                    label={t('login.password')}
                    variant="outlined"
                    type="password"
                    className="margin-bottom-20"
                    onChange={e => setPassword(e.target.value)}
                    required
                  />
                  {<a href="/#/resetPassword" className="text-small text-right color-primary">{t('login.forgot.password')}</a>}
                </div>

                <div style={{minHeight: 30}}>
                  <Typography className="error">{error}</Typography>
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={isLoading}
                >
                  {t('login.button.login')}
                </Button>
                {isLoading && <LinearProgress/>}           
              </form>
            </div>
          </Card>
        </div>
    );
  };
