import React, {useState, useEffect, useContext, useCallback} from "react";
import { IconButton, MenuItem, Select} from '@mui/material';
import "./filters.scss";
import {AuthUserContext} from 'AuthUserContext';
import CompanyServices from "../../services/company.services";
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import companyImg from '../../assets/img/company.svg';

export const SelectCompany = (props: any) => {
  const { t } = useTranslation();
  const {user, companyId, setCompanyId, setCompanyName} = useContext(AuthUserContext); 
  const [companies, setCompanies] = useState(null);
  const enabled = props.enabled;

const loadCompanies = useCallback(() => {
  CompanyServices.getCompaniesSummary().then((res) => {
    setCompanies(res.data);
  }); 
}, []);

useEffect(() => {
  if (user.isAdmin) {
    loadCompanies();
  }
}, [loadCompanies, user]);

const refresh = () => {
  loadCompanies();
};

const handleCompanyChange = (newValue: string) => {
  // set company in app context
  const id = newValue;
  setCompanyId(id);
  const comp = companies.filter((item: any) => item.id === id) as any;
  setCompanyName(comp[0].name);
};

  return (
    <>
    {user && user.isAdmin && (
      <div style={{height: '30px'}}>
        {enabled && 
        <div className="margin-top-20 margin-left-20 margin-right-20" style={{fontSize: 'smaller'}}>
        
          <img src={companyImg} alt="Company" style={{maxHeight: 17}}/> {t('account.organisations')} : &nbsp;         
          
          <Select
            id="event"
            value={companyId}
            onChange={(e) => handleCompanyChange(e.target.value)}
            variant="standard"
            style={{width: 300}}
            label={t('detail.placeholder')}
          >
            {companies && companies.length > 1 && companies.map((item: any) => 
              <MenuItem style={{fontSize: 'smaller'}} key={item.id} value={item.id}>
                <span style={{fontSize: 'smaller'}}> {item.name}</span>
              </MenuItem>
            )}
          </Select>

          <IconButton
              title={t('participant.refresh')}
              size="small"
              onClick={() => refresh()}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>

          <span style={{fontSize: 'smaller', marginLeft: '10px'}}>{t('account.helper.admin')}</span>
            
        </div>
      }
      </div>
    )}
    </>
  );
};
