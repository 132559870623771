import "./CompanyForm.scss"
import React, {useState} from "react";
import {CompanyModel} from '../../../../models/company';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CompanyServices from "../../../../services/company.services";
import { toast } from "react-toastify";
import { countries } from "../../../shared/Constantes";
import emailImg from '../../../../assets/img/mail.svg';

interface CompanyFormProps {
    company?: CompanyModel;
    onClose: () => void;
    refresh: () => void;
    isOpen: boolean;
}

export const CompanyForm = (props: CompanyFormProps) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [name, setName] = useState(props.company && props.company.name ? props.company.name : '');
    const [contact, setContact] = useState(props.company && props.company.contact ? props.company.contact : '');
    const [phone, setPhone] = useState(props.company && props.company.phone? props.company.phone : '');
    const [country, setCountry] = useState(props.company && props.company.country? props.company.country : '');
    const [mail, setMail] = useState(props.company && props.company.mail ? props.company.mail : '');
    const [city, setCity] = useState(props.company && props.company.city? props.company.city : '');
    const [cp, setCp] = useState(props.company && props.company.cp ? props.company.cp : '');
    const [refEla, setRefEla] = useState(props.company && props.company.refEla ? props.company.refEla : '');
    const [errorRef, setErrorRef] = useState(false);
    const [helperTextRef, setHelperTextRef] = useState<string>(t('orgas.form.characters.20'));

    const submit = (e: any) => {
        
        setIsSubmitting(true);
        setErrorRef(false);
        setHelperTextRef(t('orgas.form.characters.20'));

        const company = {
            name: name,
            contact: contact,
            mail: mail,
            phone: phone,
            country: country,
            city: city,
            cp: cp,
            refEla: refEla,
            id: props.company ? props.company.id : null
        };

        if (props.company) {
            CompanyServices.updateCompany(company).then(() => {
                setIsSubmitting(false);
                props.onClose();
                toast.success(t('orgas.update.success', { company: company.name }), { position: 'bottom-right' });
                props.refresh();
            }).catch((err) => {
                setIsSubmitting(false);
                if (err.response.status === 409) {
                    setErrorRef(true);
                    setHelperTextRef(t('orgas.form.error'));
                } else {
                    toast.error(t('orgas.update.error', { company: company.name }), { position: 'bottom-right', autoClose: false });
                    console.log(err);
                }                
            });
        } else {
            CompanyServices.createCompany(company)
            .then(() => {                
                setIsSubmitting(false);
                props.onClose();
                toast.success(t('orgas.add.success', { company: company.name }), { position: 'bottom-right' });
                props.refresh();
            }).catch((err) => {
                setIsSubmitting(false);
                if (err.response.status === 409) {
                    setErrorRef(true);
                    setHelperTextRef(t('orgas.form.error'));
                } else {
                    toast.error(t('orgas.add.error', { company: company.name }), { position: 'bottom-right', autoClose: false });
                    console.log(err);
                }
            });
        }
               
        e.preventDefault();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => props.onClose()}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="form-dialog-title">
                {props.company ? t('orgas.form.title.update') : t('orgas.form.title.add')}
            </DialogTitle>

            <DialogContent className="modal__form">
                {!props.company && <p>{t('orgas.form.sub.title.add')}</p>}

                <form onSubmit={submit}>
                    <div className="separator-container company-form--width-all">
                        <div className="company-form__field">
                            <TextField
                                value={name}
                                inputProps={{maxLength: 30}}
                                helperText={t('orgas.form.characters.30')}
                                required
                                variant="outlined"
                                id="name"
                                label={t('orgas.form.name')}
                                type="text"
                                onChange={(event) => {setName(event.target.value as string);}}
                                fullWidth
                            />
                        </div>
                        
                        <div className="company-form__field">
                            <FormControl variant="outlined" fullWidth required className="margin-bottom-10">
                                <InputLabel>
                                    {t('orgas.form.country')}
                                </InputLabel>
                                <Select
                                    id="country"
                                    label={t('orgas.form.country')}
                                    value={country}
                                    required
                                    onChange={(event) => setCountry(event.target.value as string)}              
                                >
                                    {countries.map(({ label, code }) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="company-form__field">
                            <TextField
                                value={city}
                                inputProps={{maxLength: 32}}
                                helperText={t('orgas.form.characters.32')}
                                variant="outlined"
                                id="city"
                                label={t('orgas.form.city')}
                                type="text"
                                onChange={(event) => {setCity(event.target.value as string);}}
                                fullWidth
                            />
                        </div>

                        <div className="company-form__field">
                        <TextField
                                value={cp}
                                inputProps={{maxLength: 20}}
                                helperText={t('orgas.form.characters.20')}
                                variant="outlined"
                                id="cp"
                                label={t('orgas.form.cp')}
                                type="text"
                                onChange={(event) => {setCp(event.target.value as string);}}
                                fullWidth
                            />
                        </div>

                        <div className="company-form__field">
                            <TextField
                                value={contact}
                                inputProps={{maxLength: 50}}
                                helperText={t('orgas.form.characters.50')}
                                required
                                variant="outlined"
                                id="contact"
                                label={t('orgas.form.contact')}
                                type="text"
                                onChange={(event) => {setContact(event.target.value as string);}}
                                fullWidth
                            />
                        </div>

                        <div className="company-form__field">
                            <TextField
                                value={refEla}
                                inputProps={{maxLength: 20}}
                                helperText={helperTextRef}
                                error={errorRef}
                                variant="outlined"
                                id="contact"
                                label={t('orgas.form.ref.ela')}
                                type="text"
                                onChange={(event) => {
                                    setErrorRef(false);
                                    setHelperTextRef(t('orgas.form.characters.20'));
                                    setRefEla(event.target.value as string);
                                }}
                                fullWidth
                            />
                        </div>

                        <div className="section-title">
                            <img src={emailImg} alt="Localisation" /> {t('orga.infos')}
                        </div>                        

                        <div className="company-form__field">
                            <TextField
                                value={mail}
                                inputProps={{maxLength: 50}}
                                helperText={t('orgas.form.characters.50')}
                                required
                                variant="outlined"
                                id="mail"
                                label={t('orgas.form.email')}
                                type="email"
                                onChange={(event) => {setMail(event.target.value as string);}}
                                fullWidth
                            />
                        </div>

                        <div className="company-form__field">
                            <TextField
                                value={phone}
                                inputProps={{maxLength: 20}}
                                onErrorCapture={() => console.log('error')}
                                helperText={t('orgas.form.characters.20')}
                                required
                                variant="outlined"
                                id="phone"
                                label={t('orgas.form.phone')}
                                type="text"
                                onChange={(event) => {setPhone(event.target.value as string);}}
                                fullWidth
                            />
                        </div>

                        {isSubmitting && <LinearProgress/>}
                    </div>
                    <DialogActions>
                        <Button
                            onClick={() => props.onClose()}                        >
                            {t('button.cancel')}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            {t('button.validate')}
                        </Button>
                    </DialogActions>                    
                </form>
            </DialogContent>
        </Dialog>


    );
}
