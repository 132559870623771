import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  LinearProgress
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import firebase from '../../../config/firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import EventServices from '../../../services/event.services';

export const ResultImport = (props: any) => {

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const res:any = Object.values(props.results).reduce(
    (acc: any, result: any) => {
      if(result.userCreated) {
        acc.users ++;
        acc.emails.push(result.userCreatedMail);
      }
      if(!result.userCreated) {
        acc.usersUpdated ++;
        acc.emailsUpdated.push(result.userUpdatedMail);
      }
      if (result.companyCreated) {
        acc.companies ++;
      }
      if (result.eventCreated) {
        acc.events ++;
      }
      if (result.event) {
        acc.events ++;
      }
      return acc;
    },
    { events: 0, users: 0, usersUpdated:0, companies: 0, emails: [], emailsUpdated: []}
  );

  const sendMails = async () => {
    setIsSubmitting(true);

    if (res.emails.length > 0) {
      res.emails.map(async (mail:any) => {
        const auth = getAuth(firebase);
        await sendPasswordResetEmail(auth, mail);
      });
    }
    
    if (res.emailsUpdated.length > 0) {
      EventServices.sendMailToCoord(res.emailsUpdated);
    }
    
    setIsSubmitting(false);
    props.onClose();
  };

  return (
    <Dialog
        open={props.isOpen}
        onClose={() => props.onClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
    >
        <DialogTitle id="form-dialog-title">
            RESULTATS
        </DialogTitle>

        <DialogContent className="modal__form">
            
          <TableContainer>
            <Table stickyHeader style={{marginBottom: 20}}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('event.import.creation')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell key="1">{t('events.import.success.companies', {company: res.companies})}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key="2">
                      {t('events.import.success.users', {user : res.users })}
                      {res.emails.length > 0 && (`: ${res.emails.toString()}`) }
                      </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key="3">
                      {t('events.import.success.users.updated', {user : res.usersUpdated })}
                      {res.emailsUpdated.length > 0 && (`: ${res.emailsUpdated.toString()}`) }
                      </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key="4">{t('events.import.success.events', {event: res.events })}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {Object.values<any>(props.warnings).length > 0 &&
              <TableContainer>
                <Table stickyHeader style={{marginBottom: 20}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('event.import.warning')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries<any>(props.warnings).map(([key, warn]) => 
                      <TableRow>
                        <TableCell key={key}>{t('events.import.warning.message', {line: key, oldCode: warn.oldCode, newCode: warn.newCode})}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }

            {(res.emails.length > 0 || res.emailsUpdated.length > 0) &&
              <div className="margin-bottom-10">
                  {t('event.import.results.message')}
              </div>
            }
        </DialogContent>

        <DialogActions>
        {isSubmitting && <LinearProgress/>}
          <Button
              onClick={() => props.onClose()}
          >
              {(res.emails.length > 0 || res.emailsUpdated.length > 0) ? t('button.cancel') : "OK"}
          </Button>

          {(res.emails.length > 0 || res.emailsUpdated.length > 0) &&
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => sendMails()}
              disabled={isSubmitting}
            >
              {t('button.validate')}
            </Button>
          }
          
          </DialogActions>
      </Dialog>
  )
};
