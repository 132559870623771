import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  DatePicker, TimePicker
} from '@mui/x-date-pickers';
import './add-event.scss';
import { FORMAT_DATE, getCountryTimezone } from '../Constantes';
import EventServices from '../../../services/event.services';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import calendarImg from '../../../assets/img/calendar.svg';
import timeImg from '../../../assets/img/calendar_time.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

interface UpdateEventDatesModalProps {
  open: boolean;
  toggle: () => void;
  event?: any;
  refresh?: () => void;
}

export const UpdateEventDatesModal = (props: UpdateEventDatesModalProps) => {
  const { t } = useTranslation();

  const toLocalHour = (dtdayjs: any) => dayjs(dtdayjs.format('YYYY-MM-DD HH:mm'));
  const now = dayjs();

  const [startDate, setStartDate] = useState(props.event && props.event.startDate !== null && props.event.startDate !== "" ? dayjs(props.event.startDate).tz(getCountryTimezone(props.event.location)) : now);
  const [endDate, setEndDate] = useState(props.event && props.event.endDate !== null && props.event.endDate !== "" ? dayjs(props.event.endDate).tz(getCountryTimezone(props.event.location)) : now);
  const [startHour, setStartHour] = useState(props.event && props.event.startDate !== null ? toLocalHour(dayjs(props.event.startDate).tz(getCountryTimezone(props.event.location))) : now);
  const [endHour, setEndHour] = useState(props.event && props.event.endDate !== null !== null ? toLocalHour(dayjs(props.event.endDate).tz(getCountryTimezone(props.event.location))) : now);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorDate, setErrorDate] = useState(false);

  const handleError = () => {
    const start = dayjs(dayjs(startDate).format("YYYY-MM-DD") + " " + dayjs(startHour).format("HH:mm"));
    const end = dayjs(dayjs(endDate).format("YYYY-MM-DD") + " " + dayjs(endHour).format("HH:mm"));
    
    const hasError = start.isAfter(end) || start.isSame(end);
    setErrorDate(hasError);
    return hasError;
  };
  
  const update = (e: any) => {
    const country = props.event.location;
    const event = {
      id: props.event ? props.event.id : null,
      name: props.event.name,
      startDate: dayjs.tz(dayjs(startDate).format("YYYY-MM-DD") + " " + dayjs(startHour).format("HH:mm"), getCountryTimezone(country)).toISOString(),
      endDate: dayjs.tz(dayjs(endDate).format("YYYY-MM-DD") + " " + dayjs(endHour).format("HH:mm"), getCountryTimezone(country)).toISOString()
    };

    const hasError = handleError();
    if (!hasError) {
      setIsSubmitting(true);     
      EventServices.updateEventDates(event)
        .then((result: any) => {
          setIsSubmitting(false);
          props.toggle();
          toast.success(t('events.success2', {event: event.name}), { position: 'bottom-right' });
          props.refresh();
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
        });      
    }
    
    e.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {props.event ? t('events.edit') : t('events.add')}
      </DialogTitle>

      <form onSubmit={update} >

        <DialogContent>        
          
          <div className="margin-bottom-10">
            <div className="section-title">
              <img src={calendarImg} alt="plus icon" className="margin-right-10" />{t('events.date')}
            </div>
            <div className="form-row">
              <DatePicker
                value={startDate}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                  helperText: t('events.start.helper'),
                }}}
                onChange={(event) => {
                  setStartDate(event as any);
                }}
                onClose={handleError}
                label={t('events.start.label')}
                className="margin-right-20"
                format={FORMAT_DATE}
                closeOnSelect
                
              />
              <DatePicker
                value={endDate}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                  helperText: t('events.end.helper'),
                }}}
                label={t('events.end.label')}
                onChange={(event) => {
                  setEndDate(event as any);
                }}
                format={FORMAT_DATE}
                closeOnSelect
              />
            </div>

          </div>

          <div className="margin-bottom-10">
            <div className="section-title">
              <img src={timeImg} alt="plus icon" className="margin-right-10" />
              {t('events.defaultSchedule.label')}
            </div>
            
            <div className="form-row">
              <TimePicker
                value={startHour}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                }}}
                onChange={(event) => {
                  setStartHour(event as any);
                }}
                label={t('events.start.label')}
                className="margin-right-20"
                ampm={false}
                closeOnSelect
              />
              <TimePicker
                value={endHour}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                }}}
                onChange={(event) => {
                  setEndHour(event as any);
                }}
                label={t('events.end.label')}
                ampm={false}
                closeOnSelect
              />
            </div>
          </div>

          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          {errorDate ? <Typography className="error">{t('events.error.message')}</Typography> : null}
          <Button onClick={props.toggle}>
            {t('button.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('button.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
