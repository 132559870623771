import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import './add-event.scss';
import { FORMAT_DATE, countries, eventType, getCountryTimezone } from '../../shared/Constantes';
import EventServices from '../../../services/event.services';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import calendarImg from '../../../assets/img/calendar.svg';
import timeImg from '../../../assets/img/calendar_time.svg';
import countryImg from '../../../assets/img/country.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

interface AddEventModalProps {
  open: boolean;
  toggle: () => void;
  event?: any;
  refresh?: () => void;
}

export const AddEventModal = (props: AddEventModalProps) => {
  const { t } = useTranslation();

  const toLocalHour = (dtdayjs: any) => dayjs(dtdayjs.format('YYYY-MM-DD HH:mm'));
  const now = dayjs();

  const [refEla, setRefEla] = useState(props.event && props.event.refEla ? props.event.refEla : '');
  const [country, setCountry] = useState(props.event && props.event.location ? props.event.location : '');
  const [type, setType] = useState(props.event && props.event.type ? props.event.type : '');
  const [isPromiseEnabled, setIsPromiseEnabled] = useState(props.event ? props.event.isPromiseEnabled : false);
  const [title, setTitle] = useState(props.event ? props.event.name : '');
  const [description, setDescription] = useState(props.event && props.event.description ? props.event.description : '');
  const [startDate, setStartDate] = useState(props.event && props.event.startDate !== null && props.event.startDate !== "" ? dayjs(props.event.startDate).tz(getCountryTimezone(props.event.location)) : now);
  const [endDate, setEndDate] = useState(props.event && props.event.endDate !== null && props.event.endDate !== "" ? dayjs(props.event.endDate).tz(getCountryTimezone(props.event.location)) : now);
  const [startHour, setStartHour] = useState(props.event && props.event.startDate !== null ? toLocalHour(dayjs(props.event.startDate).tz(getCountryTimezone(props.event.location))) : now);
  const [endHour, setEndHour] = useState(props.event && props.event.endDate !== null !== null ? toLocalHour(dayjs(props.event.endDate).tz(getCountryTimezone(props.event.location))) : now);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorDate, setErrorDate] = useState(false);
  const [errorRef, setErrorRef] = useState(false);
  const [helperTextRef, setHelperTextRef] = useState<string>(t('orgas.form.characters.20'));

  const handleError = () => {
    const start = dayjs(dayjs(startDate).format("YYYY-MM-DD") + " " + dayjs(startHour).format("HH:mm"));
    const end = dayjs(dayjs(endDate).format("YYYY-MM-DD") + " " + dayjs(endHour).format("HH:mm"));
    
    const hasError = start.isAfter(end) || start.isSame(end);
    setErrorDate(hasError);
    return hasError;
  };
  
  const insertOrUpdate = (e: any) => {
    
    const event = {
      id: props.event ? props.event.id : null,
      name: title,
      location: country !== '' ? country : null,
      type: type !== '' ? type : null,
      description: description !== '' ? description : null,
      isPromiseEnabled: isPromiseEnabled,
      startDate: dayjs.tz(dayjs(startDate).format("YYYY-MM-DD") + " " + dayjs(startHour).format("HH:mm"), getCountryTimezone(country)).toISOString(),
      endDate: dayjs.tz(dayjs(endDate).format("YYYY-MM-DD") + " " + dayjs(endHour).format("HH:mm"), getCountryTimezone(country)).toISOString(),
      refEla: refEla !== '' ? refEla : null
    };

    const hasError = handleError();
    if (!hasError) {
      setIsSubmitting(true);
      if (props.event) {
        EventServices.updateEvent(event)
          .then((result: any) => {
            setIsSubmitting(false);
            props.toggle();
            toast.success(t('events.success2', {event: event.name}), { position: 'bottom-right' });
            props.refresh();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              setErrorRef(true);
              setHelperTextRef(t('orgas.form.error'));
              setIsSubmitting(false);
            } else {
              console.log(error);
              setIsSubmitting(false);
              toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
            }            
          })
      } else {        
        EventServices.createEvent(event)
          .then((result: any) => {
            props.toggle();
            setIsSubmitting(false);
            toast.success(t('events.success1', {event: event.name}), { position: 'bottom-right' });
            props.refresh();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              setErrorRef(true);
              setHelperTextRef(t('orgas.form.error'));
              setIsSubmitting(false);
            } else {
              console.log(error);
              setIsSubmitting(false);
              toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
            }   
          })
      }
    }
    
    e.preventDefault();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {props.event ? t('events.edit') : t('events.add')}
      </DialogTitle>

      <form onSubmit={insertOrUpdate} >

        <DialogContent>
          <FormControlLabel
            className="margin-bottom-10"
            control={
              <Switch
                checked={isPromiseEnabled}
                onChange={(e) => setIsPromiseEnabled(e.target.checked)}
                name="isPromiseEnabled"
                color="primary"
              />
            }
            label={t('events.promise.enabled')}
          />
          <TextField
            value={title}
            inputProps={{maxLength: 50}}
            helperText={t('events.name.helper')}
            required
            variant="outlined"
            id="title"
            label={t('events.name.label')}
            type="text"
            onChange={(event) => setTitle(event.target.value as string)}
            fullWidth
            className="margin-bottom-10"
          />
         
          <div className="margin-bottom-10">
            <FormControl variant="outlined" required fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t('events.type.label')}
              </InputLabel>
              <Select
                id="type"
                label={t('events.type.label')}
                value={type}
                required
                onChange={(event) => setType(event.target.value as string)}
              >
                {Object.values(eventType).map(({label, code}) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
              </Select>
            </FormControl>
          </div>

          <TextField
            value={refEla}
            inputProps={{maxLength: 20}}
            helperText={helperTextRef}
            error={errorRef}
            variant="outlined"
            id="contact"
            label={t('orgas.form.ref.ela')}
            type="text"
            onChange={(event) => {
              setErrorRef(false);
              setHelperTextRef(t('orgas.form.characters.20'));
              setRefEla(event.target.value as string);
            }}
            fullWidth
            className="margin-bottom-10"
          />

          <div className="margin-bottom-10">
            <div className="section-title">
              <img src={calendarImg} alt="plus icon" className="margin-right-10" />{t('events.date')}
            </div>
            <div className="form-row">
              <DatePicker
                value={startDate}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                  helperText: t('events.start.helper')
                }}}
                onChange={(event) => {
                  setStartDate(event as any);                  
                }}
                label={t('events.start.label')}
                className="margin-right-20"
                format={FORMAT_DATE}
                closeOnSelect
              />
              <DatePicker
                value={endDate}
                slotProps={{ textField: {
                  required: true,
                  error: errorDate,
                  fullWidth: true,
                  helperText: t('events.end.helper')
                }}}
                label={t('events.end.label')}
                onChange={(event) => {
                  setEndDate(event as any);
                }}
                format={FORMAT_DATE}
                closeOnSelect
              />
            </div>

          </div>

          <div className="margin-bottom-10">
            <div className="section-title">
              <img src={timeImg} alt="plus icon" className="margin-right-10" />
              {t('events.defaultSchedule.label')}
            </div>
            <div className="margin-bottom-15 text-small">{t('events.defaultSchedule.description')}
            </div>
            <div className="form-row">
              <TimePicker
                value={startHour}
                slotProps={{ textField: {
                  required: true,
                  fullWidth: true,
                }}}
                onChange={(event) => {
                  setStartHour(event as any);
                }}
                label={t('events.start.label')}
                className="margin-right-20"
                ampm={false}
                closeOnSelect
              />
              <TimePicker
                value={endHour}
                slotProps={{ textField: {
                  required: true,
                  error: errorDate,
                  fullWidth: true,
                }}}
                onChange={(event) => {
                  setEndHour(event as any);
                }}                
                label={t('events.end.label')}                
                ampm={false}
                closeOnSelect
              />
            </div>
          </div>

          <div className="margin-bottom-10">
            <div className="section-title"><img src={countryImg} alt="plus icon" className="margin-right-10" />{t('events.country.label')} </div>
            <div className="margin-bottom-15 text-small">{t('events.country.description')}</div>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t('events.country.label')}
              </InputLabel>
              <Select
                id="country"
                label={t('events.country.label')}
                value={country}
                onChange={(event) => setCountry(event.target.value as string)}
              >
                <MenuItem value="">
                  <em>{t('events.country.none')} </em>
                </MenuItem>
                {countries.map(({ label, code }) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
              </Select>
            </FormControl>
          </div>

          <TextField
            value={description}
            error={description.length > 200}
            helperText={description.length > 200 ? t('events.description.helper') : ""}
            variant="outlined"
            id="name"
            label={t('events.description.label')}
            type="text"
            onChange={(event) => setDescription(event.target.value as string)}
            fullWidth
            multiline
            rows="4"
          />

          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          {errorDate ? <Typography className="error">{t('events.error.message')}</Typography> : null}
          <Button onClick={props.toggle}>
            {t('button.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('button.validate')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
