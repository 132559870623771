import React, { useState } from "react";

import "./params.scss";
// @ts-ignore
import EventServices from '../../../services/event.services';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, LinearProgress, MenuItem, Radio, Select } from "@mui/material";
import { download } from '../../shared/tools/Utils';
import { eventType, countries, FORMAT_DATE } from "../../shared/Constantes";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import countryImg from '../../../assets/img/country.svg';
import calendarImg from '../../../assets/img/calendar.svg';
import dayjs from "dayjs";

export const Exports = (props: any) => {
  const { t } = useTranslation();  

  const [isEnabled, setIsEnabled] = useState(true);
  const [country, setCountry] = useState("");
  const [isMTBE, setIsMTBE] = useState(true);
  const [isMTB, setIsMTB] = useState(true);
  const [isGP, setIsGP] = useState(true);
  const [isTPE, setIsTPE] = useState(true);
  const [isStartDateError, setIsStartDateError] = useState(false);
  const [isEndDateError, setIsEndDateError] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const downloadStat = () => {
    setIsEnabled(false);
    setIsLoading(true);
    if (startDate === null) {
      setIsEnabled(true);
      setIsLoading(false);
      setIsStartDateError(true);
    } else if (endDate === null) {
      setIsEnabled(true);
      setIsLoading(false);
      setIsEndDateError(true);
    } else {

      const type = 
          (isMTBE ? eventType.MTBE.code + ';': '')
        + (isMTB ? eventType.MTB.code + ';' : '')
        + (isGP ? eventType.GP.code + ';' : '')
        + (isTPE ? eventType.TPE.code : '');
      
      EventServices.downloadStatistics(
        type,
        startDate ? dayjs(startDate).format("YYYY-MM-DD"): '',
        endDate ? dayjs(endDate).format("YYYY-MM-DD") : '',
        country)
      .then((response) => {
        download(
          response.data,
          `Statistiques_challenges_${dayjs().toISOString()}.xlsx` ,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        setIsEnabled(true);
        setIsLoading(false);
      }).catch(() => {
        setIsEnabled(true);
        setIsLoading(false);
        toast.error(t('params.export.error'), { position: 'bottom-right', autoClose: false })
      });
    }
  };

  return (  
    <div style={{display:'flex', flexDirection:'column'}}>
      <div style={{height:10}}>
          {isLoading && <LinearProgress />} 
      </div>
      <FormControlLabel
        control={<Radio 
        checked={true}
        name="isAdmin" 
        color="primary"/>}
        label={t('params.export.stats')}
      />

      <div className="params">
        <div style={{display:'flex', flexDirection:'row'}}>
          <FormControlLabel
            control={<Checkbox
            checked={isMTBE}
            onChange={(e) => setIsMTBE(e.target.checked)}
            name="isMTBE"
            color="primary" />}
            label={t(eventType.MTBE.label)}
          />
          <FormControlLabel
            control={<Checkbox
            checked={isMTB}
            onChange={(e) => setIsMTB(e.target.checked)}
            name="isMTB"
            color="primary" />}
            label={t(eventType.MTB.label)}
          />
          <FormControlLabel
            control={<Checkbox
            checked={isGP}
            onChange={(e) => setIsGP(e.target.checked)}
            name="isGP"
            color="primary" />}
            label={t(eventType.GP.label)}
          />
          <FormControlLabel
            control={<Checkbox
            checked={isTPE}
            onChange={(e) => setIsTPE(e.target.checked)}
            name="isTPE"
            color="primary" />}
            label={t(eventType.TPE.label)}
          />
        </div>

      <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{display:'flex', flexDirection:'column', marginRight:30}}>
          <div className="section-title"><img src={countryImg} alt="plus icon" className="margin-right-10" />{t('events.country.label')} </div>
          <FormControl variant="outlined">
            <InputLabel>
              {t('orga.country')}
            </InputLabel>
            <Select
              id="country"
              label={t('orga.country')}
              value={country}
              style={{ width: 300 }}
              onChange={(event) => setCountry(event.target.value as string)}
            >
              <MenuItem value="">
                <em>{t('events.country.none')} </em>
              </MenuItem>
              {countries.map(({ label, code }) => <MenuItem key={code} value={code}>{t(label)}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        
        <div style={{display:'flex', flexDirection:'column', marginBottom:10}}>
          <div className="section-title">
            <img src={calendarImg} alt="plus icon" className="margin-right-10" />{t('events.date')}
          </div>
          <div style={{display:'flex', flexDirection:'row', marginBottom:10}}>
          <DesktopDatePicker
            value={startDate}
            slotProps={{ textField: { required: true, error: isStartDateError, fullWidth: true, helperText: t('events.start.helper') } }}
            onChange={(event) => {
              setStartDate(event as any);
              setIsStartDateError(false);           
            }}            
            label={t('events.start.label')}
            className="margin-right-10"
            format={FORMAT_DATE}
            closeOnSelect
          />
          <DesktopDatePicker
            value={endDate}
            slotProps={{ textField: { required: true, error: isEndDateError, fullWidth: true, helperText: t('events.end.helper') } }}
            label={t('events.end.label')}
            onChange={(event) => {
              setEndDate(event as any);
              setIsEndDateError(false);
            }}
            format={FORMAT_DATE}
            closeOnSelect
          />
          </div>
        </div>
        </div>
      </div>

      <div className="buttons">
        <Button
          color="primary"
          variant="contained"              
          endIcon={<DownloadIcon />}
          onClick={() => downloadStat()}
          disabled={!isEnabled}
        >
          {t('button.export')}
        </Button>
        
      </div>
    </div>
  )
};
