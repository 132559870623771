import React, {useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  LinearProgress
} from '@mui/material';
import dayjs from 'dayjs';
import EventServices from '../../../services/event.services';
import {toast} from "react-toastify";
import config from '../../../config/config';
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/GetApp";
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, getCountryTimezone } from "components/shared/Constantes";
import { formatNameOfDocument } from "components/shared/tools/Toolbox";
import i18next from 'i18next';
import {download} from '../../shared/tools/Utils';

export const DetailTable = (props: any) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [language] = useState(i18next.language);

  const unregister = (company: any) => {
    setIsLoading(true);
    EventServices.unregisterCompany(props.event.id, company.id)
      .then(() => {
        props.load();
        toast.success(t('detail.unregister.success', {company: company.name}), { position: 'bottom-right' });
        setIsLoading(false);
      }).catch((error) => {
        toast.error(error.message, { position: 'bottom-right', autoClose: false })
      });
  };

  const downloadUserGuide = (company: any) => {
    setIsLoading(true);
    EventServices.downloadUserGuide(props.event.id, company.id, getCountryTimezone(props.event.location)).then((response) => {
      download(
        response.data,
        formatNameOfDocument(company.qrcode +'_'+ company.name, '.pdf'),
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
      setIsLoading(false);
    }).catch(() => {
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
    });
  };

  return (
    
      <div className="section margin-top-20">
        <div className="section-info">
          {props.companies && t('detail.orgas', {nb: props.companies.length})}
        </div>      

      <TableContainer>
      {isLoading && <LinearProgress/>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('detail.orga')}</TableCell>
              <TableCell align="right">{t('detail.qrcode')}</TableCell>
              <TableCell align="right">{t('detail.register.date')}</TableCell>
              <TableCell/>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.companies.map((company: any) => (
              <TableRow key={company.name} hover>
                <TableCell component="th" scope="row">{company.name}</TableCell>
                <TableCell align="right">{company.qrcode}</TableCell>
                <TableCell align="right">{dayjs(company.subscriptionDate).format(FORMAT_DATE)}</TableCell>                
                
                <TableCell align="right">
                  <a href={`${config.dashboardUrl}/#eventId=${props.event.id}&companyId=${company.id}&lang=${language}`} target="_blank" rel="noopener noreferrer"> 
                    {t('detail.results')}
                  </a>
                </TableCell>
                <TableCell align="right">
                  {props.event.isGuideEnabled && 
                    <IconButton
                    title={t('detail.button.dowload.guide')}
                    disabled={isLoading}
                    size="small"
                    onClick={() => downloadUserGuide(company)}
                    >
                        <DownloadIcon fontSize="inherit"/>
                    </IconButton>
                  }
                  {props.event.isEditable && 
                  <IconButton
                      title={t('detail.button.unregister')}
                      size="small"
                      disabled={isLoading}
                      onClick={() => unregister(company)}
                  >
                      <DeleteIcon fontSize="inherit"/>
                  </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  );
};
