import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  handleAction: () => void;
  title: string;
  message: string;
  confirmLabel?: string
  cancelLabel?: string
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
  }, [props]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
        {isSubmitting && <LinearProgress/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>
          {props.cancelLabel ? props.cancelLabel : 'Annuler'}
        </Button>
        <Button
          onClick={() => { setIsSubmitting(true); props.handleAction()} }
          variant="contained"
          color="primary"
          disableElevation
          disabled={isSubmitting}
        >
          {props.confirmLabel ? props.confirmLabel : 'Ok'}
        </Button>

        
      </DialogActions>
    </Dialog>
  );
}
