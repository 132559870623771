
const download = (data: any, fileName: any, type : any) => {
  const file = new Blob([data], {type: type});
  const href = window.URL.createObjectURL(file);
  const tempLink = document.createElement('a');
  tempLink.href = href;
  tempLink.setAttribute('download', fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export { download };