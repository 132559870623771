import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import Card from '@mui/material/Card';
import {
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    IconButton, 
    FormControl,
    InputLabel,
    Select,
    TextField,
    Button,
    MenuItem,
    Typography,
    InputAdornment,
    LinearProgress,
    TableSortLabel
} from '@mui/material';
import "./participant.scss";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import RunnerServices from "../../../services/runners.services";
import CompanyServices from "../../../services/company.services";
import { toast } from "react-toastify";
import { AuthUserContext } from 'AuthUserContext';
import SearchIcon from '@mui/icons-material/Search';
import NotifOk from '@mui/icons-material/Check';
import NotifNotOk from '@mui/icons-material/Clear';
import Help from '@mui/icons-material/HelpOutline';
import { useDialog } from "../../shared/tools/CustomHooks";
import { RunnerForm } from "./RunnerForm";
import { formatterNumber } from "../../shared/tools/Toolbox";
import { ConfirmDialog } from "../../shared/ConfirmDialog/ConfirmDialog";
import { useTranslation } from 'react-i18next';
import { download } from '../../shared/tools/Utils';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import dayjs from "dayjs";
import { FORMAT_DATE_TIME } from "components/shared/Constantes";

export const Participant = (props: any) => {
      
    const { t } = useTranslation();
    const { companyId } = useContext(AuthUserContext);

    const [eventId, setEventId] = useState('');
    const [isEventToCome, setIsEventToCome] = useState(false);
    const [code, setCode] = useState('');
    const [events, setEvents] = useState<any[]>([]);
    const [runners, setRunners] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [open, toggle] = useDialog(false);
    const [openSup, toggleSup] = useDialog(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRunner, setSelectedRunner] = useState(null);
    const [levels, setLevels] = useState([]);
    const [orderBy, setOrderBy] = useState('pseudo');
    const [order, setOrder] = useState('asc');
    const [isEnabled, setIsEnabled] = useState(true);
    const [lastUpdate, setLastupdate] = useState(null);

    const handleSearchChange = (value: string) => {
        setSearch(value);
    };

    const handleEventChange = (value: string) => {
        const id = value;
        setEventId(id);
        setRunners([]);
        const evt = events.filter((item: any) => item.id === id);
        if (evt.length > 0) {
            setCode(evt[0].qrcode);
            setIsEventToCome(dayjs(evt[0].startDate).isAfter(dayjs()))
        }
    };

    const filteredRunners = useMemo(() => {
        return runners.filter((item: any) => {
            return (item.pseudo && (item.pseudo as string).toLowerCase().includes(search.toLowerCase()));
        }).sort((a: any, b: any) => {
            if (orderBy === 'nbSteps') {
                return (order === 'asc') ? a[orderBy]> b[orderBy] ? -1 : 1 : a[orderBy] > b[orderBy] ? 1 : -1;
            }
            if (orderBy === 'active') {
                return (order === 'asc') 
                ? (a[orderBy] === true && b[orderBy] === false ? -1 : 1) 
                : (a[orderBy] === true && b[orderBy] === false ? 1 : -1)
                ;
            }
            if (!a[orderBy]) {
                return (order === 'asc' ? -1 : 1);
            }
            if (!b[orderBy]) {
                return (order !== 'asc' ? -1 : 1);
            }
            if (orderBy === 'registrationDate' || orderBy === 'updateDate') {
                return (order === 'asc') ? dayjs(a[orderBy]).isAfter(dayjs(b[orderBy])) ? 1 : -1
                    : dayjs(a[orderBy]).isBefore(dayjs(b[orderBy])) ? 1 : -1
            }            
            const labelFunction = (orderBy === 'subsidiary' || orderBy === 'service') ? (x: any) => x.name : (x: any) => x
            if (order === 'asc') {
                return labelFunction(a[orderBy]).localeCompare(labelFunction(b[orderBy]));
            } else {
                return labelFunction(b[orderBy]).localeCompare(labelFunction(a[orderBy]));
            }
        });
    }, [runners, search, orderBy, order]);

    const loadEvents = useCallback(() => {
        CompanyServices.getEvents(companyId)
            .then((result: any) => {
                const list = result.data;
                list.sort((a: any,b: any) => {
                    if (a.name < b.name) {
                      return -1
                    } else if (a.name > b.name) {
                        return 1
                    }
                    return 0;
                  });
                setEvents(list);        
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err.message);
                setIsLoading(false);
                toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
            });
    }, [companyId, t]);

    const loadRunners = useCallback(() => {
        if (eventId) {
            setIsLoading(true);
            RunnerServices.getRunners(eventId, companyId)
                .then((resp) => {
                    setRunners(resp.data);
                    setLastupdate(dayjs());
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false);
                    toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, t]);

    const loadLevels = useCallback(() => {
        if (eventId) {
            RunnerServices.getLevels(eventId, companyId)
                .then((results) => {
                    setLevels(results.data);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    const refresh = () => {
        setIsLoading(true);
        loadLevels();
        loadRunners();
    };

    useEffect(() => {
        setIsLoading(true);
        loadLevels();
        loadRunners();
    }, [eventId, loadLevels, loadRunners]);

    useEffect(() => {
        setIsLoading(true);
        setEventId('');
        setRunners([]);
        setLevels([]);
        loadEvents();
    }, [companyId, loadEvents]);

    const handleCloseSup = () => {
        toggleSup();
        setSelectedRunner(null);
    };

    const reset = () => {
        setSelectedRunner(null);
    };

    const handleDelete = () => {
        RunnerServices.deleteRunner(eventId, companyId, selectedRunner.subsidiary.id, selectedRunner.service.id, selectedRunner.id)
            .then(() => {
                loadRunners();
                toast.success(t('participant.delete.success', { runner: selectedRunner.pseudo }), { position: 'bottom-right' });
                toggleSup();
                reset();
            })
            .catch(() => {
                toast.error(t('participant.delete.error', { runner: selectedRunner.pseudo }), { position: 'bottom-right', autoClose: false });
                toggleSup();
                reset();
            });
    };

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const downloadRunners = () => {
        setIsEnabled(false);
        setIsLoading(true);
        RunnerServices.downloadRunners(eventId, companyId)
        .then((response) => {
          download(
            response.data,
            `Participants_${code}_${dayjs().toISOString()}.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          setIsEnabled(true);
          setIsLoading(false);
        }).catch(() => {
          setIsEnabled(true);
          toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
        });
      };

    return (
        <div className="content">
            <div className="participant-container">
                <Card className="width-full">
                    <CardContent className="content">

                        <div className="margin-bottom-15 filter-menu">
                            <div className="filters">
                                <FormControl
                                    style={{ minWidth: '300px', paddingRight: '10px' }}
                                >
                                    <InputLabel id="event-label">
                                        {t('participant.header.dropdown')}
                                    </InputLabel>
                                    <Select
                                        labelId="event-label"
                                        variant="standard"
                                        id="event"
                                        label={t('participant.header.dropdown')}
                                        value={eventId}
                                        onChange={(e) => handleEventChange(e.target.value)}
                                    >
                                        {events.map((item: any) =>
                                            <MenuItem key={item.id} value={item.id}>{item.name} ({item.qrcode})</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="standard-basic"
                                    variant="standard"
                                    label={t('participant.header.runnersSearch')}
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    type="search"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ width: '400px' }}
                                />
                            </div>

                            {open && 
                                <RunnerForm
                                    open={true}
                                    toggle={toggle}
                                    event={eventId}
                                    runner={selectedRunner}
                                    levels={levels}
                                    refresh={() => loadRunners()}
                                    reset={() => reset()}
                                />
                            }

                            {openSup &&
                                <ConfirmDialog
                                    open={true}
                                    handleClose={handleCloseSup}
                                    handleAction={handleDelete}
                                    title={t('participant.deletion.title')}
                                    message={selectedRunner ? t('participant.deletion.content', { runner: selectedRunner.pseudo }) : ''}
                                    confirmLabel={t('button.validate')}
                                />
                            }                            

                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                endIcon={<AddIcon />}
                                className="add-button"
                                onClick={() => {
                                    setSelectedRunner(null);
                                    toggle();
                                }}
                                disabled={!eventId}
                                size="small"
                            >
                                {t('participant.header.addRunner')}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                endIcon={<DownloadIcon />}
                                onClick={() => downloadRunners()}
                                disabled={!eventId || !isEnabled}
                                size="small"
                                style={{marginLeft:20}}
                            >
                                {t('button.export')}
                            </Button>
                        </div>

                        <div style={{height:10}}>
                           {isLoading && <LinearProgress />} 
                        </div>

                        <div className="section margin-20">
                            {eventId && filteredRunners && (
                                <>
                                    <div>
                                        {t('participant.title')}
                                    </div>                           
                                    <div className="section-info margin-bottom-20">
                                        {t('participant.refresh.date', {lastUpdate :lastUpdate ? lastUpdate.format(FORMAT_DATE_TIME) : '-'})}
                                        <IconButton 
                                            title={t('participant.refresh')}
                                            size="small"
                                            onClick={() => refresh()}
                                        >
                                        <RefreshIcon fontSize="inherit" />
                                        </IconButton>
                                    </div>
                                    <div className="margin-bottom-20" style={{display: 'flex', flexDirection:'column'}}>
                                        <div {...isEventToCome && { className: "margin-bottom-10" }} style={{display: 'flex', flexDirection:'row'}}>
                                            <div><WarningIcon style={{ color: 'red'}} fontSize="inherit" className="margin-right-10"/></div>                                        
                                            <div style={{fontStyle: 'italic', fontSize: 'smaller'}}>{t('participant.alert')}</div>
                                        </div>
                                        {isEventToCome && (
                                            <div style={{display: 'flex', flexDirection:'row'}}>
                                                <div><WarningIcon style={{ color: 'red'}} fontSize="inherit" className="margin-right-10"/></div>                                        
                                                <div style={{fontStyle: 'italic', fontWeight: 'bold' }}>{t('participant.synch.alert')}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="section-info">
                                        {t('participant.runners', { nb: filteredRunners.length })}
                                    </div>
                                </>
                            )}
                            {!eventId && !isLoading && (
                                <Typography>{t('participant.selectEvent')}</Typography>
                            )}

                            {filteredRunners && filteredRunners.length > 0 && (
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'pseudo'}
                                                    direction={orderBy === 'pseudo' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('pseudo')}
                                                >{t('participant.table.pseudo')}</TableSortLabel></TableCell>                                                
                                                <TableCell align="right"><TableSortLabel
                                                    active={orderBy === 'nbSteps'}
                                                    direction={orderBy === 'nbSteps' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('nbSteps')}
                                                >{t('participant.table.stepNumber')}</TableSortLabel></TableCell>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'active'}
                                                    direction={orderBy === 'active' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('active')}
                                                >{t('participant.table.active')}</TableSortLabel></TableCell>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'deviceToken'}
                                                    direction={orderBy === 'deviceToken' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('deviceToken')}
                                                >{t('participant.table.notification')}</TableSortLabel></TableCell>                                                
                                                <TableCell colSpan={2}><TableSortLabel
                                                    active={orderBy === 'baseOs'}
                                                    direction={orderBy === 'baseOs' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('baseOs')}
                                                >{t('participant.table.appActivated')}</TableSortLabel></TableCell>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'registrationDate'}
                                                    direction={orderBy === 'registrationDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('registrationDate')}
                                                >{t('participant.table.registrationDate')}</TableSortLabel></TableCell>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'updateDate'}
                                                    direction={orderBy === 'updateDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('updateDate')}
                                                >{t('participant.table.lastModification')}</TableSortLabel></TableCell>                                                
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'subsidiary'}
                                                    direction={orderBy === 'subsidiary' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('subsidiary')}
                                                >{t('participant.table.department')}</TableSortLabel></TableCell>
                                                <TableCell><TableSortLabel
                                                    active={orderBy === 'service'}
                                                    direction={orderBy === 'service' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                                    onClick={createSortHandler('service')}
                                                >{t('participant.table.service')}</TableSortLabel></TableCell>
                                                <TableCell align="right" style={{ width: '60px' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredRunners && filteredRunners.map((runner: any) => (
                                                <TableRow key={runner.id+runner.service.id+runner.subsidiary.id} hover>
                                                    <TableCell>{runner.pseudo}</TableCell>
                                                    {!runner.alert 
                                                        ? <TableCell align="right">{formatterNumber().format(runner.nbSteps)}</TableCell> 
                                                        : null
                                                    }
                                                    {runner.alert
                                                        ? <TableCell align="right" style={{ color: 'red'}}>{formatterNumber().format(runner.nbSteps)}</TableCell>
                                                        : null
                                                    }
                                                    {runner.active
                                                        ? <TableCell style={{cursor: 'help'}}><NotifOk style={{ color: 'green'}}/></TableCell>
                                                        : null
                                                    }
                                                    {!runner.active
                                                        ? <TableCell><NotifNotOk style={{ color: 'red'}}/></TableCell>
                                                        : null
                                                    }
                                                    {runner.deviceToken && (runner.active === undefined || runner.active)
                                                        ? <TableCell style={{cursor: 'help'}} title={runner.deviceToken}><NotifOk style={{ color: 'green'}}/></TableCell>
                                                        : null
                                                    }
                                                    {(!runner.deviceToken || (runner.active !== undefined && !runner.active))
                                                        ? <TableCell><NotifNotOk style={{ color: 'red'}}/></TableCell>
                                                        : null
                                                    }
                                                    {runner.appActivated === undefined
                                                        ? <TableCell><Help style={{ color: 'orange'}}/></TableCell>
                                                        : null
                                                    }
                                                    {runner.appActivated !== undefined && runner.appActivated
                                                        ? <TableCell><NotifOk style={{ color: 'green'}}/></TableCell>
                                                        : null
                                                    }
                                                    {runner.appActivated !== undefined && !runner.appActivated
                                                        ? <TableCell><NotifNotOk style={{ color: 'red'}}/></TableCell>
                                                        : null
                                                    }
                                                    {runner.version && runner.version !== '-' 
                                                        ? <TableCell title={`v${runner.version}`} style={{cursor: 'help'}}>{runner.baseOs ? ' ('+runner.baseOs+')' : ' (-)'}</TableCell>
                                                        : <TableCell>{runner.baseOs ? ' ('+runner.baseOs+')' : ' (-)'}</TableCell>
                                                    }
                                                    {runner.typeCreation
                                                        ? <TableCell title={runner.typeCreation} style={{cursor: 'help'}}>{runner.registrationDate ? dayjs(runner.registrationDate).format(FORMAT_DATE_TIME) : '-'}</TableCell>
                                                        : <TableCell>{runner.registrationDate ? dayjs(runner.registrationDate).format(FORMAT_DATE_TIME) : '-'}</TableCell>
                                                    }
                                                    {runner.typeUpdate
                                                        ? <TableCell title={runner.typeUpdate} style={{cursor: 'help'}}>{runner.updateDate ? dayjs(runner.updateDate).format(FORMAT_DATE_TIME) : '-'}</TableCell>
                                                        : <TableCell>{runner.updateDate ? dayjs(runner.updateDate).format(FORMAT_DATE_TIME) : '-'}</TableCell>
                                                    }
                                                    <TableCell>{runner.subsidiary.name}</TableCell>
                                                    <TableCell>{runner.service.name}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton aria-label="delete" size="small"
                                                            onClick={() => {
                                                                setSelectedRunner(runner);
                                                                toggle();
                                                            }}>
                                                            <EditIcon fontSize="inherit" />
                                                        </IconButton>
                                                        <IconButton aria-label="delete" size="small"
                                                            onClick={() => {
                                                                setSelectedRunner(runner);
                                                                toggleSup();
                                                            }}>
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
