
const numberWithSpaces = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const formatterCurrency = (currency: any) => new Intl.NumberFormat('fr', {
  style: 'currency',
  currency: currency,
  maximumFractionDigits: 3
});

const combining = /[\u0300-\u036F]/g; 

const formatNameOfDocument = (name: string, ext: string) => {
  let result = name.split(' ').join('_').toUpperCase();
  return result.normalize('NFKD').replace(combining, '') + ext; 
}

const formatterNumber = () => new Intl.NumberFormat('fr', {maximumFractionDigits: 3});

export {numberWithSpaces, formatterCurrency, formatterNumber, formatNameOfDocument};
