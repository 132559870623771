import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
    Button,
    IconButton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    InputAdornment,
    LinearProgress,
    TableSortLabel
} from "@mui/material";
import "./company.scss";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompanyServices from "../../../services/company.services";
import { toast } from "react-toastify";
import { useDialog } from "../../shared/tools/CustomHooks";
import { CompanyForm } from "./CompanyForm/CompanyForm";
import { ConfirmDialog } from "../../shared/ConfirmDialog/ConfirmDialog";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, getCountryLabel } from '../../shared/Constantes';
import { download } from '../../shared/tools/Utils';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import dayjs from "dayjs";

export const Companies = (props: any) => {
    const { t } = useTranslation();
    const [open, toggle] = useDialog(false);
    const [openSup, toggleSup] = useDialog(false);
    const [companies, setCompanies] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('creationDate');
    const [order, setOrder] = useState('desc');
    const [isEnabled, setIsEnabled] = useState(true);

    const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(event.target.value as string);
    };

    const closeModal = () => {
        toggle();
        setSelectedCompany(null);
    };

    const editCompany = (item: any) => {
        setSelectedCompany(item);
        toggle();
    };

    const deleteCompany = (item: any) => {
        setSelectedCompany(item);
        toggleSup();
    };

    const handleCloseSup = () => {
        toggleSup();
        setSelectedCompany(null);
    };

    const handleDelete = () => {
        const deletedCompanyName = selectedCompany.name;
        CompanyServices.deleteCompany(selectedCompany.id).then((result: any) => {
            loadCompanies();
            toast.success(t('orgas.delete.success', { company: deletedCompanyName }), { position: 'bottom-right' });
            toggleSup();
        }).catch((err) => {
            toast.error(t('orgas.delete.error', { company: deletedCompanyName }), { position: 'bottom-right', autoClose: false });
            toggleSup();
        });
    };

    const downloadCompanies = () => {
        setIsEnabled(false);
        setIsLoading(true);
        CompanyServices.downloadCompanies()
        .then((response) => {
          download(
            response.data,
            `Organisations_${dayjs().toISOString()}.xlsx` ,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          setIsEnabled(true);
          setIsLoading(false);
        }).catch(() => {
          setIsEnabled(true);
          toast.error(t('generic.error'), {position: 'bottom-right', autoClose: false})
        });
      };

    const filteredCompanies = useMemo(() => {
        return companies.filter((item: any) => {
            return (
                (item.name && (item.name as string).toLowerCase().includes(search.toLowerCase()))
                || (item.contact && (item.contact as string).toLowerCase().includes(search.toLowerCase()))
                || (item.mail && (item.mail as string).toLowerCase().includes(search.toLowerCase()))
            );
        }).sort((a: any, b: any) => {
            if (!a[orderBy]) {
                return (order === 'asc' ? -1 : 1);
            }
            if (!b[orderBy]) {
                return (order !== 'asc' ? -1 : 1);
            }
            const labelFunction = (orderBy === 'country') ? getCountryLabel : (x: any) => x
            if (orderBy === 'creationDate') {
                return (order === 'asc') ?
                dayjs(a[orderBy]).isAfter(dayjs(b[orderBy])) ? 1 : -1
                : dayjs(a[orderBy]).isBefore(dayjs(b[orderBy])) ? 1 : -1
            }
            if (order === 'asc') {
                return labelFunction(a[orderBy]).toString().localeCompare(labelFunction(b[orderBy]).toString());
            } else {
                return labelFunction(b[orderBy]).toString().localeCompare(labelFunction(a[orderBy]).toString());
            }
        })
    }, [companies, search, order, orderBy]);

    const loadCompanies = useCallback(() => {
        setIsLoading(true);
        CompanyServices.getCompanies().then((fetchCompanies) => {
            setCompanies(fetchCompanies.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false });
        });
    }, [t]);

    useEffect(() => {
        loadCompanies();
    }, [loadCompanies]);

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    return (
        <>
            <div className="margin-bottom-25 filter-menu">
                <div className="filters">
                    <TextField
                        id="standard-basic"
                        label={t('orgas.search')}
                        type="search"
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        style={{ width: '400px' }}
                        onChange={handleSearchChange}
                    />
                </div>
                {open &&
                    <CompanyForm
                        company={selectedCompany}
                        refresh={() => loadCompanies()}
                        onClose={closeModal}
                        isOpen={true}
                    />
                }
                <ConfirmDialog
                    open={openSup}
                    handleClose={handleCloseSup}
                    handleAction={handleDelete}
                    title={t('dialog.title.delete')}
                    message={selectedCompany ? t('orgas.message.delete', { company: selectedCompany.name }) : ''}
                    confirmLabel={t('button.validate')}
                />

                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    endIcon={<AddIcon />}
                    className="add-button"
                    onClick={toggle}
                    size="small"
                >
                    {t('orgas.button.add')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  endIcon={<DownloadIcon />}
                  onClick={() => downloadCompanies()}
                  disabled={!isEnabled}
                  size="small"
                  style={{marginLeft:20}}
                >
                    {t('button.export')}
                </Button>
            </div>

            {isLoading && <LinearProgress />}

            <div className="section margin-20">
                <div className="section-info">
                    {filteredCompanies && t('orgas.number', { nb: filteredCompanies.length })}
                </div>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '15%' }}><TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('name')}
                                >{t('orgas.name')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }}><TableSortLabel
                                    active={orderBy === 'creationDate'}
                                    direction={orderBy === 'creationDate' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('creationDate')}
                                >{t('orgas.date')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }}><TableSortLabel
                                    active={orderBy === 'contact'}
                                    direction={orderBy === 'contact' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('contact')}
                                >{t('orgas.contact')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '15%' }}><TableSortLabel
                                    active={orderBy === 'email'}
                                    direction={orderBy === 'email' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('email')}
                                >{t('orgas.email')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '15%' }}><TableSortLabel
                                    active={orderBy === 'phone'}
                                    direction={orderBy === 'phone' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('phone')}
                                >{t('orgas.phone')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }}><TableSortLabel
                                    active={orderBy === 'country'}
                                    direction={orderBy === 'country' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('country')}
                                >{t('orgas.country')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '15%' }}><TableSortLabel
                                    active={orderBy === 'cp'}
                                    direction={orderBy === 'cp' ? order === 'asc' ? 'asc' : 'desc' : 'asc'}
                                    onClick={createSortHandler('cp')}
                                >{t('orgas.cp')} / {t('orgas.city')}</TableSortLabel></TableCell>
                                <TableCell style={{ width: '10%' }} align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredCompanies && filteredCompanies.map((company: any) => (
                                <TableRow key={company.id} hover>
                                    <TableCell>{company.name}</TableCell>
                                    <TableCell>{dayjs(company.creationDate).format(FORMAT_DATE)}</TableCell>
                                    <TableCell>{company.contact}</TableCell>
                                    <TableCell>{company.mail}</TableCell>
                                    <TableCell>{company.phone}</TableCell>
                                    <TableCell>{t(getCountryLabel(company.country))}</TableCell>
                                    <TableCell>{company.cp ? company.cp : '-'} / {company.city ? company.city : '-'}</TableCell>
                                    <TableCell align="right">
                                        
                                        <IconButton
                                            aria-label="edit"
                                            size="small"
                                            onClick={() => editCompany(company)}
                                        >
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                        
                                        {
                                            company.isEditable &&
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => deleteCompany(company)}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}