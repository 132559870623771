import React, { useState } from "react";
import {
  LinearProgress, 
  Button, 
  FormControlLabel,
  Radio
} from '@mui/material';
import Upload from '@mui/icons-material/Publish';
import "./params.scss";
// @ts-ignore
import EventServices from '../../../services/event.services';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../shared/tools/CustomHooks';
import { toast } from "react-toastify";
import { ResultImport } from "./ResultImportDialog";

export const Imports = (props: any) => {
  const { t } = useTranslation();  

  const [isSubmit, setSubmit] = useState(false);
  const [typeImport, setTypeImport] = useState("mono"); 

  const [openResult, toggleOpenResult] = useDialog(false);
  const [results, setResults] = useState(null);
  const [warnings, setWarnings] = useState(null);

  const uploadFile = (event: any) => {
    setSubmit(true);
    const file = event.target.files[0];
    if (!file || file === null || !file.name.endsWith('.xlsx')) {
      console.log('error');
    } else {
      console.log(typeImport);
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        if (typeImport === 'mono') {        
          EventServices.importEvents({ file: e.target.result }).then((result) => {
            setSubmit(false);
            setResults(result.data.result);
            setWarnings(result.data.warnings);
            toggleOpenResult();          
          }).catch((error) => {
            setSubmit(false);
            if (error.response && error.response.status && error.response.status === 400) {
              const message = error.response.data.message;
              toast.error(
                <div style={{whiteSpace: 'pre-line', textAlign: 'left'}}>
                  {t('events.import.error.message', 
                    {
                      line: message.line,
                      startDate :message.startDate,
                      endDate: message.endDate,
                      type: message.type,
                      country: message.country,
                      refEla: message.refEla,
                      code: message.qrcode
                    }
                  )}
                </div>
              , { position: 'bottom-right', autoClose: false });
          
            } else {
              toast.error(t('events.import.error'), { position: 'bottom-right', autoClose: false });
            }
          }); 
        } else if (typeImport === 'multi') {
          EventServices.importEventsMultiCompanies({ file: e.target.result }).then((result) => {
            setSubmit(false);
            setResults(result.data.result);
            setWarnings(result.data.warnings);
            toggleOpenResult();          
          }).catch((error) => {
            setSubmit(false);
            if (error.response && error.response.status && error.response.status === 400) {
              const message = error.response.data.message;
              toast.error(
                <div style={{whiteSpace: 'pre-line', textAlign: 'left'}}>
                  {t('events.import.multi.error.message', 
                    {
                      line: message.line,
                      startDate :message.startDate,
                      endDate: message.endDate,
                      type: message.type,
                      eventCountry: message.eventCountry,
                      orgCountry: message.orgCountry,
                      orgRefEla: message.orgRefEla,
                      eventRefEla: message.eventRefEla,
                      eventName: message.eventName,
                      orgName: message.orgName,
                      code: message.qrcode
                    }
                  )}
                </div>
              , { position: 'bottom-right', autoClose: false });
          
            } else {
              toast.error(t('events.import.error'), { position: 'bottom-right', autoClose: false });
            }
          });
        }
      };
    }
    //event.preventDefault();
  };

  return (
  
    <div style={{ paddingBottom: '20px' }}>
    
      {openResult && results && warnings && 
        <ResultImport
          onClose={() => {
            toggleOpenResult();
          }}
          isOpen={true}
          results={results}
          warnings={warnings}
        />
      }

        <div style={{display: 'flex', flexDirection:'column', marginBottom: 20}}>
          <div style={{height:10}}>
            {isSubmit && <LinearProgress />}
          </div>

          <FormControlLabel
            control={<Radio 
            checked={typeImport === 'mono'} 
            onChange={(e) => setTypeImport('mono')}
            name="isAdmin" 
            color="primary"/>}
            label={t('params.import.mono')}
          />

          <div style={{fontSize: 'smaller', marginBottom: 20 , marginLeft:30}}>
            {t('params.import.help.mono')}
          </div>
          
          <FormControlLabel
            control={<Radio 
            checked={typeImport === 'multi'} 
            onChange={(e) => setTypeImport('multi')} 
            name="isCompanyAdmin"
            color="primary"/>}
            label={t('params.import.multi')}
          />
        </div>        

        <label htmlFor="data">
          <input
            accept=".xlsx"
            style={{ display: 'none' }}
            name="data"
            id="data"
            onChange={(event) => uploadFile(event)}
            type="file"
          />
          <Button
            disabled={isSubmit}
            color="primary"
            component="span"
            variant="contained"
            className="add-button h-100"
            disableElevation
            endIcon={<Upload />}
          >
            {t('button.import')}
          </Button>
        </label>    
      
    </div>
  )
};
