import React, {useContext, useState} from "react";
import "./home.scss"
import {Button, List, ListItem, ListItemIcon} from '@mui/material';
import { Link } from 'react-router-dom';
import {AuthUserContext} from 'AuthUserContext';
import { useTranslation } from 'react-i18next';
import EventServices from '../../../services/event.services';
import {toast} from "react-toastify";
import { eventType } from "components/shared/Constantes";
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import {download} from '../../shared/tools/Utils';
import logoMTB from '../../../assets/img/logoMTB_pink.png';
import challengesImg from '../../../assets/img/challenges.svg';
import orgasImg from '../../../assets/img/organisations.svg';
import runnersImg from '../../../assets/img/runners.svg';
import settingsImg from '../../../assets/img/settings.svg';

export const HomePage = (props: any) => {
  const {user} = useContext(AuthUserContext);
  const { t } = useTranslation();
  const [isEnabled, setIsEnabled] = useState(true);

  const downloadUserGuide = (type: any) => {
    setIsEnabled(false);
    EventServices.downloadGuideByType(type).then((response) => {
      download(
        response.data,
        response.headers["content-disposition"].split("filename=")[1],
        'application/pdf'
      );
      setIsEnabled(true);
    }).catch(() => {
      setIsEnabled(true);
      toast.error(t('generic.error'), { position: 'bottom-right', autoClose: false })
    });
  };

  return (
    <div className="content">
      <div className="dashboard-container">
        <div className="home-page">
          
          <img src={logoMTB} alt="Logo ELA" className="logo" style={{paddingTop: 50}}/>
          <div className="section margin-40">{t('homepage.title')}</div>
          
          {user.isAdmin &&
          <List>
            <ListItem button component={Link} to="/events" onClick={() => props.onClick(true)}>
              <ListItemIcon><img src={challengesImg} alt="Log out button"/></ListItemIcon>
              {t('homepage.admin.events')}
            </ListItem>
            <ListItem button component={Link} to="/companies" onClick={() => props.onClick(true)}>
              <ListItemIcon><img src={orgasImg} alt="Log out button"/></ListItemIcon>
              {t('homepage.admin.companies')}
            </ListItem>
            <ListItem button component={Link} to="/runners" onClick={() => props.onClick(true)}>
              <ListItemIcon><img src={runnersImg} alt="Log out button"/></ListItemIcon>
                {t('homepage.companyAdmin.runners')}
            </ListItem>
            <ListItem button component={Link} to="/params" onClick={() => props.onClick(false)}>
              <ListItemIcon><img src={settingsImg} alt="Log out button" style={{maxHeight: 30}}/></ListItemIcon>
              {t('homepage.admin.settings')}
            </ListItem>
          </List>
          }
          {user.isCompanyAdmin &&
          <List>
            <ListItem button component={Link} to="/my-company">
              <ListItemIcon><img src={orgasImg} alt="Log out button"/></ListItemIcon>
                {t('homepage.companyAdmin.company')}
            </ListItem>
            <ListItem button component={Link} to="/dashboard-company">
              <ListItemIcon><img src={challengesImg} alt="Log out button"/></ListItemIcon>
                {t('homepage.companyAdmin.events')}
            </ListItem>
            <ListItem button component={Link} to="/runners">
              <ListItemIcon><img src={runnersImg} alt="Log out button" style={{maxHeight: 30}}/></ListItemIcon>
                {t('homepage.companyAdmin.runners')}
            </ListItem>
          </List>
          }

          {user.isSchoolAdmin &&                     
          <List>
            <ListItem button component={Link} to="/my-company">
              <ListItemIcon><img src={orgasImg} alt="Log out button"/></ListItemIcon>
                {t('homepage.companyAdmin.company')}
            </ListItem>
            <ListItem button component={Link} to="/dashboard-company">
              <ListItemIcon><img src={challengesImg} alt="Log out button"/></ListItemIcon>
                {t('homepage.companyAdmin.events')}
            </ListItem>
            <ListItem button component={Link} to="/runners">
              <ListItemIcon><img src={runnersImg} alt="Log out button" style={{maxHeight: 30}}/></ListItemIcon>
                {t('homepage.companyAdmin.runners')}
            </ListItem>
          </List>          
          }

          {(user.isSchoolAdmin || user.isCompanyAdmin) &&
          <div className="margin-top-40" style={{display: 'flex', flexDirection: 'row', justifyContent: 'stretch'}}>
            <span className="padding-5">{t('homepage.help')}</span>
            <Button
              className="action-link"
              color="primary"
              size="small"
              endIcon={<DownloadIcon />}
              onClick={() => downloadUserGuide(user.isCompanyAdmin ? eventType.MTBE.code : eventType.MTB.code)}
              disabled={!isEnabled}
            >
              {t('homepage.download.guide')}
            </Button>
          </div>
          }

        </div>
      </div>
    </div>
  )
}
