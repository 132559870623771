import {useCallback, useState} from 'react';

export const useDialog = (defaultState = false): [boolean, () => void] => {
  const [isOpen, setOpen] = useState(defaultState);

  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return [isOpen, toggle];
}
