import React, {useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  IconButton
} from '@mui/material';
import "./Detail.scss"
import EventServices from '../../../services/event.services';
import CompanyServices from '../../../services/company.services';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE_TIME, NOTIF_STATUS, getCountryTimezone, getNotifStatusLabel } from '../../shared/Constantes';
import ViewIcon from "@mui/icons-material/Edit";
import SendOutlined from '@mui/icons-material/SendOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

export const NotificationsListModal = (props: any) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications , setNotifications] = useState<any>([]);
  
  const getNotifications = useCallback(() => {
    setIsLoading(true);
    if (props.company !== undefined && props.company !== null) {
      CompanyServices.getNotifications(props.event, props.company).then((results) => {
        setNotifications(results.data);
        setIsLoading(false);
      });
    } else {
      EventServices.getNotifications(props.event).then((results) => {
        setNotifications(results.data);
        setIsLoading(false);
      });
    };
  }, [props]);

  const editNotif = (item: any) => {
    props.updateNotif(item);
  };

  const newNotif = () => {
    props.newNotif();
  };

  const deleteNotif = (item: any) => {
    props.deleteNotif(item);
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <Dialog
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', width: '100%'}}>
          <div style={{width: '100%'}}>
            {t('detail.notifs.title')}
          </div>
          <div style={{textAlign: 'right', width: '40%'}}>
            <Button
            className="add-button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => newNotif()}
            endIcon={<SendOutlined />}
            >
              {t('detail.send.notification')}
            </Button>
          </div>
        </div>    
      </DialogTitle>

      <DialogContent>
        {isLoading && <LinearProgress/>}  
        {!isLoading && notifications.length === 0 && (<span>{t('detail.notifs.empty')}</span>)}
        
        {!isLoading && notifications.length !== 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>{t('detail.notifs.subject')}</TableCell>
                  <TableCell style={{ width: '35%' }}>{t('detail.notifs.message')}</TableCell>
                  <TableCell style={{ width: '10%' }}>{t('detail.notifs.status')}</TableCell>
                  <TableCell style={{ width: '15%' }}>{t('detail.notifs.date')}</TableCell>
                  <TableCell style={{ width: '10%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>                  
                  {notifications !== null && notifications.map(
                      (notif: any) => (
                        <TableRow key={notif.id} hover>
                          <TableCell>{notif.title}</TableCell>
                          <TableCell>{notif.body}</TableCell>
                          <TableCell>
                            {notif.status === NOTIF_STATUS.SENT.code 
                            ? <div style={{ color: 'green' }}>{t(getNotifStatusLabel(notif.status))}</div>
                            : <div style={{ color: 'orange' }}>{t(getNotifStatusLabel(notif.status))}</div>}
                          </TableCell>
                          <TableCell>
                            {dayjs(notif.toSendDate).tz(getCountryTimezone(props.country)).format(FORMAT_DATE_TIME)}
                          </TableCell>
                          <TableCell align="right">
                            {notif.status === NOTIF_STATUS.TO_SEND.code &&
                              (<>
                                <IconButton 
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => editNotif(notif)}
                                >
                                  <ViewIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton 
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => deleteNotif(notif)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </>)
                            }
                          </TableCell>
                        </TableRow>
                      )
                    )
                  }
              </TableBody>
            </Table>
          </TableContainer>)
          }
        
      </DialogContent>
      
      <DialogActions>
        <Button onClick={props.toggle}>
          {t('button.cancel')}
        </Button>
      </DialogActions>
        
    </Dialog>
  );
}
