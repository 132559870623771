import React from "react";
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import "./DashboardCompany.scss"
import { useTranslation } from 'react-i18next';
import { EventsCompany } from "./EventsCompany";

export const DashboardCompany = (props: any) => {
  const { t } = useTranslation();
  
  return (
    <div className="content">
      <Card className="event-panel dashboard-container">
        <div className="event-title">
          <div>
            {t('events.title')}
          </div>
        </div>
        <CardContent>
          <EventsCompany />
        </CardContent>
      </Card>
    </div>
  )
}
