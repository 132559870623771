import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import config from './config'

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  databaseURL: config.databaseURL,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
  measurementId: config.measurementId,
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
