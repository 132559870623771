import React from "react";


export const Error = (props: any) => {
  return (
    <div className="content">
      ERROR
    </div>
  );

}
